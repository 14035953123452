import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Indicator } from '@eclass/api'

import { UIVars, NewDropdown, Button, ObjectivesModal } from '~ui'
import { Icon } from '~ui/Icons'
import { testsStates } from '../../../Views/Tests/testsStates'
import TimeProgress from './NavTest/TimeProgress'
import Wrapper from './Style'
import ReadingProgress from './ReadingProgress'
import { useFixed } from './useFixed'
import { Types } from './types'
import { RouteParams } from '~routes/types'

const NAVIGATION_GRID = 2

/**
 * Nav fixed con avance, volver y dropdown con lista de enlaces
 * @example
 *  <Nav menu={menu} type="navigation-pro | test" topics={} />
 */
const Nav = ({
  activeQuestion,
  answered = null,
  answers,
  config,
  fixed = false,
  type,
  state,
  testActions,
  title,
  topics,
  menu,
  practice = false,
  level = 0
}: Types.NavProps) => {
  const { t } = useTranslation()
  const { isFixed } = useFixed()
  const history = useHistory()
  const { programId, studentId, courseId, resourceId, answerId } = useParams<
    RouteParams
  >()
  const {
    ui: { isMobile },
    courses: { getCourseInfo: courseInfo }
  } = useSelector((store: Types.Store) => store)
  const [isOpen, setIsOpen] = useState(false)

  const [unitIndicator, setUnitIndicator] = useState<Indicator>({
    id: 0,
    name: 'Estrellas',
    image: 'estrellas',
    obtained: 0,
    max: 0
  })
  const hasTopics = topics && topics.length > 0
  const showTime =
    [
      testsStates.ANSWERING,
      testsStates.COMPLETED,
      testsStates.TERMINATE
    ].includes(state) &&
    config &&
    config!.hasTime > 0

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (Object.keys(courseInfo).length === 0) {
      history.push(`/units/${programId}/${studentId}/${courseId}`)
    }
  }, [courseInfo, history, courseId, programId, studentId])

  useEffect(() => {
    let max = 0
    let obtained = 0
    if (menu) {
      menu!.Links.forEach(link => {
        link.Childrens?.forEach(child => {
          const stars = child.indicators?.find(indicator => indicator.id === 3)!
          if (stars) {
            max += stars.max!
            obtained += stars.obtained!
          }
        })
      })
      if (max > 0) {
        setUnitIndicator({
          id: 3,
          max,
          obtained,
          name: 'Estrellas',
          image: 'estrellas'
        })
      }
    }
  }, [menu])

  /**
   * Obtiene el título del menu de los subitems en mobile.
   */
  const getMobileTitle = useCallback(() => {
    if (!practice) {
      return {
        title: t('Resource'),
        mobile: '',
        icon: 'materials'
      }
    }
    const resource = menu?.Links.find(link => link.id === Number(resourceId))!
    if (resource && resource!.Childrens!.length > 0) {
      const total = resource.Childrens?.reduce(
        (acc, child, key) => {
          if (child.status?.style === 'completed') {
            acc.answered++
          }
          if (child.id === Number(answerId)) {
            acc.position = key + 1
          }
          acc.max++
          return acc
        },
        { answered: 0, max: 0, position: 1 }
      )

      return {
        title: t('ResourceExercise', {
          current: total?.position,
          max: total?.max
        }),
        mobile: t('ResourceExercise', {
          context: 'mobile',
          current: total?.position,
          max: total?.max
        }),
        icon: 'bullet-list'
      }
    } else {
      // si viene undefined es por el caché, por esto agregamos este texto por defecto
      return {
        title: t('ResourceExercise', {
          max: 1,
          current: 1
        }),
        mobile: t('ResourceExercise', {
          context: 'mobile',
          max: 0,
          current: 1
        }),
        icon: 'bullet-list'
      }
    }
  }, [menu, practice, resourceId, answerId, t])

  const Navigations = [
    {
      id: 'test',
      name: testsStates.EVALUATED === state ? 'feedback' : 'test',
      text: t('ResourceQuestions', {
        answered,
        total: answers ? answers.length : null
      }),
      icon: 'bullet-list',
      items: answers || [],
      header: title || '',
      btnMobile: t('ResourceQuestionsOf', {
        answered,
        total: answers ? answers.length : null
      })
    },
    {
      id: 'levels',
      name: testsStates.EVALUATED === state ? 'feedbackLevels' : 'levels',
      text: t('ResourceQuestions', {
        answered: answered?.answers || 0,
        total: answered?.total || ''
      }),
      icon: 'bullet-list',
      items: answers || [],
      header: title || '',
      btnMobile: t('ResourceQuestionsOf', {
        answered: answered?.answers || 0,
        total: answered?.total || ''
      })
    },
    {
      id: 'navigation-pro',
      name: 'navigation',
      text: getMobileTitle().title,
      icon: getMobileTitle().icon,
      items: menu?.Links!,
      header:
        courseInfo.Course!.navigationId === NAVIGATION_GRID
          ? courseInfo.Course!.name
          : menu?.Unit && menu?.Unit.name,
      btnMobile: getMobileTitle().mobile,
      indicator: unitIndicator
    }
  ]

  const DropType = Navigations.find(items => {
    if (type && type.includes(items.id)) {
      return items
    }
    return null
  })!

  if (Object.keys(courseInfo).length === 0) {
    return <div>{t('Course information was not retrieved')}</div>
  }

  return (
    <Wrapper className={`ResourceNav ${isFixed}`}>
      <div className="position-relative">
        <Container fluid={isMobile}>
          <Row>
            <Col lg={{ size: 12 }} className="ResourceNav__back flex">
              <Link to={courseInfo.Course?.href!}>
                <Icon
                  name="goback-courses"
                  fill={UIVars.setColors.completed}
                  width={8}
                  height={12}
                />
                <span>{t('ResourceCourse')}</span>
              </Link>
              {showTime && (
                <TimeProgress config={config!} testActions={testActions} />
              )}
              <div className="ResourceNav__menu flex">
                {hasTopics && (
                  <Button
                    className={`Objectives__Button ${
                      isMobile ? 'isMobile' : ''
                    }`}
                    onClick={() => toggleModal()}>
                    <Icon
                      name="objectives"
                      fill={UIVars.setColors.deepSkyBlue}
                    />
                    {!isMobile && t('ContentObjectives')}
                  </Button>
                )}
                {![
                  testsStates.ALERT,
                  testsStates.FINISHED,
                  testsStates.REPETITIONS
                ].includes(state) &&
                  type &&
                  DropType.items && (
                    <NewDropdown
                      activeQuestion={activeQuestion}
                      btnIcon={DropType.icon}
                      btnTitle={DropType.text}
                      dropdownType={DropType.name}
                      headerTitle={DropType.header}
                      items={DropType.items}
                      btnTitleMobile={DropType.btnMobile}
                      indicator={DropType.indicator}
                      level={level}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </Container>
        <ReadingProgress id="containerReadingProgress" />

        {hasTopics && (
          <ObjectivesModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            topics={topics}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default Nav
