import { format } from 'date-fns'
import { es } from 'date-fns/locale'

/**
 * Retorno fecha con formato solicitado
 * @example
 * formatDate(item.date, 'd')
 */
export const formatDate = (date: Date, dateformat: string, locale = es) => {
  return format(new Date(date), dateformat, {
    locale
  })
}
