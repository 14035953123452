import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Types } from './types'
import * as Utils from './utils'

const BASE_REGEX = /(___REPLACE_CONTENT_MODULE___.*?___REPLACE_CONTENT_MODULE___)/gm

/**
 * Parsea un string a componentes JSX.
 *
 * @param {String} content - Contenido.
 * @param {Types.Embeds} embeds - Embeds, componentes a parsear, las configuraciones vienen de la tabla `ContentModules`.
 * @param {Types.Other} other - Otras configuraciones adicionales del parser.
 */
const ContentParser = (
  content: string,
  embeds?: Types.Embeds,
  other?: Types.Other
) => {
  let ExerciseIndex = 0
  const setExerciseIndex = () => {
    ExerciseIndex++
  }

  const components = Utils.getComponentsEmbeds(embeds!)

  const parseContent = ReactHtmlParser(content, {
    decodeEntities: true,
    transform: Utils.removeContentEditable
  })

  const parseOrder: any = []
  parseContent.forEach((element, i) => {
    if (Utils.canReplace(element)) {
      element.props.children.forEach((child: any, kChild: number) => {
        // si el hijo no es un objeto hago el replace si no busco hasta que lo sea
        if (typeof child !== 'object') {
          let miregex = BASE_REGEX
          if (other ? child.search(`REPLACE_${other.type}`) > 0 : false) {
            miregex = new RegExp(
              `(___REPLACE_CONTENT_MODULE___.*?___REPLACE_CONTENT_MODULE___)|(___REPLACE_${
                other!.type
              }___.*?___REPLACE_${other!.type}___)`,
              'gm'
            )
          }
          const nodeChilds = child.split(miregex)
          nodeChilds.forEach((node: any, kNode: number) => {
            const nodeString = `${node}`
            if (nodeString.search('REPLACE_CONTENT_MODULE') > 0) {
              const findComponent = components.find((c: any) => c.id === node)
              nodeChilds[kNode] = findComponent ? findComponent.component : node
            } else if (
              other &&
              nodeString.search(`REPLACE_${other.type}`) > 0
            ) {
              const renderHandler = other.handleRender(
                node,
                ExerciseIndex,
                other?.evaluations
              )
              if (renderHandler) {
                setExerciseIndex()
              }
              nodeChilds[kNode] = renderHandler || node
            } else {
              const findComponent = components.find((c: any) => c.id === node)
              nodeChilds[kNode] = findComponent ? findComponent.component : node
            }
          })
          parseContent[i].props.children[kChild] = nodeChilds
        } else if (
          child.props.children &&
          typeof child.props.children.forEach === 'function'
        ) {
          child.props.children.forEach((child2: any, kChild2: number) => {
            if (typeof child2 !== 'object') {
              let miregex2 = BASE_REGEX
              const customRegex = other
                ? child2.search(`REPLACE_${other.type}`) > 0
                : false
              if (customRegex) {
                miregex2 = new RegExp(
                  `(___REPLACE_CONTENT_MODULE___.*?___REPLACE_CONTENT_MODULE___)|(___REPLACE_${
                    other!.type
                  }___.*?___REPLACE_${other!.type}___)`,
                  'gm'
                )
              }
              const nodeChilds2 = child2.split(miregex2)
              nodeChilds2.forEach((node2: any, kNode2: number) => {
                const nodeString2 = `${node2}`
                if (nodeString2.search('REPLACE_CONTENT_MODULE') > 0) {
                  const findComponent = components.find((c: any) => {
                    return c.id === node2
                  })
                  nodeChilds2[kNode2] = findComponent
                    ? findComponent.component
                    : node2
                } else if (
                  other &&
                  nodeString2.search(`REPLACE_${other.type}`) > 0
                ) {
                  const renderHandler = other.handleRender(
                    node2,
                    ExerciseIndex,
                    other?.evaluations
                  )
                  if (renderHandler) {
                    setExerciseIndex()
                  }
                  nodeChilds2[kNode2] = renderHandler || node2
                } else {
                  const findComponent = components.find((c: any) => {
                    return c.id === node2
                  })
                  nodeChilds2[kNode2] = findComponent
                    ? findComponent.component
                    : node2
                }
              })
              parseContent[i].props.children[kChild].props.children[
                kChild2
              ] = nodeChilds2
            } else if (child2.props.children) {
              typeof child2.props.children === 'object' &&
                child2.props.children.forEach(
                  (child3: any, kChild3: number) => {
                    if (typeof child3 !== 'object') {
                      let miregex3 = BASE_REGEX
                      if (
                        other
                          ? child3.search(`REPLACE_${other.type}`) > 0
                          : false
                      ) {
                        miregex3 = new RegExp(
                          `(___REPLACE_CONTENT_MODULE___.*?___REPLACE_CONTENT_MODULE___)|(___REPLACE_${
                            other!.type
                          }___.*?___REPLACE_${other!.type}___)`,
                          'gm'
                        )
                      }
                      const nodeChilds3 = child3.split(miregex3)
                      nodeChilds3.forEach((node3: any, kNode3: number) => {
                        const nodeString2 = `${node3}`
                        if (nodeString2.search('REPLACE_CONTENT_MODULE') > 0) {
                          const findComponent = components.find((c: any) => {
                            return c.id === node3
                          })
                          nodeChilds3[kNode3] = findComponent
                            ? findComponent.component
                            : node3
                        } else if (
                          other &&
                          nodeString2.search(`REPLACE_${other.type}`) > 0
                        ) {
                          const handleComponent = other.handleRender(
                            node3,
                            ExerciseIndex,
                            other?.evaluations
                          )
                          if (handleComponent) {
                            setExerciseIndex()
                          }
                          nodeChilds3[kNode3] = handleComponent || node3
                        } else {
                          const findComponent = components.find((c: any) => {
                            return c.id === node3
                          })
                          nodeChilds3[kNode3] = findComponent
                            ? findComponent.component
                            : node3
                        }
                      })
                      parseContent[i].props.children[kChild].props.children[
                        kChild2
                      ].props.children[kChild3] = nodeChilds3
                    }
                  }
                )
            }
          })
        }
      })
    } else if (!['style', 'hr'].includes(`${element.type}`)) {
      /**
       * Esto es cuando no es una etiqueta HTML
       */
      // @ts-ignore
      const nodeChilds = element.split(BASE_REGEX)
      nodeChilds.forEach((node: any, kNode: number) => {
        const findComponent = components.find((c: any) => c.id === node)
        nodeChilds[kNode] = findComponent ? findComponent.component : node
      })
      parseContent[i] = <>{nodeChilds}</>
    }
    parseOrder.push({ ...parseContent[i], key: i.toString() })
  })

  return parseOrder.map((parse: any, kContent: number) => {
    if (other && other.type !== 'TEST' && parse.type === 'p') {
      parse.type = 'div'
      parse.props = {
        ...parse.props,
        className: 'question'
      }
    }
    const contentChilds = parse
    return {
      ...contentChilds,
      key: kContent
    }
  })
}

export default ContentParser
