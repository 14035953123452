/**
 * Cambia en el Storage de la Aplicación si muestra u oculta la bienvenida del curso.
 *
 * @param {boolean} showWelcome - Muestra la bienvenida del curso
 * @returns String con el estado del cambio
 */
const setCourseWelcome = showWelcome => {
  return dispatch => {
    dispatch({
      type: 'COURSE_WELCOME',
      payload: showWelcome
    })
    return showWelcome ? 'COURSE_WELCOME_OPEN' : 'COURSE_WELCOME_CLOSE'
  }
}

export default setCourseWelcome
