// @Dependencias externas
import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Container } from 'reactstrap'
import { ImgDesktop404, ImgMobile404 } from '../../../assets/img/error'
import { eclassLogoFooter } from '~assets/svg'
import { Types } from './types'

/**
 *
 * @param param0
 */
export const Error500 = ({ status }: Types.Props) => {
  const { isMobile } = useSelector((store: Types.Store) => store.ui)
  const messages = [
    {
      lang: 'Español',
      flag: 'spa',
      text: 'Esta página no existe, quizás escribiste mal la dirección. Puedes',
      link: 'volver al inicio.'
    },
    {
      lang: 'English',
      flag: 'eng',
      text:
        'This page doesn’t exist, maybe you misspelled the address. You can',
      link: 'go back to home.'
    },
    {
      lang: 'Portugues',
      flag: 'por',
      text:
        'Esta página não existe, talvez você digitou incorretamente o endereço. Você pode',
      link: 'voltar ao início.'
    }
  ]

  return (
    <Container fluid={isMobile}>
      <div className="title_mobile">Oops!</div>
      <Row className="ErrorPage">
        <Col md={6} className="ErrorPage__content">
          <h2>Oops!</h2>
          {status.detail && status.code && (
            <p className="error">
              {status.detail} (Cod. {status.code})
            </p>
          )}
          <ul>
            {messages.map((item, index) => (
              <li key={index}>
                <img
                  src={`//cdn.eclass.cl/assets/img/error/${item.flag}.png`}
                  alt={item.lang}
                />
                <p>
                  {item.text} <a href="/courses">{item.link}</a>
                </p>
              </li>
            ))}
          </ul>
          <img
            src={eclassLogoFooter}
            className="logo-footer-404"
            alt="eClass"
          />
        </Col>
        <Col md={6} className="ErrorPage__image">
          <img
            src={ImgDesktop404}
            className="desktop-404-img"
            alt={'Logo eClass'}
          />
          <img
            src={ImgMobile404}
            className="mobile-404-img"
            alt={'Logo eClass'}
          />
        </Col>
      </Row>
    </Container>
  )
}
