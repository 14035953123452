// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @Local Dependencies
import UIVars from './variables'

// @Component AverageCalification
export default function AverageQualification ({
  className = '',
  size,
  value,
  background,
  qualificationStatus,
  color
}) {
  return (
    <Wrapper
      className={`info average-info ${className}`}
      data-testid="AverageQualification">
      <div
        className={`average-box ${size} ${background} ${qualificationStatus} ${color} ${
          value ? '' : 'no-value'
        }`}>
        <span className={`average`}>{value || '_,_'}</span>
      </div>
    </Wrapper>
  )
}

// @Styles component
const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .average-box {
    background: ${UIVars.setColors.backgroundGrey};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.approved {
      background: ${UIVars.setColors.jadeGreen};
      .average {
        color: ${UIVars.setColors.whiteThree};
      }
    }

    &.reprobate {
      background: ${UIVars.setColors.red};
      .average {
        color: ${UIVars.setColors.whiteThree};
      }
    }

    &.xs {
      .average {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        height: 16px;
        padding: 2px 6px;
      }
      &.no-value {
        .average {
          letter-spacing: 3px;
          color: ${UIVars.setColors.whiteThree};
        }
      }
    }

    &.md {
      .average {
        font-size: 16px;
        line-height: 21px;
        padding: 2px 10px;
      }
      &.no-value {
        .average {
          letter-spacing: 5px;
          color: ${UIVars.setColors.whiteThree};
          margin-left: 7px;
        }
      }
    }

    &.lg {
      .average {
        font-size: 26px;
        padding: 5px 20px;
      }
      &.no-value {
        .average {
          letter-spacing: 3px;
        }
      }
    }

    &.no-background {
      background: none;
      .average {
        color: ${UIVars.setColors.black};
      }
      &.no-value {
        .average {
          letter-spacing: 3px;
          font-weight: normal;
          color: ${UIVars.setColors.black};
          margin-left: 7px;
        }
      }
    }

    .average {
      color: ${UIVars.setColors.whiteThree};
      font-weight: bold;
    }
  }
`

// @Proptypes
AverageQualification.propTypes = {
  size: PropTypes.string
}

// @DefaultProps
AverageQualification.defaultProps = {
  size: 'xs',
  background: '',
  qualificationStatus: '',
  color: ''
}
