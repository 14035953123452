// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'

/**
 * Recibe los Id de cursos y busca la ultima actividad del alumno.
 * Retornando un objeto de `LatestActivity`
 */
export const getCourseLatestActivity = (courses: number[]) => {
  const requestId = 'getCourseLatestActivity'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getCourseLatestActivity($CourseLatestActivityInput: CourseLatestActivityInput!) {
        getCourseLatestActivity(input: $CourseLatestActivityInput) {
          status {
            code
            success
            name
            detail
          }
          LatestActivity {
            callToAction {
              resources
              completedResources
              progress
              href
              courseName
              unitName
            }
            info {
              id
              enabled
              name
              style
            }
          }
        }
      }
    `

    const variables = {
      CourseLatestActivityInput: {
        courses
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        return response?.data?.data?.getCourseLatestActivity
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
