import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Store } from '~app_store/types'
import { es, ptBR, enUS } from 'date-fns/locale'

import { useParams } from 'react-router-dom'
import { RouteParams } from '~routes/types'

import i18nApp from '../../app/I18n'

/**
 * Retorna el Locale de dateFNS para formatear fechas.
 * Evalúa la url si está en el contexto de un curso o una sección transversal
 * - Si es curso, toma el idioma del curso `CourseInfo`
 * - Si es transversal la sección toma el resultado de `CampusBranding`
 *
 * - Si el idioma del curso no está seteado entonces retorna el idioma Transversal.
 *
 *
 * Por último cambia el idioma de la aplicación.
 *
 * @example
 * const { Locale, name } = useLocale()
 * @since 08 enero 2021
 */
export const useLocale = () => {
  const { i18n, i18nCourses } = useSelector((state: Store) => state.ui)
  const { courseId } = useParams<RouteParams>()
  const [locale, setLocale] = useState('')

  const listLocales = new Map([
    ['spa', es],
    ['eng', enUS],
    ['es_eng', es],
    ['por', ptBR]
  ])

  useEffect(() => {
    // Si tenemos courseId en la url (estamos dentro de un curso)
    if (
      courseId &&
      i18nCourses instanceof Object &&
      i18nCourses[Number(courseId)]
    ) {
      setLocale(i18nCourses[Number(courseId)]!)
    } else {
      setLocale(i18n!)
    }
  }, [i18n, courseId, i18nCourses])

  // Solo cambia la configuracion por el flag
  useEffect(() => {
    if (locale && i18nApp.language !== locale) {
      i18nApp.changeLanguage(locale)
    }
  }, [locale])

  return {
    Locale: listLocales.get(locale) || listLocales.get(i18n!)!,
    name: locale
  }
}
