import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useTranslation } from 'react-i18next'
import { useToolbar } from './Toolbar/'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Wrapper } from './Style'
import { Props } from './types'

/**
 * Limpia un string en formato html y retorna la cantidad de palabras.
 *
 * @param {string} text - texto html.
 */
export const filterString = (text: string): number => {
  return text
    .replace(/(<([^>]+)>)/gi, '')
    .replace('\n', '')
    .split(' ')
    .filter(s => s.length > 0).length
}

/**
 * Componente con editor de texto.
 */
export const TextEditor = ({
  value,
  setValue,
  setHasContent,
  onBlur,
  onFocus,
  readOnly,
  loading,
  placeholder,
  showCounter = false,
  toolbar,
  setAttached,
  attachTotal
}: Props.TextEditor) => {
  const { t } = useTranslation()
  const [characterCount, setCharacterCount] = useState(0)
  const toolbarConfig = useToolbar(toolbar, setAttached!, attachTotal!)
  const onEditorStateChange = (editorState: EditorState) => {
    setValue(editorState)
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setCharacterCount(content.length - 8)
    setHasContent(filterString(content))
  }

  return (
    <Wrapper className="TextEditor">
      <Editor
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        wrapperClassName="wrapper-class"
        editorClassName={`editor-class ${characterCount > 400 ? 'tall' : ''}`}
        toolbarClassName="toolbar-class"
        toolbar={toolbarConfig.default}
        editorState={value}
        onEditorStateChange={onEditorStateChange}
        localization={{
          locale: 'en'
        }}
        toolbarCustomButtons={toolbarConfig.custom}
      />
      <span className="editor-info">
        {loading ? `${t('CrossSaving')}` : showCounter && characterCount}
      </span>
    </Wrapper>
  )
}
