import { UIVars } from '~ui'
import Close from '../../../../assets/svg/cerrar-definicion.svg'
import Definition from '../../../../assets/svg/group-4.svg'

const Bootstrap = `
  span[data-toggle="tooltip"]{
    color: ${UIVars.setColors.deepSkyBlue};
    cursor: pointer;
    padding-right: 22px;
    position: relative;
    :before{
      content: url(${Definition});
      cursor: pointer;
      right: 0;
      position: absolute;
      top: -4px;
    }

    &:hover {
      color: #333;
      :before{
        filter: saturate(0);
      }
    }
  }
  .Timeline__tooltip, .Option__Tooltip, .tooltip {
    font-size: 12px;
    z-index: 10;
    &.show{
      opacity: 1;
    }
    &.Timeline__tooltip, &.Option__Tooltip {
      .tooltip-inner{
        background-color: black;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
        color: white;
        font: 12px 'Roboto';
        font-weight: 400;
        padding: 6px 10px;
        max-width: 200px;

        &:before {
          display: none;
        }

        strong {
          font-weight: 500;
        }

        strong, div {
          position: relative;
          z-index: 10;
        }
      }

      .arrow {
        &:before {
          background: black;
          top: -10px;
        }
      }
    }

    .tooltip-inner {
      background: ${UIVars.setColors.whiteTwo};
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      color: ${UIVars.setColors.blackTwo};
      max-width: 250px;
      padding: 20px;
      position: relative;
      text-align: left;
      z-index: 2;
      strong{
        display: block;
        margin-bottom: 5px;
      }
      :before{
        content: url(${Close});
        cursor: pointer;
        right: 10px;
        position: absolute;
        top: 10px;
        z-index: 1;
      }
    }
    .arrow:before{
      background: ${UIVars.setColors.whiteTwo};
      border:0;
      box-shadow: 3px 3px 3px 0 rgba(0,0,0,0.1);
      content: '';
      display: block;
      height: 16px;
      left: -2px;
      position: absolute;
      top: -10px;
      transform:rotate(45deg);
      width: 16px;
      z-index:1;
    }
    &.bs-tooltip{
      &-top{
        top: -4px!important;
      }
      &-bottom{
        top: 4px!important;
        .arrow{
          :before{
            top: 0;
            box-shadow: none;
          }
        }
      }
    }
  }
`
export default Bootstrap
