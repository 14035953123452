import React from 'react'

import styled from 'styled-components'

type Props = {
  className?: string
  fill?: string
  rotation?: number
  size?: number
}

export const LoaderAsync = ({
  className = '',
  fill = '#fff',
  rotation = 0.5,
  size = 16
}: Props) => {
  return (
    <div className={`LoaderAsync ${className}`}>
      <Svg viewBox="0 0 16 16" width={size} height={size} rotation={rotation}>
        <g className="prefix__nc-icon-wrapper" fill={fill}>
          <g className="prefix__nc-loop_circle-02-16">
            <path
              opacity={0.4}
              d="M14.322 3.098c2.703 3.486 2.066 8.522-1.42 11.224s-8.522 2.066-11.224-1.42S-.388 4.38 3.098 1.678s8.522-2.066 11.224 1.42zM3.258 11.676c2.028 2.615 5.804 3.093 8.418 1.066s3.093-5.804 1.066-8.418S6.938 1.23 4.324 3.258 1.23 9.062 3.258 11.676z"
            />
            <path
              data-color="color-2"
              d="M3.098 1.678l1.226 1.58a6.007 6.007 0 00-1.066 8.418l-1.58 1.226C-1.025 9.416-.388 4.38 3.098 1.678z"
            />
          </g>
        </g>
      </Svg>
    </div>
  )
}

const Svg = styled.svg<Props>`
  animation: ${({ rotation }: Props) =>
    `rotating ${rotation}s linear infinite`};
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
export default LoaderAsync
