import { createGlobalStyle } from 'styled-components'
import base from './base'
import Bootstrap from './bootstrap'
import fancyBox from './fancyBox'
import formGroup from './formGroup'
import helpers from './helpers'
import popover from './popover'
import text from './text'
// import dropdown from './dropdown'
import perfectScrollbar from './perfectScrollbar'
import modal from './modal'

/** Fixes position iframe */
const ZenDesk = `
  #launcher {
    transition: none!important;
    z-index: 999!important;
  }
`

const GlobalStyle = createGlobalStyle`
  ${Bootstrap}
  ${modal}
  ${base}
  ${text}
  ${formGroup}
  ${popover}
  ${helpers}
  ${fancyBox}
  ${perfectScrollbar}
  ${ZenDesk}
`
export default GlobalStyle
