// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'
import { QuestionListInput, QuestionListPayload } from '@eclass/api'

/**
 * Agrega una pregunta al curso
 * @returns {Promise<QuestionListPayload>}
 */
const getQuestionList = (input: QuestionListInput) => {
  const requestId = 'getQuestionList'
  const { studentId, courseId, filter } = input

  return async (dispatch: any) => {
    getToken()
    const query = `
      query getQuestionList($QuestionListInput: QuestionListInput!) {
        getQuestionList(input: $QuestionListInput) {
          status {
            success
            detail
          }
          total
          pages
          Units{
            id
            name
            related
          }
          Resources{
            id
            name
            related
          }
          Questions{
            details{
              id
              name
              href
              status{
                id
                enabled
                name
                detail
              }
              indicators{
                name
                image
              }
            }
            Student{
              id
              personId
              Person {
                id
                fullName
                picture
              }
              Profile {
                id
                name
              }
            }
            created
          }
        }
      }
    `

    const variables = {
      QuestionListInput: {
        studentId,
        courseId,
        filter: {
          limit: 10,
          ...filter
        }
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const payload: QuestionListPayload = response.data.data.getQuestionList
        return payload
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getQuestionList
