import { PresencialResumeReducer, ReducerAction } from './types'
import { initialState } from './initialState'

/**
 * Hace el dispatch de las lecciones presenciales del alumno o lo elimina del store.
 *
 * @retuns Objeto con los datos del usuario
 */
export const presencialResume = (
  state: PresencialResumeReducer = initialState,
  action: ReducerAction
): PresencialResumeReducer | null => {
  switch (action.type) {
    case 'SET_LESSONS':
      return action.payload
    default:
      return state
  }
}
