import styled from 'styled-components'
import UIVars from '../../variables'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${UIVars.setColors.whiteThree};
  &.fixed-top {
    box-shadow: ${UIVars.navShadow};
    margin-top: 0;
  }

  .Timer {
    font-size: 12px;
    color: ${UIVars.setColors.brownThree};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;

    .progress-bar {
      background-color: ${UIVars.setColors.deepSkyBlue};
    }

    &.yellow {
      .progress-bar {
        background-color: ${UIVars.setColors.orangeyYellow};
      }
      &.red {
        .progress-bar {
          background-color: ${UIVars.setColors.red};
        }
      }
    }

    .Capital {
      text-transform: uppercase;
    }
    .progress {
      width: 400px;
      height: 6px;
      margin-left: 11px;
      margin-right: 11px;
    }
    .Text__Time {
      color: ${UIVars.setColors.black};
      font-size: 16px;
      font-weight: bold;
      text-transform: lowercase;
    }
  }

  @media screen and (max-width: 425px) {
    .Timer {
      .progress {
        width: 120px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .Timer {
      .progress {
        width: 90px;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .Timer {
      .progress {
        width: 50px;
      }
    }
  }
`

export default Wrapper
