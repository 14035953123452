import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as ItemStyle from './ItemStyle'
import { Icon } from '~ui/Icons'
import { IconProps, PropsMenuItemNavigation } from './Types'
import { UIVars } from '~ui'
import { ItemIndicators } from '../ItemIndicators'
import CountStars from '../CountStars'
import { RouteParams } from '~routes/types'

const ITEM_COMPLETED = [3, 8, 9]

const SetIcon = ({ link, index }: IconProps) => {
  const { answerId } = useParams<RouteParams>()
  const ITEM_PRESENTIAL_COMPLETED =
    link.status?.style === 'attend' || link.status?.style === 'absent'

  if (link.status!.enabled) {
    if (link.status!.style === 'on' || link.id! === Number(answerId)) {
      return <Icon fill={UIVars.setColors.primary} name="in-progress" />
    } else {
      const styles = [link.status!.detail, link.status!.style]
      return styles.includes('completed') ||
        ITEM_COMPLETED.includes(link.status!.id) ||
        ITEM_PRESENTIAL_COMPLETED ? (
        <Icon fill={UIVars.setColors.completed} name="completed" />
      ) : (
        index + 1
      )
    }
  } else {
    return <Icon fill={UIVars.setColors.veryLightPink} name="blocked" />
  }
}

const MenuItemNavigation = ({
  link,
  index,
  setActive,
  activeNavigation,
  setNavId
}: PropsMenuItemNavigation) => {
  const history = useHistory()
  const { resourceId } = useParams<RouteParams>()
  const navigate = () => {
    setActive(true)
    if (setNavId) {
      setNavId(link.id!)
    }
  }

  const goTo = () => {
    if (!link.Childrens) {
      history.push(link.href!)
    }
  }

  const stars = link.indicators?.find(indicator => indicator.id === 3)
  const action = !activeNavigation
    ? navigate
    : !link.status!.enabled
    ? () => {}
    : goTo

  return (
    <ItemStyle.Navigation
      className={link.status!.detail}
      type="navigation"
      total={(link.Childrens && link.Childrens!.length) || 0}
      onClick={action}>
      <div className="resourceState">
        <SetIcon link={link} index={index} resourceId={resourceId} />
      </div>
      <div className="menu_container">
        <div
          className={`${
            activeNavigation && stars ? 'max-width' : ''
          } exercise_title`}>
          <span
            className={`${
              activeNavigation && stars ? 'children-max-width' : ''
            } title_overflow`}>
            <span>{link.name}</span>
            <ItemIndicators
              indicators={link.indicators!}
              activeNavigation={activeNavigation}
            />
          </span>
        </div>
        {link.Childrens && link.Childrens.length > 0 && (
          <span className="arrow">
            <Icon name="arrow-in" />
          </span>
        )}
        {activeNavigation && stars && (
          <CountStars obtained={stars.obtained!} max={stars.max} />
        )}
      </div>
    </ItemStyle.Navigation>
  )
}

export default MenuItemNavigation
