// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'
import { ResourceMeetPayload } from '@eclass/api'

/**
 * Obtiene la data de la vista de detalles en clases presenciales
 *
 * @example
 * ```
 *   await getResourceMeet(this.props.config)
 * ```
 */
const getResourceMeet = (input: ResourceMeetPayload) => {
  const requestId = 'getResourceMeet'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getResourceMeet($input: ResourceMeetInput!) {
        getResourceMeet(input: $input) {
          status {
            success
            name
            code
            detail
          }
          Resources {
            id
            resourceRefTypeId
            title
            description
            Article {
              name
            }
          }
          Menu {
            Navigation {
              prev {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
              next {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
            }
            Links {
              id
              name
              href
              indicators {
                id
                name
                image
                obtained
                max
              }
              status {
                id
                name
                detail
                style
                enabled
              }
              Childrens {
                id
                name
                href
                status {
                  id
                  name
                  detail
                  style
                  enabled
                }
                indicators {
                  id
                  name
                  image
                  obtained
                  max
                }
              }
            }
            Unit {
              id
              name
              title
            }
          }
          AttendStatus {
            id
            enabled
            name
            detail
            style
          }
          Teachers {
            id
            Person {
              picture
              name
              firstSurname
              lastSurname
              fullName
            }
            Profile {
              id
              name
            }
          }
          Recommendations {
            icon
            message
          }
          Lesson {
            status
            id
            remote
            date
            time
            url
            code
            password
            providerId
            headquarterId
            Provider {
              id
              name
              alias
              urlIos
              urlAndroid
            }
            Headquarter {
              id
              name
              address
              lattitude
              longitude
              room
              Location {
                id
                name
                Region2 {
                  id
                  name
                  Country {
                    id
                    name
                  }
                }
              }
            }
            unitId
            Unit {
              id
              name
            }
          }
        }
      }

    `
    const variables = {
      input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.getResourceMeet
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getResourceMeet
