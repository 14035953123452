// @External Dependencies
import React, { Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'

// @Dependencies
import { Icon } from '../Icons'
import UIVars from '../variables'

// @Component
const ModalBasic = ({
  error,
  className = '',
  header,
  subHeader,
  children,
  footer,
  toggle,
  isOpen,
  closeModal,
  iconHeader,
  type,
  fade,
  btnClose
}) => {
  const setBackdrop = () => {
    if (isOpen) {
      switch (type) {
        case 'full':
          return <ModalFull />

        case 'video':
          return <ModalVideo />

        case 'iframe':
          return <ModalIFrame />

        case 'info':
          return <ModalInfo />

        default:
          return <ModalRegular />
      }
    }
  }

  return (
    <Fragment>
      <ModalGlobalStyles />
      {setBackdrop()}
      <Modal isOpen={isOpen} fade={fade} toggle={toggle} className={className}>
        {btnClose && (
          <Icon
            className="close-modal"
            name="close-modal"
            fill="white"
            viewBox={22}
            onClick={closeModal}
          />
        )}
        <section>
          {header && (
            <ModalHeader>
              {iconHeader && (
                <Icon name={iconHeader} fill={UIVars.setColors.whiteThree} />
              )}
              {header}
              {subHeader && <p className="subHeader">{subHeader}</p>}
            </ModalHeader>
          )}
          <ModalBody>{error || children}</ModalBody>
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </section>
      </Modal>
    </Fragment>
  )
}

// @Proptypes
ModalBasic.propTypes = {
  body: PropTypes.object,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  error: PropTypes.bool,
  header: PropTypes.string,
  footer: PropTypes.node,
  subHeader: PropTypes.string,
  toggle: PropTypes.func,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  iconHeader: PropTypes.string,
  fade: PropTypes.bool,
  btnClose: PropTypes.bool
}
ModalBasic.defaultProps = {
  btnClose: true,
  error: false,
  fade: false
}

// @Export Component
export default ModalBasic

const ModalGlobalStyles = createGlobalStyle`
  .modal{
    &.fade {
      transition: none;
    }
    border-radius: 5px;

    .close-modal {
      cursor: pointer;
      margin: 0;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 99;
    }

    &-dialog{
      max-width: 850px;
      width: 100%;
    }

    &-content{
      border: none;
      box-shadow: none;
      border-radius: 10px;

      .modal-header {
        border-radius: 10px 10px 0 0;
        .modal-title {
          display: flex;
          align-items: center;
        }
      }

      .modal-body {
        border-radius: 0 0 10px 10px;
      }

      & > section{
        color: #777781;

        &.modal-disabled {
          &:after {
            content: '';
            background: rgba(255, 255, 255, 0.4);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;
          }
        }

        .modal__message--error {
          margin-right: 1rem;
          color: red;
        }
        .subHeader {
          margin-bottom: 0;
        }

        .DraftEditor__component {
          margin-top: 15px;
        }

        & > div{
          padding: 30px;
          position: relative;
        }
      }
    }

    &-header{
      background: ${UIVars.setColors.primary};
      border: none;
      border-bottom: 1px solid #dedee8;
      justify-content: center;
      padding: 20px 40px !important;

      h4{
        font-size: 1.7857rem;
      }

      button{
        margin-top: 5px;
        margin-right: 0;
        background-color: #c9c9d3;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: white;
        font-size: 20px;
        outline: none;
        opacity: 1;
        transition: all .2s ease;
        :hover{
          color: white;
          opacity: 0.8;
        }
      }

      .modal-title {
        color: white;
        font-size: 20px;
        font-weight: bold;
      }
    }

    &-body{
      color: ${UIVars.setColors.black};
      font-size: 14px;
      line-height: 25px;

      p {
        font-size: inherit;
        line-height: inherit;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    &-footer{
      align-items: center;
      background-color: #f5f5fb;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top: none;
      display: flex;
      justify-content: flex-end;
      span{
        margin-right: 1rem;
        &.left{
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%)
        }
      }
      button:last-child{
        margin-right: 0;
      }
    }

    @media screen and (max-width: 990px) {
      .modal-dialog  {
        .close-modal {
          right: 24px;
          top: 22px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .modal-dialog{
        margin: 0;
      }
    }

    @media screen and (max-width: 425px) {
      border-radius: 0 0 0 0;

      .modal-header{
        border-radius: 0 0 0 0;
      }
    }
  }
`

const ModalVideo = createGlobalStyle`
  .modal-backdrop {
    &.show {
      background: ${UIVars.setColors.black};
      opacity: 1;
    }
  }

  .modal-dialog {
    align-items: center;
    display: flex;

    .close-modal {
      position: fixed;
      right: 55px;
      top: 40px;
    }

    .modal-body {
      padding: 0;
    }

    video {
      display: block;
      width: 100%;
    }
  }
`

const ModalIFrame = createGlobalStyle`
  .modal-content {
    height: 100%;
  }
  .modal {
    border-radius: 0px;

  .modal-dialog {
    height: 100%;
      .modal-content {
        .Svg__Icon {
          opacity: 0.9;
          background-color: gray;
          border-radius: 5px;
          height: 45px;
          overflow: hidden;
          padding: 12px;
          width: 45px;
        }
        section {
          height: 100%;
          .modal-body, > div {
            height: 100%;
            padding: 0px;
            border-radius: 10px;
          }
        }
      }
    }
  }
`

const ModalInfo = createGlobalStyle`
  .modal {
    .modal-content {
      margin: 0 auto;
      width: 690px;
      border-radius: 10px !important;
      box-shadow: 0 5px 10px 0 rgba(199, 199, 199, 0.5);

      .modal-body {
          padding: 0;
          border: ${UIVars.borderSolid};
          border-radius: 10px 10px 10px 10px;
        .info-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-top: 30px;
            margin-bottom: 15px;
          }
          .description-superior {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 11px;
            text-align: center;
          }
          .description-inferior {
            margin-bottom: 16px;
            font-size: 16px;
            text-align: center;
          }
          .description-final {
            margin-bottom: 16px;
            font-size: 16px;
          }
          .get-it {
            cursor: pointer;
            .firstButton{
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-left: ${UIVars.borderSolid};
            }
            .secondButton{
              border-left: ${UIVars.borderSolid};
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            border-top: ${UIVars.borderSolid};
            color: ${UIVars.setColors.deepSkyBlue};
            font-weight: bold;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .modal-backdrop {
    &.show {
      background: white;
      opacity: .8;
    }
  }

  @media screen and (max-width: 425px) {
    .modal {
      margin-top: 10px;
      .modal-dialog {
        height: 100%;
        .modal-content {
          max-width: fit-content;
          margin: 10px;
        }
      }
    }
  }
`

const ModalFull = createGlobalStyle`
  .modal-backdrop {
    &.show {
      background: white;
      opacity: .8;
    }
  }

  .modal-content {
    box-shadow: -1px 6px 11px 0 rgba(47, 47, 47, 0.09)!important;
    margin: 0 auto;
    width: 690px;
    border-radius: 10px;
  }


  @media screen and (max-width: 425px) {
    .modal-dialog {
      height: 100%;
      .modal-content {
        height: 100%;
      }
    }
  }
`

const ModalRegular = createGlobalStyle`
  .modal-backdrop {
    &.show {
      background: white;
      opacity: .8;
    }
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: -1px 6px 11px 0 rgba(47, 47, 47, 0.09);
    margin: 0 auto;
    width: 690px;
  }


  @media screen and (max-width: 425px) {
    .modal-dialog {
      height: 100%;
    }
  }
`
