import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

import { Types } from './types'
import {
  AcademicHistorialIcon,
  CommentsIcon,
  ProfileIcon,
  Question,
  RequirementsIcon,
  TermsIcon
} from '~ui/Icons'
import * as Style from './Style'
import { Store } from '~app_store/types'

/**
 * Componente HeaderPage para vistas dependiendo del tipo se muestra texto e icono correspondiente
 * @example <HeaderPage type={"academicHistory | profile | terms"} hasContainer={true|false} back={} />
 */

export const HeaderPage = ({
  type,
  hasContainer,
  total,
  className
}: Types.Header) => {
  const { t } = useTranslation()
  const {
    ui: { isMobile }
  } = useSelector((state: Store) => state)

  const types = new Map([
    [
      'comments',
      {
        text: t('ResourceComments', {
          context: total ? 'more' : 'none',
          count: Number(total)
        }),
        icon: <CommentsIcon />
      }
    ],
    [
      'profile',
      {
        text: t('CrossMyProfile'),
        icon: <ProfileIcon />
      }
    ],
    [
      'academicHistory',
      {
        text: t('AcademicHistory'),
        icon: <AcademicHistorialIcon />
      }
    ],
    [
      'terms',
      {
        text: t('TermsConditions1'),
        icon: <TermsIcon />
      }
    ],
    [
      'question',
      {
        text: t('ResourceQuestion'),
        icon: <Question />
      }
    ],
    [
      'requirements',
      {
        text: t('TechnicalRequirements'),
        icon: <RequirementsIcon />
      }
    ]
  ])

  const Content = () => (
    <Style.Wrapper>
      <Style.Title>
        {types.get(type)!.icon && (
          <Style.Icon>{types.get(type)!.icon}</Style.Icon>
        )}
        <Style.H1>{types.get(type)!.text}</Style.H1>
      </Style.Title>
    </Style.Wrapper>
  )

  return (
    <Style.Header className={className} data-testid="HeaderPage">
      {hasContainer ? (
        <div data-testid="headerHasContainer">{Content()}</div>
      ) : (
        <Container fluid={isMobile}>
          <Row>
            <Col md="12">{Content()}</Col>
          </Row>
        </Container>
      )}
    </Style.Header>
  )
}
