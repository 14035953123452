import { Types } from './types'

export const initialState: Types.Store = {
  alert: {
    show: false,
    type: ''
  },
  countries: undefined,
  jobs: undefined,
  industries: undefined
}
