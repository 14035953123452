import { CommentReducerAction, CommentReducer } from './types'
import { initialState } from './initialState'

/**
 * Guarda el estado del modal de los comentarios
 */
export const comments = (
  state: CommentReducer = initialState,
  action: CommentReducerAction
): any => {
  switch (action.type) {
    case 'COMMENT':
      return { ...state }
    case 'COMMENT_MODAL_OPEN':
      return handleOpen(action.payload)
    case 'COMMENT_MODAL_CLOSED':
      return initialState
    case 'COMMENT_DISPLAYED':
      return { ...state, displayed: action.payload }
    default:
      return state
  }
}

const handleOpen = (type: CommentReducer) => {
  return {
    ...type,
    show: true
  }
}
