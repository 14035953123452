// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import { ProductAttributes } from '@eclass/sequelize-models'

type Props = {
  product: ProductAttributes
}

/**
 * Wrapper del logo del branding
 * @example
 *    <ProductLogo product={product} />
 */
const ProductLogo = ({ product }: Props) => {
  if (!product.brandingLogo) {
    return null
  } else {
    return (
      <Wrapper className="ProductLogo flex-center">
        <img src={product.brandingLogo} alt={product.name} />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  height: 185px;
  img {
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    height: 105px;
    margin-bottom: 20px;
  }
`

export default ProductLogo
