// Traduccion de variables
const profile = {
  'Show me online to my other classmates':
    'Muestrame conectad{{gender}} a mis compañeros',
  Activated: 'Activado',
  Disabled: 'Desactivado'
}

const spa = {
  'Previous unit pending': 'Pendiente unidad anterior', // SE BORRARA EVENTUALMENTE
  'Final test': 'Examen final', // SE BORRARA EVENTUALMENTE
  'Take test': 'Rendir examen', // SE BORRARA EVENTUALMENTE
  'Continue test': 'Continuar examen', // SE BORRARA EVENTUALMENTE
  Bad: 'Malo', // SE BORRARA EVENTUALMENTE
  Good: 'Bueno', // SE BORRARA EVENTUALMENTE
  Excellent: 'Excelente', // SE BORRARA EVENTUALMENTE
  'What did you think of this material?': '¿Qué te pareció este material?', // SE BORRARA EVENTUALMENTE
  Sending: 'Enviando', // SE BORRARA EVENTUALMENTE
  'Continue studying in your course': 'Continúa estudiando en tu curso',

  // estas traducciones se cambian por las que estan en pestaña curso materiales fila 60 a las 63
  'Oops, you didn`t get correct. Retry!':
    'Oops, no obtuviste correctas. ¡Vuelve a intentarlo!',
  'Don`t be discouraged!': '¡No te desanimes!',
  'You answered everything correctly. Keep it up!':
    'Respondiste todo correctamente. ¡Sigue así!',
  'You hit out of. Try again!':
    'Acertaste {{obtained}} de {{total}}. ¡Vuelve a intentarlo!',

  'Invalid token': 'Token inválido',

  'Did you finish': 'Finalizaste', // pendiente

  // implementar bien este yo.
  point_of: '{{obtained}} de {{total}} punto',
  point_of_plural: '{{obtained}} de {{total}} puntos',
  ErrorLookingForMaterial:
    'Ha ocurrido un error al buscar el material de estudio.'
}

const Helper = {
  'Your course': 'Tu curso'
}

const LevelsStages = {
  'Not available': 'No disponible',
  'You can answer now': 'Ya puedes responder'
}

const PresencialClass = {
  Attend: 'Asistió',
  Absent: 'No asistió'
}

export default {
  ...profile,
  ...spa,
  ...Helper,
  ...LevelsStages,
  ...PresencialClass
}
