// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} InputType
 * @property {number} campusId - ID Campus.
 * @property {number} step - Paso de la confirmación.
 * @property {number} nationId - ID Nacionalidad.
 * @property {string} name - Nombres.
 * @property {string} firstSurname - Primer apellido.
 * @property {string} lastSurname - Segundo apellido.
 * @property {Date} birthday - Fecha de nacimiento.
 * @property {number} gender - Sexo.
 * @property {string} email - Mail.
 * @property {number} addressTypeId - Tipo de dirección.
 * @property {number} countryId - ID País.
 * @property {number} regionId - ID Región.
 * @property {number} locationId - ID Comuna.
 * @property {string} address - Dirección.
 * @property {string} numberAddress - Número dirección.
 * @property {string} block - Block dirección.
 * @property {string} apartment - Departamento dirección.
 * @property {number} phoneTypeId - ID Tipo de Teléfono.
 * @property {string} countryCode - Código de país de Teléfono.
 * @property {string} areaCode - Código de área de Teléfono.
 * @property {string} numberPhone - Número de Teléfono.
 * @property {number} studyLevelId - ID Nivel educacional.
 * @property {boolean} unemployed - Desempleado.
 * @property {number} jobTypeId - ID Tipo de empleo.
 * @property {number} industryId - Industria.
 * @property {number} executivePositionId - ID Posición Ejecutiva.
 * @property {string} company - Empresa.
 * @property {string} position - Cargo.
 * @property {string} professionalResume - Resumen profesional.
 * @property {boolean} contactability - Desea que eClass lo contacte.
 * @property {boolean} agreeTermsConditions - Acepto términos y condiciones.
 * @property {Array<number>} programs - Ids programas
 */

/**
 * Guarda la información de la persona.
 *
 * @param {InputType} input - Parámetros para registrar el ingreso.
 * @example
 * ```js
 *    const response = await userConfirm(input)
 * ```
 * @returns Retorna el Estado si se guardó la información
 */
const userConfirm = input => {
  const requestId = 'userConfirm'
  return async dispatch => {
    getToken()
    const query = `
      mutation UserConfirm($UserConfirmInput: UserConfirmInput!) {
        UserConfirm(input: $UserConfirmInput) {
          status {
            success
            detail
          }
        }
      }
    `
    const values = {
      step: parseInt(input.step, 10)
    }
    if (parseInt(input.step, 10) === 1) {
      values.name = input.name
      values.firstSurname = input.firstSurname
      values.lastSurname = input.lastSurname
      values.birthday = input.birthday
      values.gender = parseInt(input.gender, 10)
      values.nationId = parseInt(input.nationId, 10)
    } else if (parseInt(input.step, 10) === 2) {
      values.email = input.email
      values.addressTypeId = parseInt(input.addressTypeId, 10)
      values.countryId = parseInt(input.countryId, 10)
      values.regionId = parseInt(input.regionId, 10)
      values.locationId = parseInt(input.locationId, 10)
      values.address = input.address
      values.numberAddress = input.numberAddress
      values.block = input.block
      values.apartment = input.apartment
      values.phoneTypeId = parseInt(input.phoneTypeId, 10)
      values.countryCode = input.countryCode
      values.areaCode = input.areaCode
      values.numberPhone = input.numberPhone
    } else {
      values.professionalResume = input.professionalResume
      values.campusId = parseInt(input.campusId, 10)
      values.studyLevelId = parseInt(input.studyLevelId, 10)
      values.unemployed = input.unemployed
      if (!values.unemployed) {
        values.jobTypeId = parseInt(input.jobTypeId, 10)
        values.industryId = parseInt(input.industryId, 10)
        values.executivePositionId = parseInt(input.executivePositionId, 10)
        values.company = input.company
        values.position = input.position
      }
      values.contactability = input.contactability
      values.agreeTermsConditions = input.agreeTermsConditions
      values.programs = values.agreeTermsConditions ? input.programs : []
    }

    const variables = {
      UserConfirmInput: values
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        const { UserConfirm } = response.data.data
        return UserConfirm
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default userConfirm
