import React, { useEffect } from 'react'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import $ from 'jquery'

import { StyleWrapper } from './Style'

// @ts-ignore
window.jQuery = $
require('@fancyapps/fancybox/dist/jquery.fancybox')

type ComponentProps = {
  id?: string
  children: JSX.Element
  className?: string
}

/**
 *
 * Posee los estilos y componentes funcionales del articulo ( video, carousel, imageZoom )
 *
 * @param {any} children
 * @param {string} className
 *
 * @example
 *   <ContentArticle>{children}</ContentArticle>
 */
const ContentArticle = ({
  children,
  className = '',
  id = 'content-article'
}: ComponentProps) => {
  useEffect(() => {
    /**
     * Scripts jQuery
     */
    $(document).ready(() => {
      const documentType: any = document || null
      const ArticleVideo = documentType.querySelector(
        '.ResourceHeader__video__container video'
      )

      if (ArticleVideo) {
        ArticleVideo.pause()
        ArticleVideo.load()
      }

      /**
       * Cambio contenido dinámico en section de carousel según el item activo.
       * Cada item debe venir con un data-title y un data-description.
       */
      const ArticleCarousel = documentType.querySelector(
        'section.Article__carousel'
      )
      if (ArticleCarousel) {
        const carouselContent = () => {
          const carouselTitle = documentType.querySelector(
            '.Article__carousel .carousel-item.active'
          )
          if (carouselTitle) {
            carouselTitle.getAttribute('data-title')
          }
          const carouselDescription = documentType.querySelector(
            '.Article__carousel .carousel-item.active'
          )
          if (carouselDescription) {
            carouselDescription.getAttribute('data-description')
          }
          const title = documentType.querySelector(
            '.Article__carousel .carousel-content h4'
          )
          const description = documentType.querySelector(
            '.Article__carousel .carousel-content p'
          )

          if (title) {
            title.innerHTML = carouselTitle
          }
          if (description) {
            description.innerHTML = carouselDescription
          }
        }

        $('.Article__carousel').on('slid.bs.carousel', () => {
          carouselContent()
        })

        $('.carousel-item').css('display', 'block')
        const carouselContents = [
          ...documentType.querySelectorAll(
            '.Article__carousel .carousel-item .item-content'
          )
        ]
        const sizes = carouselContents.map(box => box.offsetHeight)
        const maxSize = Math.max(...sizes)
        documentType.querySelector(
          '.Article__carousel .carousel-content'
        ).style.height = `${maxSize}px`
        $('.carousel-item').removeAttr('style')
        documentType
          .querySelectorAll('.item-content')
          .forEach((e: any) => e.parentNode.removeChild(e))

        carouselContent()
      }

      if ($(document).scrollTop() !== null) {
        if ($('.footnote a, .Article__references li a:not(.ext)').length) {
          $('.footnote a, .Article__references li a:not(.ext)').each(
            function () {
              $(this).click(function (e) {
                e.preventDefault()
                const strAncla: any = $(this).attr('href')
                if ($(strAncla).length) {
                  $('body, html').animate(
                    {
                      scrollTop: $(strAncla).offset()!.top - 180
                    },
                    {
                      duration: 900
                    }
                  )
                }
              })
            }
          )
        }
      }

      const tooltipClick: any = $('[data-toggle="tooltip"]') || null
      tooltipClick.tooltip({
        trigger: 'click'
      })
      $(documentType).on('click', '.tooltip', function () {
        // @ts-ignore
        $(this).tooltip('hide')
      })
    })

    if ($('.Article__image_zoom > a').length > 0) {
      const item: any = $('.Article__image_zoom > a') || null
      item.fancybox({
        touch: false,
        keyboard: false,
        infobar: false,
        arrows: false,
        toolbar: true,
        buttons: ['close'],
        clickContent: (current: any) => {
          return current.type === 'image' ? false : 'close'
        },
        mobile: {
          clickContent: (current: any) =>
            current.type === 'image' ? false : 'close',
          clickSlide: () => 'close'
        },
        animationDuration: 0,
        beforeShow: () => $('body').css('overflowY', 'hidden'),
        afterShow: (instance: any) => instance.scaleToActual(),
        afterClose: () => $('body').css('overflowY', 'scroll')
      })
    }
    return () => {
      const conte: any = $('[data-toggle="tooltip"]') || null
      conte.tooltip('hide')
    }
  }, [])

  return (
    <StyleWrapper
      className={`ContentArticle ${className}`}
      id="content-article">
      {children}
    </StyleWrapper>
  )
}

export default ContentArticle
