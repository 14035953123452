// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'
import { ResourceCommentDeleteInput } from '@eclass/api'

/**
 * Elimina un adjunto o comentario.
 *
 * @param {import('@eclass/api').ResourceCommentDeleteInput} input - Parámetros para buscar los comentarios.
 * @returns {Promise<import('@eclass/api').ApiRequest>}
 */
export const deleteResourceComment = (input: ResourceCommentDeleteInput) => {
  const requestId = 'deleteResourceComment'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation ResourceCommentDelete($ResourceCommentDeleteInput: ResourceCommentDeleteInput!) {
        ResourceCommentDelete(input: $ResourceCommentDeleteInput) {
          status {
            name
            success
            code
            detail
          }
        }
      }
    `

    const variables = {
      ResourceCommentDeleteInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => response.data.data.ResourceCommentDelete)
      .catch((err: any) => axiosError(err, requestId, dispatch))
  }
}
