import { formatToTimeZone } from 'date-fns-timezone'

const TIMEZONE = 'America/Santiago'
const FORMAT = 'Z'

/**
 * Retorno UTC de santiago de chile
 */
export const dateUTC = () => {
  return `GMT ${formatToTimeZone(new Date(), FORMAT, { timeZone: TIMEZONE })}`
}
