import React, { useState, useEffect } from 'react'
import { ImageBox } from './Box/'
import { ImageBreak } from './Break/'
import { ImageZoom } from './Zoom/'

import { Props } from '../types'

/**
 * # Función metodológica
 * La función de este recurso está delimitada por el objetivo (general) de las imágenes.
 * De personificar y visualizar gráficamente el contenido a exponer.
 * Por su ubicación, las imágenes de subtítulo y uso mediano, deben ser coherentes, seguir el hilo narrativo de la información, finalmente, de jerarquizar los niveles de información.
 * @example
 *    <Image config={options} id={6} />
 */
export const Image = ({ config, id }: Props.Image) => {
  const [renderImage, setRenderImage] = useState<JSX.Element>(<></>)

  useEffect(() => {
    const { type } = config
    switch (type) {
      case 'box':
        setRenderImage(<ImageBox config={config} id={id} />)
        break
      case 'contextual':
        setRenderImage(<ImageBreak config={config} id={id} />)
        break
      case 'zoom':
        setRenderImage(<ImageZoom config={config} id={id} />)
        break
      default:
        setRenderImage(<></>)
    }
  }, [id, config, setRenderImage])

  return renderImage
}
