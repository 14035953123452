// @External Dependencies
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import AppReducers from './Reducers'

const AppPersistConfig = {
  key: 'platform',
  storage,
  blacklist: ['exercises', 'profile', 'comments']
}

// @ts-ignore
const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedReducer = persistReducer(AppPersistConfig, AppReducers)

const store = createStore(
  persistedReducer,
  composeEnhacers(applyMiddleware(thunk))
)
const persistor = persistStore(store)

/**
 * Exportamos configuración de Redux.
 */
export { store, persistor }
