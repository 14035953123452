import styled from 'styled-components'
import { accordionClosed, accordionOpen } from '~assets/svg'

import { UIVars } from '~ui'

// Destruturing de UIVars
const {
  setColors: { hexToRgbA },
  setColors
} = UIVars

export default styled.section`
  .panel {
    margin-bottom: 10px;
    .card-header {
      border-bottom: 0;
      background-color: ${hexToRgbA(setColors.deepSkyBlue, 0.1)};
      border-bottom: 1px solid ${setColors.whiteFour};
      border-left: 3px solid ${setColors.deepSkyBlue};
      border-right: 1px solid ${setColors.whiteFour};
      border-top: 1px solid ${setColors.whiteFour};
      position: relative;
      padding: 0px;

      &.collapsed {
        background: white;
        &:before {
          content: url(${accordionClosed});
          color: ${setColors.brownGreyTwo};
        }
      }

      &:before {
        content: url(${accordionOpen});
        color: ${setColors.primary};
        cursor: pointer;
        font: normal normal normal 1em/31px 'nucleo';
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
      }

      .Accordion__Title {
        color: ${setColors.blackTwo};
        cursor: pointer;
        font-family: ${UIVars.fontRoboto};
        font-size: 18px;
        line-height: 31px;
        max-width: 95%;
        padding: 20px;
        text-decoration: none;
      }

      @media screen and (max-width: 768px) {
        .Accordion__Title {
          font-size: 16px;
        }
      }
    }

    .card-body {
      border-bottom: 1px solid ${setColors.whiteFour};
      border-left: 3px solid ${setColors.deepSkyBlue};
      border-right: 1px solid ${setColors.whiteFour};
      padding: 30px 20px;
      p {
        margin: 0;
      }
    }
    &:nth-child(3n-1) {
      .card-header,
      .card-body {
        border-left: 3px solid ${hexToRgbA(setColors.deepSkyBlue, 0.6)};
      }
    }
    &:nth-child(3n) {
      .card-header,
      .card-body {
        border-left: 3px solid ${hexToRgbA(setColors.deepSkyBlue, 0.3)};
      }
    }
  }

  @media screen and (max-width: 768px) {
    .card-body {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
`
