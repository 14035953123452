// @Dependencias externas
import * as React from 'react'
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'

import Wrapper from './Style'
import { Props } from '../../types'

const emptyParragraph = '<p><br></p>'
/**
 *
 * @example
 *    <ImageBreak config={options} id={62} />
 */
export const ImageBreak = ({ config, id }: Props.ImageBreak) => {
  return (
    <Wrapper
      className={classNames('Article__image_break', {
        'float right': config.position === 'right',
        'float left': config.position === 'left'
      })}
      role="ImageBreak"
      key={id}>
      <img
        src={`https://cdn.eclass.com/${config.image}`}
        alt={`https://cdn.eclass.com/${config.image}`}
      />
      {config.text !== '' &&
        config.text !== emptyParragraph &&
        ReactHtmlParser(config.text)}
    </Wrapper>
  )
}
