import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import * as Style from './Style'
import { Icon } from '../../../Components/UI/Icons'
import { Avatar, Pressed } from '../../../Components/UI'
import HeaderDrop from './HeaderDrop'
import { Items } from './Items'
import { NewDropProps, Indicator } from './Types'
import { RouteParams } from '~routes/types'

const LUDICO = 3

/**
 * Dropdown con contenido personalizable
 * Muestra Dropdown base e items creados según necesidad
 * @example<NewDropdown
 *           btnIcon={'bullet-list'}
 *           btnTitle={}
 *           btnTitleMobile={}
 *           dropdownType={'test'}
 *           headerTitle={title}
 *           items={answers}/>
 */
const NewDropdown = ({
  btnIcon,
  btnTitle,
  btnTitleMobile,
  dropdownType,
  headerTitle,
  items = [],
  activeQuestion,
  userInfo,
  indicator,
  level
}: NewDropProps) => {
  const { t } = useTranslation()
  const { resourceId } = useParams<RouteParams>()
  const [stars, setStars] = useState<Indicator>()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [activeNavigation, setActive] = useState(Number(resourceId) !== 0)
  const [activeNavId, setNavId] = useState(Number(resourceId))
  const toggle = () => setDropdownOpen(prevState => !prevState)

  /**
   * Calcula las estrellas.
   * Mantiene las de la clase o calcula las de los Childrens
   */
  useEffect(() => {
    const activeItem = items.find(item => item.id === activeNavId)!
    if (!activeNavId) {
      setStars(indicator!)
    } else if (activeItem) {
      const total = activeItem.Childrens?.reduce(
        (acc, child) => {
          const starIndicator = child.indicators?.find(
            ({ id }) => id === LUDICO
          )
          if (starIndicator) {
            acc.max += starIndicator.max!
            acc.obtained += starIndicator.obtained!
          }
          return acc
        },
        { max: 0, obtained: 0 }
      )
      setStars({
        ...indicator!,
        ...total
      })
    }
  }, [indicator, activeNavId, items])

  return (
    <Style.Wrapper type={dropdownType} titleMobile={btnIcon}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        {dropdownType === 'avatar' ? (
          <Style.AvatarDrop>
            <DropdownToggle caret>
              <Avatar
                fullName={userInfo?.fullName ? userInfo.fullName : ''}
                userId={userInfo?.id ? userInfo.id : 0}
                picture={userInfo?.picture ? userInfo.picture : ''}
                size={30}
                fontSize={14}
              />
            </DropdownToggle>
          </Style.AvatarDrop>
        ) : (
          <Pressed>
            <Style.ButtonDrop type={dropdownType}>
              <DropdownToggle caret>
                {btnIcon && <Icon name={btnIcon || ''} fill="#fff" />}
                <span className="Desktop_title">{t(`${btnTitle}`)}</span>
                {btnTitleMobile && (
                  <span className="Mobile_title">{t(`${btnTitleMobile}`)}</span>
                )}
              </DropdownToggle>
            </Style.ButtonDrop>
          </Pressed>
        )}
        <Style.DropdownBox type={dropdownType} icon={btnIcon!}>
          <DropdownMenu>
            <HeaderDrop
              dropdownType={dropdownType}
              userInfo={userInfo}
              headerTitle={headerTitle}
              activeNavigation={activeNavigation}
              setActive={setActive}
              items={items}
              activeNavId={activeNavId}
              setNavId={setNavId}
              stars={stars}
            />
            <Style.Itemdrop items={items || null} type={dropdownType}>
              <Items
                dropdownType={dropdownType}
                items={items}
                activeQuestion={activeQuestion}
                activeNavigation={activeNavigation}
                setActive={setActive}
                setNavId={setNavId}
                activeNavId={activeNavId}
                level={level}
              />
            </Style.Itemdrop>
          </DropdownMenu>
        </Style.DropdownBox>
      </Dropdown>
    </Style.Wrapper>
  )
}

export default NewDropdown
