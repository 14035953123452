// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError } from '../../../Services'
import { LoginPayload, LoginInput } from '@eclass/api'

type Response = {
  data: {
    data: {
      login: LoginPayload
    }
  }
}

const UserLogin = (input: LoginInput) => {
  const requestId = 'UserLogin'
  return async (dispatch: any) => {
    const query = `
      mutation login ($LoginInput: LoginInput!) {
        login (input: $LoginInput) {
          token
          status {
            code
            name
            success
            detail
          }
        }
      }
    `
    const variables = {
      LoginInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: Response) => {
        if (response.data.data?.login?.status?.success) {
          dispatch({
            type: 'LOGIN',
            payload: response.data.data.login
          })
        }
        return response.data.data.login
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default UserLogin
