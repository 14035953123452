// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'
/**
 * @typedef {Object} InputType
 * @property {number} studentId - Alumno
 * @property {number} courseId - Curso
 * @property {number} unitId - Unidad
 * @property {number} resourceId - Recurso
 * @property {number} rating - Evauación (caritas del 1 al 5)
 */

/**
 * Guarda la valorización de un recurso por un alumno
 *
 * @param {InputType} input - Parámetros para registrar el ingreso.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444,
 *      rating: 5,
 *    }
 *    const response = await addResourceRating(input)
 * ```
 * @returns {Promise<import('@eclass/api').ResourceRatingPayload>} Retorna el Estado si se guardó la valorización del alumno
 */
const addResourceRating = input => {
  const requestId = 'addResourceRating'
  return async dispatch => {
    getToken()
    const query = `
      mutation addResourceRating($ResourceStudentRatingInput: ResourceStudentRatingInput!) {
        addResourceRating(input: $ResourceStudentRatingInput) {
          status {
            name
            code
            success
            detail
          }
        }
      }
    `
    const variables = {
      ResourceStudentRatingInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10),
        unitId: parseInt(input.unitId, 10),
        resourceId: parseInt(input.resourceId, 10),
        rating: parseInt(input.rating, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.addResourceRating)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default addResourceRating
