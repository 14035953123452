// @External Dependencies
import axios from 'axios'
import axiosCancel from 'axios-cancel'

axiosCancel(axios, {
  debug: true
})

/**
 * Cancela un request si desmontamos el componente
 * @param {number} [requestId] - id de la petición, si está vacía cancela todas las peticiones.
 * @example
 * ```js
 *  componentWillUnmount () {
 *    axiosAbort()
 *  }
 * ```
 */
const abort = requestId => {
  requestId ? axios.cancel(requestId) : axios.cancelAll()
}

// @Export Component
export default abort
