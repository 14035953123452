const Popover = `
  .popover{
    border-width: 2px;
    max-width: 700px;
    z-index: 9999;

    &-title{
      background: #fff
    }
    &__filter{
      display: grid;
      grid-template-columns: 1fr 140px;
      grid-template-rows: auto;
      &-left{
        .vertical{
          margin-bottom: 5px;
        }
      }

      footer{
        border-top: 1px solid rgba(0,0,0,.2);
        display: flex;
        grid-column-end: 3;
        grid-column-start: 1
        justify-content: space-between;
        margin-left: -14px;
        margin-top: 9px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 11px;
        width: calc(100% + 28px);
        button{
          font-size: 1rem;
          margin-right: 0;
        }
      }
    }
  }
`
export default Popover
