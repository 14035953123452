import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { UIVars } from '~ui'
import { Types } from './types'

const showMessage = (item: Types.UnitBlock) => {
  const repetition = item.indicators?.find(
    ({ image }) => image === 'repetition'
  )
  return (
    repetition &&
    repetition?.obtained! > 0 &&
    repetition?.max! > repetition?.obtained!
  )
}

export const LabelYellow = ({ item, children }: Types.LabelYellow) => {
  const { t } = useTranslation()
  const { remaining } = useSelector(
    (state: Types.Store) => state.courses.getCourseInfo.Course!
  )

  if (children) {
    return (
      <Wrapper>
        <span className="DataLabel__Indicator">
          {children}
          <div className="arrow_text" />
        </span>
      </Wrapper>
    )
  }

  const hrefSplit = item
    ?.href!.split('/')
    .slice(1, 3)
    .join('/')
  if (hrefSplit === 'test/alert') {
    if (showMessage(item!)) {
      return (
        <Wrapper>
          <span className="DataLabel__Indicator">
            {t('ContentDayRemaining', { count: remaining })}
            <div className="arrow_text" />
          </span>
        </Wrapper>
      )
    } else {
      return null
    }
  } else if (hrefSplit === 'test/pending') {
    return (
      <Wrapper>
        <span className="DataLabel__Indicator">
          {t('ContentPendingEva')}
          <div className="arrow_text" />
        </span>
      </Wrapper>
    )
  } else if (
    item?.ResourceType?.id === 4 &&
    item.label &&
    item.label?.detail !== '' &&
    ![6, 7].includes(item.label?.id!) &&
    item.href !== ''
  ) {
    return (
      <Wrapper>
        <span className={`DataLabel__Indicator ${item.label?.style}`}>
          {item.label!.detail}
          <div className={`arrow_text ${item.label?.style}`} />
        </span>
      </Wrapper>
    )
  } else {
    return null
  }
}

const Wrapper = styled.div`
  margin-bottom: 5px;
  .DataLabel__Indicator {
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: initial;
    margin-left: 10px;
    padding: 3px 10px 3px 2px;
    text-transform: none;
    position: relative;
    background: ${UIVars.setColors.orangeyYellow};
    color: ${UIVars.setColors.whiteThree};
    &.info {
      background: ${UIVars.setColors.primary};
    }
  }

  .arrow_text {
    border-style: solid;
    border-width: 10px 10px 10px 0;
    display: inline-block;
    height: 100%;
    left: -9px;
    position: absolute;
    top: 0;
    width: 0;
    border-color: transparent ${UIVars.setColors.orangeyYellow} transparent
      transparent;
    &.info {
      border-color: transparent ${UIVars.setColors.primary} transparent
        transparent;
    }
  }
`
