import styled from 'styled-components'
import { UIVars } from '~ui'

export const Wrapper = styled.section`
  margin: 50px 0 0 calc(-20%);
  width: 140%;
  position: relative;
  overflow: hidden;
  .slider_wrapper {
    display: flex;
    transition: transform 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    width: 100%;
    .carousel_item {
      figure {
        margin: 0;
        img {
          max-width: 690px;
        }
      }
    }
  }

  .slider_content {
    background-color: ${UIVars.setColors.blackTwo};
    color: ${UIVars.setColors.whiteThree};
    bottom: 95px;
    right: 0;
    width: 380px;
    position: absolute;

    .item-content {
      padding: 30px 30px 25px 30px;
      .item-text {
        h5 {
          color: ${UIVars.setColors.whiteThree};
          font: 300 20px / 25px 'Roboto';
          font-weight: bold;
          margin: 0px 0px 8px 0px;
        }
        p {
          color: ${UIVars.setColors.whiteThree};
          font: 300 16px / 25px 'Roboto';
          margin: 0;
        }
      }
    }
  }
  .slide-footer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 15px 0px 30px 0px;
    .carousel-control {
      display: flex;
      background-color: ${UIVars.setColors.deepSkyBlue};
      border-radius: 50%;
      height: 40px;
      width: 40px;
      align-items: center;
      justify-content: center;
    }
    .carousel-controls-prev {
      text-align: right;
    }
    .carousel-controls-next {
      display: flex;
      order: 3;
    }
  }
  .carousel-indicators {
    bottom: 0;
    margin: 0;
    order: 2;
    position: static;
    padding: 0 60px;
    li {
      background-color: ${UIVars.setColors.veryLightPink};
      border-radius: 50%;
      cursor: pointer;
      flex: 0 1 auto;
      height: 10px;
      margin: 0 5px;
      list-style: none;
      position: relative;
      text-indent: -999px;
      width: 10px;
      &.active {
        background-color: ${UIVars.setColors.deepSkyBlue};
      }
    }
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    .slider_wrapper {
      display: flex;
      transition: transform 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      width: 100%;
      .carousel_item {
        figure {
          margin: 0;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
    .slider_content {
      background-color: ${UIVars.setColors.blackTwo};
      color: ${UIVars.setColors.whiteThree};
      bottom: 95px;
      right: 0;
      width: 100%;
      position: static;

      .item-content {
        .item-text {
          h5 {
            color: ${UIVars.setColors.whiteThree};
            font: 300 20px / 25px 'Roboto';
            font-weight: bold;
            margin: 0px 0px 8px 0px;
          }
          p {
            color: ${UIVars.setColors.whiteThree};
            font: 300 16px / 25px 'Roboto';
            margin: 0;
          }
        }
      }
    }
    .slide-footer {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      padding: 15px 0px 30px 0px;
      .carousel-indicators {
        padding: 0 10px;
      }
    }
  }
`
export default Wrapper
