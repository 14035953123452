/**
 * Valida el input de tipo checkbox y lista las opciones seleccionadas
 * @param {string} name
 */
const inputCheckValidate = name => {
  const fields = document.getElementsByName(name)
  const listFields = []
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].checked === true) {
      listFields.push(fields[i].value)
    }
  }
  return listFields
}
export default inputCheckValidate
