// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Lista los cursos de una persona según los filtros
 *
 * Ocupamos esta query para Dashboard de Cursos y Registro Académico
 *
 * @param {InputType} input
 * @example
 * ```js
 *    const input = {
 *      type: 'PAST'
 *    }
 *    const students = await this.props.getCourseList(input)
 * ```
 * @returns {Promise<import('@eclass/api').CourseListPayload>}
 */
const getTabsInfo = (input: any) => {
  const requestId = 'getCourseInfo'
  return async dispatch => {
    getToken()
    const query = `
      query getCourseInfo($CourseInfoInput: CourseInfoInput!) {
        getCourseInfo(input: $CourseInfoInput) {
          status {
            success
            detail
          }
          Course {
            progress
            haveQuestions
          }
          Tabs {
            id
            name
            url
            label {
              type
              name
              state
            }
          }
        }
      }
    `

    const variables = {
      CourseInfoInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => response.data.data.getCourseInfo)
      .catch(err => {
        axiosError(err, requestId, dispatch)
      })
  }
}

export default getTabsInfo
