import styled, { keyframes } from 'styled-components'

import { UIVars } from '~ui'
import { TextLink } from '../style'

export const File = styled.article`
  align-items: center;
  background: ${UIVars.setColors.whiteTwo};
  border: 1px solid ${UIVars.setColors.backgroundGrey};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 53px;
  padding: 15px;
  position: relative;
  margin-bottom: 8px;
  &:first-child {
    margin-top: 30px;
  }
  svg {
    cursor: pointer;
    margin: 0;
  }
  > a {
    height: 30px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  button.download {
    height: 29px;
    margin-right: 0;
    width: 30px;
    .button__container {
      height: 100%;
    }
  }
`

export const Name = styled.div`
  align-items: center;
  color: ${UIVars.setColors.blackThree};
  display: flex;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  width: 100%;
  .name {
    margin-bottom: 0;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    color: ${UIVars.setColors.brownThree};
    font-size: 14px;
    margin-left: 4px;
    margin-right: 45px;
    text-transform: uppercase;
  }
`

const Animation = {
  loading: keyframes`
    0% {
      width: 0;
    }
    10% {
      width: 10%;
    }
    35% {
      width: 30%;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 80%;
    }
  `,
  completed: keyframes`
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  `,
  fadeOut: keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `
}

export const Uploading = styled.div`
  background: ${UIVars.setColors.backgroundGrey};
  border-radius: 5px;
  bottom: -7px;
  height: 4px;
  position: absolute;
  overflow: hidden;
  width: 100%;
  & > div {
    position: relative;
    width: 100%;
    div {
      background: ${UIVars.setColors.primary};
      left: 0;
      position: absolute;
      top: 0;
    }
  }
  div {
    border-radius: 5px;
    height: 100%;
  }
  &.success {
    animation: ${Animation.fadeOut} 0.1s 0.5s forwards;
    .full {
      animation: ${Animation.completed} 0.5s forwards;
    }
  }
  .loading {
    animation: ${Animation.loading} 20s forwards;
  }
`

export const AllDelete = styled(TextLink)`
  display: block;
  font-size: 14px;
  line-height: 26px;
  text-align: right;
`

export const Loading = styled.div`
  margin-top: -1px;
  svg path {
    fill: ${UIVars.setColors.brownThree};
  }
`

export const Actions = styled.div`
  position: absolute;
  right: 15px;
  transform: translateY(1px);
`
