import styled from 'styled-components'

import { UIVars } from '~ui'
import { Types } from './types'

export const Header = styled.header`
  background-color: ${UIVars.setColors.whiteTwo};
  width: 100%;
  &.onComments {
    height: 90px;
  }
  @media screen and (max-width: 768px) {
    &.onComments {
      height: 120px;
    }
  }
`

export const Wrapper = styled.div<Types.StyleWrapper>`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 116px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    min-height: 72px;
  }
  @media screen and (max-width: 640px) {
    align-items: flex-start;
    flex-direction: column;
    padding: ${({ back }: Types.StyleWrapper) => (back ? '20px' : '0')} 0;
  }
`

export const Back = styled.div`
  left: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  span {
    color: ${UIVars.setColors.brownGreyTwo};
    font-size: 14px;
    font-weight: 500;
  }
  @media screen and (max-width: 640px) {
    left: 0px;
    margin-bottom: 15px;
    position: relative;
    top: 0;
    transform: translateY(0);
  }
`

export const Title = styled.div`
  align-items: center;
  display: flex;
  @media screen and (max-width: 640px) {
    margin: 0 auto;
  }
`

export const Icon = styled.div`
  svg {
    height: 42px;
  }
`

export const H1 = styled.h1`
  color: ${UIVars.setColors.blackThree};
  font-size: 24px;
  font-weight: 300;
  margin: 0 0 0 20px !important;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`
