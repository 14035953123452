// @External Dependencies
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

// @Local Dependencies
import Header from '../Header'
import Footer from '../Footer'
import { Zendesk } from '../Zendesk/'
import { Store } from '~app_store/types'
import { AppFrameProps } from './types'
import { ModalTerms } from '../TermsAndCondModal'

/**
 * Componente base para las rutas privadas.
 *
 * @example
 * <AppFrame config={domainConfig}>
 *    <Component {...props} />
 * </AppFrame>
 */
const AppFrame = ({ config, children }: AppFrameProps) => {
  const { pathname } = useLocation()
  const {
    ui: { courseWelcome }
  } = useSelector((state: Store) => state)
  const [showFooter, setShowFooter] = useState(true)

  useEffect(() => {
    /**
     * Validamos que se pueda mostrar o no el <Footer />
     *
     * - paths: resource, confirmation, test, exercise
     * - config.appMobile: true
     * - courseWelcome: true
     */
    const pathWithoutFooter = ['resource', 'confirmation', 'test', 'exercise']
    const hidePath = pathWithoutFooter.some(path => pathname.includes(path))
    if (!config.appMobile && !hidePath && !courseWelcome) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }, [pathname, config.appMobile, courseWelcome])
  return (
    <section id="MainContainer">
      <ModalTerms />
      {config.keyZendesk!.length > 0 && <Zendesk value={config.keyZendesk!} />}
      {!config.appMobile && <Header config={config} />}
      <div id="ViewContainer">{React.cloneElement(children, { config })}</div>
      {showFooter && <Footer />}
    </section>
  )
}

export default AppFrame
