const PENDING = 0
const ANSWERED = 1
const CORRECT = 2
const INCORRECT = 3
const OMITTED = 4
const PENDING_CANT_ANSWER = 5
const ANSWERED_FB = 6

/**
 * Estados de las preguntas.
 */
export const getQuestionStatus = (t: (s: string) => string, cases?: number) => {
  let status
  switch (cases) {
    case PENDING:
      status = { className: 'Pending', status: t('ResourcePending') }
      break

    case ANSWERED:
    case ANSWERED_FB:
      status = { className: 'Answered', status: t('ResourceAnswered') }
      break

    case CORRECT:
      status = { className: 'Correct', status: t('ResourceCorrect') }
      break

    case INCORRECT:
      status = { className: 'Incorrect', status: t('ResourceIncorrect') }
      break

    case OMITTED:
      status = { className: 'Omitted', status: t('ResourceOmitted') }
      break

    case PENDING_CANT_ANSWER:
      status = { className: 'Pending', status: t(`Can't Answer`) }
      break

    default:
      status = { className: 'Pending', status: t('ResourcePending') }
      break
  }

  return status
}
