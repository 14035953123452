// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { getToken, axiosError } from '../../../Services'
/**
 * Obtiene los programas de una persona
 * @example
 * ```js
 *    const response = await getPrograms()
 * ```
 * @returns Retorna una lista con Programas (id, name, campusId) y el Estado de la petición.
 */
const getPrograms = () => {
  const requestId = 'getPrograms'
  return async dispatch => {
    getToken()

    const query = `
      query getProgramList{
        getProgramList {
          status {
            success
            detail
          }
          Programs {
            id
            name
            campusId
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const { getProgramList } = response.data.data
        const { programs } = getProgramList
        dispatch({
          type: 'GET_PROGRAM_LIST',
          payload: programs
        })
        return getProgramList
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getPrograms
