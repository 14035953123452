/**
 * TODO:
 * - Hacer test
 */
import { useState, useCallback, useEffect } from 'react'

import { Types } from '../types'

/**
 * Hooks que maneja la posicion del icono ancla de las respuestas.
 *
 * Necesita la referencia del padre
 * y saber si se esta mostrando las respuestas
 * @example
 * const { fixed } = useFixed({
 *  refsAnswers: refsAnswers.current,
 *  show
 * })
 */
export const useFixed = ({ refsAnswers, show }: Types.UseFixed) => {
  const [fixed, setFixed] = useState('')

  /** Manejo la posicion del ancla en la respuesta */
  const handleState = useCallback(() => {
    if (show) {
      if (refsAnswers) {
        const data = refsAnswers.getBoundingClientRect()
        const position = data.y
        const heightNav = 80
        const heightSvg = 16
        /** Si el div ya lo tapa el nav, seteo clase fixed o fixedBottom */
        if (position < heightNav) {
          /**
           * Si el div ya no es visible, seteo clase fixedBottom,
           * de no ser así seteo la data fixed
           */
          if (position < heightNav + heightSvg - data.height) {
            if (fixed !== 'fixedBottom') {
              setFixed('fixedBottom')
            }
          } else {
            if (fixed !== 'fixed') {
              setFixed('fixed')
            }
          }
        } else {
          if (fixed !== '') {
            setFixed('')
          }
        }
      }
    }
  }, [fixed, show, refsAnswers])

  /** Escucho siempor el scroll y resize del viewport */
  useEffect(() => {
    handleState()
    window.addEventListener('resize', handleState)
    window.addEventListener('scroll', handleState)
    return () => {
      window.addEventListener('resize', handleState)
      window.removeEventListener('scroll', handleState)
    }
  }, [handleState])

  return { fixed }
}
