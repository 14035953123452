import styled from 'styled-components'

import { UIVars } from '~ui'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  figure {
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: flex-start;
    margin-bottom: 0;
    max-width: 100px;

    &:not(:last-child) {
      margin-right: 16px;
    }
    &.main {
      height: 100%;
      max-height: 103px;
      max-width: 184px;
    }
    &.sameSize {
      border-radius: ${UIVars.borderRadius};
      overflow: hidden;
    }
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  @media screen and (max-width: 915px) {
    figure,
    figure.main {
      border-radius: ${UIVars.borderRadius};
      height: 56px;
      max-width: 100px;
      overflow: hidden;
    }
  }
  @media screen and (max-width: 336px) {
    figure {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
`
