import styled from 'styled-components'

import { UIVars } from '~ui'
import {
  blueQuote,
  outstanding,
  featured,
  accordionOpen,
  accordionClosed,
  carouselRight
} from '~assets/svg'

const {
  setColors: { hexToRgbA },
  setColors
} = UIVars

export const StyleWrapper = styled.article`
  color: ${setColors.blackTwo};
  font-family: ${UIVars.fontRoboto};
  margin: 0 auto;
  max-width: 700px;
  padding: 0;
  iframe {
    max-width: 100%;
  }

  video {
    width: 100% !important;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  strong {
    word-break: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 50px 0 20px 0;
  }

  h3 {
    font: bold 36px / 50px ${UIVars.fontRoboto};
  }

  h4 {
    font: medium 32px / 52px ${UIVars.fontRoboto};
  }

  h5 {
    font: medium 26px / 43px ${UIVars.fontRoboto};
  }

  h6 {
    color: ${setColors.black};
    font: bold 20px / 32px ${UIVars.fontRoboto};
  }

  p {
    color: ${setColors.black};
    font: 18px / 31px ${UIVars.fontRoboto};
    margin-bottom: 0;

    & + p,
    & + section[class*='Article__'].float + p {
      margin-top: 40px;
    }
  }

  a {
    color: ${setColors.deepSkyBlue};
    &:hover {
      color: #333;
    }
  }

  sup {
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    &.footnote {
      a {
        font-family: bold 12 / 31 ${UIVars.fontLora};
        letter-spacing: 3px;
      }
    }
  }

  abbr {
    color: ${setColors.deepSkyBlue};
    text-decoration: none;
  }

  ul {
    margin: 20px 0 0 30px;
    list-style-type: none;
    padding: 0;

    & + p,
    & + section[class*='Article__'].float + p {
      margin-top: 50px;
    }

    li {
      strong,
      em {
        display: contents;
      }
      a {
        display: contents;
      }
      display: flex;
      font: 18px / 31px ${UIVars.fontRoboto};
      margin-bottom: 16px;
      padding-top: 2px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        background-color: ${setColors.blackTwo};
        content: '';
        display: inline-block;
        height: 31px;
        mask: url(${carouselRight}) no-repeat center left;
        mask-size: 8px 12px;
        min-width: 20px;
      }
    }

    &.numeric {
      counter-reset: item;
      li {
        counter-increment: item;
        a {
          display: contents;
        }

        &::before {
          content: counter(item) '.';
          font-family: ${UIVars.fontRoboto};
          font-weight: bold;
          width: initial;
        }
      }
    }
  }
  ol {
    margin-left: 50px;
    padding: 20px;
    > li {
      list-style-type: upper-alpha;
      strong {
        font: 18px / 31px ${UIVars.fontRoboto};
        font-weight: bolder;
      }
      ol {
        margin-left: 0;
        counter-reset: item1;
        counter-increment: item1;
        padding: 0;
        > li {
          list-style-type: none;
          counter-increment: item2;
          margin-bottom: 10px;
          &:before {
            content: counters(item1, '.') '.' counters(item2, '.') '. ';
            font: bold 15px / 1.72 ${UIVars.fontRoboto};
          }
        }
      }
    }
  }

  .list-number {
    counter-reset: section;
    font: 18px / 31px ${UIVars.fontRoboto};
    list-style-type: none;
    margin-left: 30px;
    padding: 20px;
    li {
      margin-bottom: 0px;
    }
    ol {
      padding: 0;
    }
    > li {
      list-style: none;
      &:before {
        counter-increment: section;
        content: counters(section, '.') '. ';
        font: bold 18px / 31px ${UIVars.fontRoboto};
      }
    }
  }

  .list-alpha {
    counter-reset: roman;
    font: 18px / 31px ${UIVars.fontRoboto};
    list-style: none;
    margin-left: 30px;
    padding: 20px;
    li {
      margin-bottom: 0px;
    }
    > li:before {
      counter-increment: roman;
      content: counter(roman, upper-latin) '. ';
      font: bold 18px / 31px ${UIVars.fontRoboto};
    }
    > li {
      list-style: none;

      ol {
        counter-reset: number;
        padding: 0;
        > li {
          &:before {
            counter-increment: number;
            content: counter(roman, upper-latin) '.' counters(number, '.') '. ';
            font: bold 18px / 31px ${UIVars.fontRoboto};
          }
        }
      }
    }
  }

  & > section[class*='Article__']:not(.float) {
    margin-top: 50px;
    & + p {
      margin-top: 50px;
    }
  }
  .Article {
    &__video {
      video {
        width: 100%;
      }
    }

    &__accordeon {
      .panel {
        margin-bottom: 10px;
        .card-header {
          border-bottom: 0;
          background-color: ${hexToRgbA(setColors.deepSkyBlue, 0.1)};
          border-bottom: 1px solid ${setColors.whiteFour};
          border-left: 3px solid ${setColors.deepSkyBlue};
          border-right: 1px solid ${setColors.whiteFour};
          border-top: 1px solid ${setColors.whiteFour};
          position: relative;
          padding: 0px;

          &.collapsed {
            background: white;
            &:before {
              content: url(${accordionClosed});
              color: ${setColors.brownGreyTwo};
            }
          }

          &:before {
            content: url(${accordionOpen});
            color: ${setColors.primary};
            cursor: pointer;
            font: normal normal normal 1em/31px 'nucleo';
            line-height: 9px;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
          }

          h5 {
            color: ${setColors.blackTwo};
            cursor: pointer;
            font: 18px / 31px ${UIVars.fontRoboto};
            margin: 0;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 20px;
          }
        }
        .card-body {
          border-bottom: 1px solid ${setColors.whiteFour};
          border-left: 3px solid ${setColors.deepSkyBlue};
          border-right: 1px solid ${setColors.whiteFour};
          padding: 30px 20px;
          p {
            margin: 0;
          }
        }
        &:nth-child(3n-1) {
          .card-header,
          .card-body {
            border-left: 3px solid ${hexToRgbA(setColors.deepSkyBlue, 0.6)};
          }
        }
        &:nth-child(3n) {
          .card-header,
          .card-body {
            border-left: 3px solid ${hexToRgbA(setColors.deepSkyBlue, 0.3)};
          }
        }
      }
    }
    &__featured {
      margin: 40px 0;
      width: 250px;
      p {
        border-bottom: 2px solid ${hexToRgbA(setColors.deepSkyBlue, 0.5)};
        border-top: 2px solid ${hexToRgbA(setColors.deepSkyBlue, 0.5)};
        font: 20px / 34px ${UIVars.fontLora};
        padding: 35px 0;
        position: relative;
        text-rendering: auto;
        &:before {
          background: white;
          content: url(${featured});
          left: 50%;
          padding: 0 10px;
          position: absolute;
          top: 0;
          transform: translate(-50%, -50%);
        }
      }
      &.float {
        &.left {
          float: left;
          margin-left: -125px;
          margin-right: 40px;
        }
        &.right {
          float: right;
          margin-right: -125px;
          margin-left: 40px;
        }
      }
      &.block {
        clear: both;
        margin: 50px -50px 0;
        width: 115%;
      }
    }

    &__image_box {
      align-items: center;
      display: flex;
      margin: 50px -12vw 0;
      max-width: 1000px;

      figure {
        margin: 0 50px 0 0;
      }

      p {
        color: ${setColors.deepSkyBlue};
        font: 20px / 34px ${UIVars.fontLora};
        margin: 0;
      }

      &.round {
        figure img {
          max-width: 250px;
          border-radius: 50%;
        }
      }
      &.framed {
        figure img {
          max-width: 250px;
        }
      }
    }
    &__image_break {
      img {
        width: 100%;
      }
      p {
        font: italic 12px / 16px ${UIVars.fontRoboto};
        margin-top: 10px;
      }
    }
    &__quote {
      margin: 50px 55px 0;
      p {
        color: ${setColors.brownishGrey};
        font: 20px / 33px ${UIVars.fontLora};
        margin-bottom: 30px;
      }

      .Article__quote__container {
        align-items: flex-start;
        display: flex;
        &::before {
          content: url(${blueQuote});
          flex-shrink: 0;
          height: 23px;
          margin-top: 8px;
          margin-right: 10px;
          width: 30px;
        }
      }
      footer {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;
        p {
          margin: 0;
          padding: 0px;
          font-size: 12px;
          color: ${setColors.blackTwo};
          display: block;
          &-:nth-child(2) {
            color: ${setColors.brownishGrey};
          }
          &::before {
            content: none;
          }
        }
        figure {
          margin: 0px;
          img {
            border-radius: 50%;
            width: 52px;
            margin: 0px 10px;
          }
        }

        div {
          text-align: left;
          p {
            font: 12px / normal ${UIVars.fontRoboto};
            &:first-child::before {
              content: none;
            }
            & + p {
              color: ${setColors.brownishGrey};
              margin-top: 5px;
            }
          }
          a {
            color: ${setColors.primary};
            font-size: 12px;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
    &__outstanding {
      &:before {
        content: url(${outstanding});
        display: block;
        line-height: 0;
        margin-bottom: 20px;
        text-align: center;
      }

      p {
        color: ${setColors.deepSkyBlue};
        font: 20px / 34px ${UIVars.fontLora};
        text-align: center;
      }
    }
    &__audio {
      .froala-updateAudio {
        width: 100% !important;
        audio {
          width: 100% !important;
        }
      }
      .froala-updateAudio-tool,
      .froala-updateAudio-titulo,
      .froala-updateAudio-descripcion {
        display: none;
      }
    }
  }

  @media screen and (max-width: 990px) {
    .Article {
      &__image_box {
        margin: 50px 0 0;
      }
      &__featured {
        padding: 23px 0 0;
        &.float {
          &.left,
          &.right {
            float: none;
            margin: 50px 0 0;
            text-align: center;
            width: 100%;
          }
        }

        &.block {
          margin: 50px 0 0;
          width: 100%;
        }

        p {
          padding: 30px 0;
          font: 18px / 30px ${UIVars.fontLora};
        }

        & + p {
          margin-top: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 40px 0 20px 0;
    }

    h2 {
      font-size: 28px;
    }

    h3,
    h4,
    h5,
    h6 {
      font-family: ${UIVars.fontRoboto};
    }

    h3 {
      font-size: 28px;
      line-height: 43px;
    }

    h4 {
      font-size: 26px;
      line-height: 40px;
    }

    h5 {
      font-size: 23px;
      line-height: 36px;
    }

    h6 {
      font-size: 20px;
      line-height: 32px;
    }

    p {
      font: 16px / 28px ${UIVars.fontRoboto};
    }

    ul {
      margin: 20px 30px 0 30px;
      li {
        font: 16px / 28px ${UIVars.fontRoboto};
      }
    }

    .Article {
      &__outstanding {
        p {
          font: 18px / 30px ${UIVars.fontLora};
        }
      }

      &__accordeon {
        .card-body {
          p {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }

      &__image_box {
        figure {
          margin: 0 30px 0 0;
        }

        p {
          font-size: 18px;
          line-height: 30px;
        }
      }

      &__image_break {
        margin: 50px -15px 0;
      }

      &__image_zoom {
        margin-left: 0;
        margin-right: 0;

        a::after {
          bottom: 10px;
          right: 10px;
        }
      }

      &__quote {
        margin: 30px 0 0;
        p {
          font: 18px / 30px ${UIVars.fontLora};
          margin-bottom: 30px;
        }
        flexible {
          justify-content: flex-start;
          padding-left: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .Article {
      &__image_box {
        flex-direction: column;
        text-align: center;
        figure {
          margin: 0 0 30px 0;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    ul {
      margin: 50px 0 0;
    }
  }

  @media screen and (max-width: 480px) {
    ol {
      padding: 20px 0;
    }
  }
`
