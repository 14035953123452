import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { getToken, axiosError } from '../../../Services'

const getAllInstitutionTypes = () => {
  const requestId = 'getAllInstitutionTypes'
  return async dispatch => {
    getToken()
    const query = `
      query {
        allInstitutionTypes {
          id
          name
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const { allInstitutionTypes } = response.data.data
        if (response.status === 200 && allInstitutionTypes) {
          return {
            data: allInstitutionTypes,
            status: {
              success: true,
              detail: 'Peticion exitosa'
            }
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar los tipos de instituciones'
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getAllInstitutionTypes
