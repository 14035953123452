import React from 'react'

export const Evaluation = () => (
  <svg
    role="iconEvaluation"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="24.5"
        cy="24.5"
        r="24.5"
        fill="#E0EEFA"
        fillRule="nonzero"></circle>
      <path
        fill="#0189FF"
        d="M40.143 43.612a2 2 0 01-.212.895l-1.574 3.142a1.997 1.997 0 01-3.571 0l-1.574-3.142a2 2 0 01-.212-.895v-12.66h7.143v12.66zM33 27a2 2 0 012-2h3.143a2 2 0 012 2v1.571H33V27z"></path>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25.276 44.143H8.952A2.958 2.958 0 016 41.18V7.106a2.958 2.958 0 012.952-2.963h25.096A2.958 2.958 0 0137 7.106V20.81M12 12h14m-14 6h14m-14 6h14m-14 6h14m-14 6h14"></path>
    </g>
  </svg>
)
