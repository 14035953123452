// @External Dependencies
import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Component
function MultilineEllipsis ({ text, clamp, className }) {
  return (
    <Wrapper className={`MultilineEllipsis ellipsis-container ${className}`}>
      <Dotdotdot clamp={clamp}>{ReactHtmlParser(text)}</Dotdotdot>
    </Wrapper>
  )
}

// @Proptypes
MultilineEllipsis.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  clamp: PropTypes.number
}

MultilineEllipsis.defaultProps = {
  clamp: 2
}

export default MultilineEllipsis

const Wrapper = styled.div`
  font-size: 12px;
`
