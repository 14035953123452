// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// @Dependencies
import BaseInputSelection from './BaseInputSelection'
import Info from './Info'
import Label from './Label'

// @Component
class Radio extends Component {
  state = {
    selected: this.props.selected || ''
  }

  static getDerivedStateFromProps (nextProps) {
    if (nextProps.selected) {
      return {
        selected: nextProps.selected
      }
    }
    return null
  }

  handleChange = e => {
    this.setState({ selected: e.target.value })
    if (this.props.onChange !== undefined) {
      this.props.onChange(e)
    }
  }

  render () {
    const {
      info,
      label,
      disabled,
      name,
      required,
      size,
      vertical,
      options
    } = this.props
    const { selected } = this.state

    const validate = (value, type) => {
      let answer
      if (typeof options[0] === 'string') {
        answer = value
      } else {
        answer = type === 'id' ? value.id : value.name
      }
      return answer
    }
    return (
      <BaseInputSelection type="radio" size={size}>
        <div className={`form-group row ${vertical && 'vertical'}`}>
          {label && <Label value={label} required={required} />}
          <div className={`col-sm-10 col-xs-12 container-option`}>
            {options.map((input, index) => (
              <div key={validate(input, 'id')} className="radio">
                <label htmlFor={`${name}${index}`}>
                  <input
                    checked={
                      selected
                        ? String(selected) === String(validate(input, 'id'))
                        : false
                    }
                    id={`${name}${index}`}
                    name={name}
                    required={required}
                    onChange={this.handleChange}
                    disabled={disabled}
                    type="radio"
                    value={validate(input, 'id')}
                  />
                  <span className="inputUI" />
                  <p>{validate(input, 'name')}</p>
                </label>
              </div>
            ))}
          </div>
          {info && <Info value={info} label={label} />}
        </div>
      </BaseInputSelection>
    )
  }
}

// @Proptypes
Radio.propTypes = {
  info: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  selected: PropTypes.node,
  vertical: PropTypes.bool
}

// @Export Component
export default Radio
