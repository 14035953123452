import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Avatar } from '~ui'

import { Avatar as Style } from './style'

import { Store } from '~app_store/types'
import { Props } from './types'

/**
 * Componente que muestra el avatar y el tamaño de este
 * @example <Header user={user}/>
 */
export const MyAvatar = ({ user, small }: Props.MyAvatar) => {
  const { t } = useTranslation()
  const { loggedUser } = useSelector((state: Store) => state)

  const itsMe = loggedUser.user!.id === user!.id ? 'itsMe' : ''
  const avatarSize = small ? 'small' : ''

  return (
    <Style.MyAvatar role="AvatarPic" className={`${itsMe} ${avatarSize}`}>
      <Avatar
        fullName={(user && user.fullName) || t('CommentSystemAnonymous')}
        userId={user.id || 0}
        picture={(user && user.picture) || ''}
        size={45}
        fontSize={20}
      />
    </Style.MyAvatar>
  )
}
