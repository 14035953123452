// @External Dependencies
import axios from 'axios'

// @Dependencies
import {
  PersonAgreeTermConditionInput,
  PersonAgreeTermConditionPayload
} from '@eclass/api'
import { apiURL } from '../../../../Config/constants'
import { axiosError } from '../../../Services'

const PersonAgreeTermCondition = (input: PersonAgreeTermConditionInput) => {
  const requestId = 'PersonAgreeTermCondition'
  return async (dispatch: PersonAgreeTermConditionPayload) => {
    const query = `
      mutation PersonAgreeTermCondition($PersonAgreeTermConditionInput: PersonAgreeTermConditionInput!) {
        PersonAgreeTermCondition(input: $PersonAgreeTermConditionInput) {
          status {
            success
            name
            detail
            code
          }
          url
        }
      }
    `

    const variables = {
      PersonAgreeTermConditionInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => response.data.data.PersonAgreeTermCondition)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default PersonAgreeTermCondition
