import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AppFrame } from '~components'

import domainConfig from '../domains'
import { Types } from './types'

/**
 * Rutas privadas.
 *
 * Si el usuario no está antenticado o la ruta no es pública, lo redirecciona al Login.
 * @example <PrivateRoute path={'/path'} component={Component} />
 */
const PrivateRoute = ({
  component: Component,
  path,
  public: isPublic
}: Types.Route) => {
  const isLogged = useSelector((store: Types.Store) => store.loggedUser.id! > 0)

  const render = (props: any) => {
    if (isLogged || isPublic) {
      return (
        <AppFrame config={domainConfig}>
          <Component {...props} />
        </AppFrame>
      )
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location, protected: true }
          }}
        />
      )
    }
  }

  return <Route path={path} render={props => render(props)} exact />
}

export default PrivateRoute
