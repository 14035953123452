import * as React from 'react'

export const RequirementsIcon = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={46}
    height={44}
    role="RequirementsIcon"
    viewBox="0 0 46 44">
    <g fill="none" fillRule="evenodd" transform="translate(1 -1)">
      <g transform="translate(0 .763)">
        <circle fill="#E0EEFA" cx="21.5" cy="21.737" r="21.5" />
      </g>
      <path
        stroke="#60798E"
        strokeLinecap="square"
        strokeWidth="2"
        d="M12.203 37.397L12.203 43.637M30.508 37.397L30.508 43.637"
      />
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.915 43.949L32.797 43.949M33.598 7.339h6.826c.842 0 1.525.683 1.525 1.525v11.804m-8.397 16.417H2.288c-.842 0-1.525-.683-1.525-1.526V8.864c0-.842.683-1.525 1.525-1.525H33.552"
      />
      <path
        fill="#0189FF"
        fillRule="nonzero"
        d="M43.697 28.688h-.57c-.121-.447-.284-.853-.527-1.26l.406-.406c.487-.487.487-1.259 0-1.706-.447-.447-1.26-.447-1.706 0l-.407.406c-.406-.243-.812-.406-1.259-.528v-.568c0-.691-.528-1.22-1.219-1.22-.69 0-1.218.529-1.218 1.22v.568c-.447.122-.854.285-1.26.528l-.406-.406c-.447-.447-1.26-.447-1.706 0-.488.488-.488 1.26 0 1.706l.406.407c-.244.406-.406.812-.528 1.259h-.569c-.69 0-1.219.528-1.219 1.219 0 .69.528 1.219 1.219 1.219h.569c.122.446.284.853.528 1.259l-.406.406c-.488.488-.488 1.26 0 1.706.447.447 1.259.447 1.706 0l.406-.406c.406.244.813.406 1.26.528v.569c0 .69.528 1.219 1.218 1.219s1.219-.528 1.219-1.219v-.569c.447-.122.853-.284 1.26-.528l.406.406c.447.447 1.259.447 1.706 0 .487-.487.487-1.259 0-1.706l-.406-.406c.243-.406.406-.813.528-1.26h.569c.69 0 1.218-.528 1.218-1.218s-.528-1.219-1.218-1.219zm-5.421 3.03c-1.092 0-1.95-.859-1.95-1.95 0-1.093.858-1.95 1.95-1.95s1.95.857 1.95 1.95c0 1.091-.858 1.95-1.95 1.95z"
      />
      <path
        stroke="#60798E"
        strokeWidth="2"
        d="M11 12h21c1.105 0 2 .895 2 2v8c0 1.105-.895 2-2 2H11c-1.105 0-2-.895-2-2v-8c0-1.105.895-2 2-2z"
      />
      <rect width="20" height="2" x="8" y="27" fill="#0189FF" rx="1" />
      <rect width="9" height="2" x="8" y="31" fill="#0189FF" rx="1" />
    </g>
  </svg>
)
