// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} InputType
 * @property {number} studentId - Alumno
 * @property {number} courseId - Curso
 * @property {number} unitId - Unidad
 * @property {number} resourceId - Recurso
 */

/**
 * @typedef {import('@eclass/api').ResourceViewPayload} ResourceViewPayload
 *
 * Obtiene los datos de un Recurso de Aprendizaje.
 *
 * @param {InputType} input - Parámetros para buscar el recurso de aprendizaje.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444
 *    }
 *    const response = await getResource(input)
 * ```
 * @returns {Promise<ResourceViewPayload>}Retorna el Recurso con sus partes (header,config,content, navigation) y la lista de otros recursos de la clase, y la Unidad a la que pertenece.
 */

const getResource = input => {
  const requestId = 'getResource'
  return async dispatch => {
    getToken()
    const query = `
      query getResource($ResourceViewInput: ResourceViewInput!) {
        getResource(input: $ResourceViewInput) {
          status {
            name
            success
            code
            detail
          }
          Resource {
            header {
              title
              source {
                url
                poster
              }
              description
              boxType
            }
            config {
              id
              duration
              download
              comments
              isRated
              License {
                name
                detail
                icon
                LicenseType {
                  name
                }
              }
            }
            content {
              text
              embeds {
                id
                name
                configurations
                ContentModuleType {
                  id
                  name
                }
              }
              footnotes
            }
            navigation {
              prev {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
              next {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
            }
          }
          Menu {
            Links {
              id
              name
              href
              indicators {
                id
                name
                image
                obtained
                max
              }
              status {
                id
                name
                detail
                style
                enabled
              }
              Childrens {
                id
                name
                href
                status {
                  id
                  name
                  detail
                  style
                  enabled
                }
                indicators {
                  id
                  name
                  image
                  obtained
                  max
                }
              }
            }
            Unit {
              id
              name
              title
            }
          }
          Topics {
            colour
            initials
            title
            description
            LearningGoals {
              title
              description
            }
          }
          Evaluations {
            qualification {
              valueFormatted
              status {
                name
                detail
                style
              }
            }
            config {
              id
              testId
              hasTime
              timeRemaining
              state
              showAnswers
              score {
                total
                obtained
              }
              repeat {
                hideButton
                criterial
                total
                attempt
              }
              startDate
              endDate
              completedDate
              daysRemaining
              folio
              completed
              hasRevision
              showFeedback
              canAnswer
              showQualification
            }
            header {
              name
              Type {
                id
                name
              }
            }
            Levels {
              id
              name
              Stages {
                id
                name
                Answers {
                  instructions
                  content
                  answer
                  embeds {
                    id
                    name
                    configurations
                    typeId
                      ContentModuleType {
                        id
                      }
                  }
                  config {
                    id
                    evaluationId
                    levelId
                    stageId
                    mandatory
                    sortOrder
                    ItemType {
                      id
                      name
                    }
                  }
                  feedback {
                    correct
                    score {
                      total
                      obtained
                    }
                    skipped
                    comment
                    text
                    status {
                      id
                      enabled
                      name
                      detail
                      style
                    }
                  }
                }
              }
            }
            navigation {
              prev {
                name
                href
                type {
                  id
                  name
                }
                status {
                  id
                  enabled
                  id
                  detail
                  style
                }
              }
              next {
                name
                href
                type {
                  id
                  name
                }
                status {
                  id
                  enabled
                  id
                  detail
                  style
                }
              }
            }
          }
        }
      }
    `

    const variables = {
      ResourceViewInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10),
        unitId: parseInt(input.unitId, 10),
        resourceId: parseInt(input.resourceId, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.getResource)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getResource
