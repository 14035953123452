const perfectScrollbar = `
  .ps{
    --ps-separation: 3px;
    --ps-width: 7.5px;
  }
  .ps__thumb-x,
  .ps__thumb-y{
    background-color: #222;
    opacity: .5;
    border-radius: var(--ps-width);
    width: var(--ps-width);
  }

  .ps__thumb-x {
    bottom: var(--ps-separation);
  }

  .ps__thumb-y {
    right: var(--ps-separation);
  }

  @media screen and (max-width: 480px) {
    .ps{
      --ps-separation: 5px;
      --ps-width: 6px;
    }
  }

`
export default perfectScrollbar
