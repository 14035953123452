import React from 'react'
import { DropdownItem } from 'reactstrap'
import UIVars from '../variables'
import * as Style from './Style'
import { Icon } from '../../../Components/UI/Icons'
import { Link } from '@eclass/api'

const HeaderDrop = ({
  dropdownType,
  userInfo,
  headerTitle,
  activeNavigation,
  setActive,
  items,
  activeNavId,
  setNavId,
  stars
}: any) => {
  // activo o desactivo la navegación y reseteo su id para mostrar el titulo por defecto
  const navigate = () => {
    setActive(false)
    setNavId(0)
  }

  // obtengo el titulo del header al interior del dropdown según el caso
  const getTitleNav = () => {
    const name = userInfo?.name ? userInfo.name : ''
    const firstSurname = userInfo?.firstSurname ? userInfo.firstSurname : ''
    switch (dropdownType) {
      case 'avatar':
        return `${name} ${firstSurname}`
      case 'navigation':
        if (activeNavId) {
          const active = items.find((item: Link) => item.id === activeNavId)!
          return active && active.Childrens.length > 0 && activeNavId
            ? active.name
            : headerTitle
        } else {
          return headerTitle
        }
      default:
        return headerTitle
    }
  }

  return (
    <Style.HeaderDrop onClick={navigate}>
      <DropdownItem header>
        <div className="title_container">
          <div
            className={`${
              stars && stars.max > 0 ? null : 'no_stars'
            } title_back`}>
            {activeNavigation && dropdownType === 'navigation' && (
              <Icon name="back" fill={UIVars.setColors.white} />
            )}
            <div className={`title`}>{getTitleNav()}</div>
          </div>
          {stars && stars.max > 0 ? (
            <div className="stars">
              <span>
                {stars.obtained}/{stars.max}{' '}
              </span>
              <Icon fill={UIVars.setColors.orangeyYellow} name="stars" />
            </div>
          ) : null}
        </div>
      </DropdownItem>
    </Style.HeaderDrop>
  )
}

export default HeaderDrop
