import { format, differenceInCalendarDays, Locale } from 'date-fns'

/**
 * Retorno la fecha del comentario.
 *
 * Si el comentario es de hoy, retorno la hora
 * @example
 * setDate(date, Locale)
 */
export const setDate = (date: Date, locale: Locale) => {
  let formatDate = 'dd MMM yyyy'
  let abbreviation = ''
  const today = differenceInCalendarDays(new Date(date), new Date())
  if (today === 0) {
    formatDate = 'HH:mm'
    abbreviation = 'hrs'
  }
  return `${format(new Date(date), formatDate, {
    locale
  })} ${abbreviation}`
}
