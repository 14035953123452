import { lazy } from 'react'

import { Types } from './types'

// Componentes lazy
// Rutas privadas
const lazyCourses = lazy(() =>
  import('~views/Courses/' /* webpackChunkName: "courses" */)
)
const lazyCourseClassmates = lazy(() =>
  import('~views/Courses/Classmates' /* webpackChunkName: "classmates" */)
)
const lazyCourseHelpCenter = lazy(() =>
  import('~views/Helper' /* webpackChunkName: "helper" */)
)
const lazyCourseLoginSence = lazy(() =>
  import('~views/LoginSence' /* webpackChunkName: "login-sence" */)
)
const lazyCoursePerformance = lazy(() =>
  import(
    '~views/Courses/Performance/PerformanceView' /* webpackChunkName: "performance-view" */
  )
)
const lazyExerciseDebate = lazy(() =>
  import('~views/Exercise/Debate' /* webpackChunkName: "exercise-debate" */)
)
const lazyExercisePractice = lazy(() =>
  import('~views/Exercise/Practice' /* webpackChunkName: "exercise-practice" */)
)
const lazyHelperQuestionView = lazy(() =>
  import('~views/Helper/Question' /* webpackChunkName: "helper-question" */)
)
const lazyPastCourses = lazy(() =>
  import('~views/Courses/Past' /* webpackChunkName: "past-courses" */)
)
const lazyResourceView = lazy(() =>
  import('~views/Resources' /* webpackChunkName: "resource-view" */)
)
const lazyResumeExercise = lazy(() =>
  import(
    '~views/Exercise/Practice/Resume' /* webpackChunkName: "resume-exercise" */
  )
)
const lazyTermsAndConditions = lazy(() =>
  import('~views/Terms' /* webpackChunkName: "terms-and-conditions" */)
)
const lazyTestsPending = lazy(() =>
  import('~views/Tests/Pending' /* webpackChunkName: "tests-pending" */)
)
const lazyTestsResume = lazy(() =>
  import('~views/Tests/Resume' /* webpackChunkName: "tests-resume" */)
)
const lazyUnitList = lazy(() =>
  import('~views/Units' /* webpackChunkName: "units" */)
)
const lazyRequirements = lazy(() =>
  import('~views/Requirements' /* webpackChunkName: "requirements" */)
)
const lazyTests = lazy(() =>
  import('~views/Tests/Tests' /* webpackChunkName: "tests" */)
)
const lazyUserConfirmation = lazy(() =>
  import(
    '~views/Users/Confirmation' /* webpackChunkName: "user-confirmation" */
  )
)
const lazyProfile = lazy(() =>
  import('~views/Users/Profile/Profile' /* webpackChunkName: "profile" */)
)
const lazyCEVSchedule = lazy(() =>
  import('~views/CEV/Schedule/Schedule' /* webpackChunkName: "cev-schedule" */)
)
const lazyCEVDetail = lazy(() =>
  import('~views/CEV/Detail/Detail' /* webpackChunkName: "cev-detail" */)
)

// Rutas publicas
const lazyUserLogin = lazy(() =>
  import('~views/Users/Login/' /* webpackChunkName: "user-login" */)
)
const lazyUserLogout = lazy(() =>
  import('~views/Users/Login/Logout/' /* webpackChunkName: "user-logout" */)
)
const lazyUserRecoveryPasswd = lazy(() =>
  import(
    '~views/Users/Login/Recovery' /* webpackChunkName: "user-recovery-password" */
  )
)
const lazyUserRecoveryPasswdChange = lazy(() =>
  import(
    '~views/Users/Login/Password' /* webpackChunkName: "user-recovery-password-change" */
  )
)
const lazyPersonalMeeting = lazy(() =>
  import(
    '~views/PersonalMeeting' /* webpackChunkName: "user-recovery-password-change" */
  )
)

const CourseParams = ':programId/:studentId/:courseId'
const ResourceParams = ':programId/:studentId/:courseId/:unitId/:resourceId'

/**
 * Ordena las rutas privadas de la App.
 *
 * Las rutas DEBEN estar ordenadas de la más específica a la menos específica, o el Router creará rutas en blanco.
 */
export const PrivateRoutes: Types.Route[] = [
  { path: '/profile', component: lazyProfile },
  { path: '/courses', component: lazyCourses },
  { path: '/courses-past', component: lazyPastCourses },
  { path: '/courses/:programId/:slug', component: lazyCourses },
  { path: `/units/${CourseParams}`, component: lazyUnitList },
  {
    path: `/login_sence/${CourseParams}/:err`,
    component: lazyCourseLoginSence
  },
  {
    path: `/login_sence/${CourseParams}`,
    component: lazyCourseLoginSence
  },
  {
    path: `/helper/view/${CourseParams}/:questionId`,
    component: lazyHelperQuestionView
  },
  { path: `/helper/${CourseParams}`, component: lazyCourseHelpCenter },
  {
    path: `/performance/${CourseParams}`,
    component: lazyCoursePerformance
  },
  {
    path: `/classmates/${CourseParams}`,
    component: lazyCourseClassmates
  },
  {
    path: `/resource/view/${ResourceParams}`,
    component: lazyResourceView
  },
  {
    path: `/resource/calendar/${ResourceParams}`,
    component: lazyCEVSchedule
  },
  {
    path: `/resource/detail/${ResourceParams}`,
    component: lazyCEVDetail
  },
  {
    path: `/resource/meet/${CourseParams}/:unitId`,
    component: lazyPersonalMeeting
  },
  {
    path: `/test/alert/${ResourceParams}`,
    component: lazyTests
  },
  {
    path: `/test/answer/${ResourceParams}`,
    component: lazyTests
  },
  {
    path: `/test/review/${ResourceParams}/:evaluationId`,
    component: lazyTests
  },
  {
    path: `/test/resume/${ResourceParams}/:evaluationId`,
    component: lazyTestsResume
  },
  {
    path: `/test/pending/${ResourceParams}`,
    component: lazyTestsPending
  },
  {
    path: `/exercise/view/${ResourceParams}`,
    component: lazyExerciseDebate
  },
  {
    path: `/exercise/practice/${ResourceParams}/:answerId`,
    component: lazyExercisePractice
  },
  {
    path: `/exercise/practice/${ResourceParams}`,
    component: lazyExercisePractice
  },
  {
    path: `/exercise/resume/${ResourceParams}`,
    component: lazyResumeExercise
  },
  { path: '/confirmation', component: lazyUserConfirmation },
  { path: '/terms', component: lazyTermsAndConditions },
  { path: '/requirements', component: lazyRequirements, public: true }
]

/**
 * Lista las rutas públicas de la App.
 *
 * Las rutas DEBEN estar ordenadas de la más específica a la menos específica, o el Router creará rutas en blanco.
 *
 * Por ej, si ponemos el path: '/' al inicio, todas las rutas de abajo no se cargarán, porque solo reconocerá esa.
 */
export const PublicRoutes: Types.Route[] = [
  {
    path: '/users/recover_passwd/:token',
    component: lazyUserRecoveryPasswdChange
  },
  {
    path: '/users/recover_passwd',
    component: lazyUserRecoveryPasswd
  },
  {
    path: '/login',
    component: lazyUserLogin
  },
  {
    path: '/logout/inactive',
    component: lazyUserLogout
  },
  {
    path: '/',
    component: lazyUserLogin
  }
]
