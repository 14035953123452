// @Dependencias externas
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import $ from 'jquery'

import { zoom } from '~assets/svg'
import Wrapper from './Style'
import { Props } from '../../types'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import { Store } from '~app_store/types'

// @ts-ignore
window.jQuery = $
require('@fancyapps/fancybox/dist/jquery.fancybox')

/**
 *
 * @example
 *    <ImageZoom config={options} id={63} />
 */
export const ImageZoom = ({ config, id }: Props.ImageZoom) => {
  const [seen, setSeen] = useState(false)
  const { t } = useTranslation()
  const {
    ui: { isMobile }
  } = useSelector((state: Store) => state)

  /**
   * Scripts jQuery
   */
  useEffect(() => {
    if ($('.Article__image_zoom > a').length > 0) {
      // @ts-ignore
      $('.Article__image_zoom > a').fancybox({
        touch: false,
        keyboard: false,
        infobar: false,
        arrows: false,
        toolbar: true,
        buttons: ['close'],
        clickContent: (current: any) =>
          current.type === 'image' ? false : 'close',
        mobile: {
          clickContent: (current: any) =>
            current.type === 'image' ? false : 'close',
          clickSlide: () => 'close'
        },
        animationDuration: 0,
        beforeShow: () => $('body').css('overflowY', 'hidden'),
        afterShow: (instance: any) => {
          instance.scaleToActual()
          if (!seen && window.innerWidth > 768) {
            setSeen(true)
          }
        },
        afterClose: () => $('body').css('overflowY', 'scroll')
      })
    }
  })

  return (
    <Wrapper
      className={`Article__image_zoom ${seen ? 'seen' : ''} ${
        isMobile ? 'isMobile' : ''
      }`}
      role="ImageZoom"
      key={id}>
      <a data-fancybox="" href={`https://cdn.eclass.com/${config.image}`}>
        <div
          className={`Article__image_zoom__btn flex ${
            isMobile ? '' : 'isDesktop'
          }`}>
          <div className="Article__image_zoom__message">
            <span>{t('ResourceClickToZoom')}</span>
          </div>
          <img className="zoom_icon" src={zoom} alt="zoom_icon" />
        </div>
        <img
          src={`https://cdn.eclass.com/${config.image}`}
          className="main_image"
          alt={`https://cdn.eclass.com/${config.image}`}
        />
      </a>
    </Wrapper>
  )
}
