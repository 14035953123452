import * as React from 'react'

export const TermsIcon = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={44}
    height={43}
    role="TermsIcon"
    viewBox="0 0 44 43">
    <g fill="none" fillRule="evenodd">
      <circle fill="#E0EEFA" cx="21.5" cy="21.5" r="21.5" />
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25 39c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2V16c0-1.105.895-2 2-2"
      />
      <g transform="translate(10 1)">
        <path
          stroke="#60798E"
          strokeLinecap="round"
          strokeWidth="2"
          d="M12.923 33H2.286C1.023 33 0 31.972 0 30.704V2.296C0 1.028 1.023 0 2.286 0h20.428C23.977 0 25 1.028 25 2.296v11.492M8.25 9L19 9"
        />
        <circle cx="5" cy="9" r="1" fill="#0189FF" />
        <circle cx="5" cy="14" r="1" fill="#0189FF" />
        <circle cx="5" cy="19" r="1" fill="#0189FF" />
        <circle cx="5" cy="24" r="1" fill="#0189FF" />
        <path
          stroke="#60798E"
          strokeLinecap="round"
          strokeWidth="2"
          d="M8.25 14L19 14M8.25 19L19 19M8.25 24L15 24"
        />
      </g>
      <g fill="#0189FF" fillRule="nonzero" stroke="#0189FF">
        <path
          d="M12.7 11.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S0 3.2 0 7.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S4.3 2 7.1 2s5.1 2.3 5.1 5.1-2.3 5-5.1 5z"
          transform="translate(27 25)"
        />
      </g>
    </g>
  </svg>
)
