import React from 'react'
import { Props } from '../types'

/** Componente que dibuja el video en los recursos */
export const ArticleVideo = ({ config, id }: Props.Video) => {
  if (!config) {
    return null
  }
  return (
    <section className="Article__video v8 eclass-elements-kit-video">
      <video
        key={id}
        controls
        poster={config.cover ? `https://cdn.eclass.com/${config.cover}` : ''}
        data-testid="article-video">
        <source src={`https://cdn.eclass.com/${config.video}`} />
      </video>
    </section>
  )
}
