import React, { memo } from 'react'
import toast from 'toasted-notes'
import ReactHtmlParser from 'react-html-parser'

import { SideFlash } from './'
import { AlertNotification as Props } from './helpers/types'

const VISIBLE = 6000
const EDGE_DISTANCE = 30
const DEFAULT_POSITION = 'top'
/**
 * Componente que muestra una alerta tipo notificación,
 * posee diferentes estados
 *
 * @example
 * <AlertNotification
 *  message="Error al ingresar"
 *  show={true}
 * />
 */
export const AlertNotification = memo(
  ({
    closed,
    duration = VISIBLE,
    margin = EDGE_DISTANCE,
    message,
    position = DEFAULT_POSITION,
    show,
    type,
    toasted,
    queueIt = false
  }: Props) => {
    if (!show || (document.getElementById('alert') && !queueIt)) {
      return null
    }

    return (
      <>
        {toast.notify(
          ({ onClose }) => (
            <SideFlash
              type={type}
              margin={margin}
              onClose={onClose}
              toasted={toasted}>
              <span>{ReactHtmlParser(message)}</span>
            </SideFlash>
          ),
          {
            position,
            duration
          }
        )}
      </>
    )
  }
)
