import React from 'react'
import { Link } from 'react-router-dom'

import { UIVars } from '~ui'
import { Icon } from '~ui/Icons'

import { BackButtonProps } from './types'
import { Wrapper } from './Style'

export const BackButton = ({ href, title }: BackButtonProps) => {
  return (
    <Wrapper>
      <div className="Content">
        <Link to={href}>
          <Icon
            name="goback-courses"
            fill={UIVars.setColors.completed}
            width={8}
            height={12}
          />
          <span>{title}</span>
        </Link>
      </div>
    </Wrapper>
  )
}

export default BackButton
