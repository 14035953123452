import styled from 'styled-components'
import { UIVars } from '~ui'

/**
 * Estilos CSS
 */
export default styled.section`
  margin: 50px -140px 0;
  overflow: hidden;
  position: relative;

  &.isMobile {
    margin: 0;
  }

  a {
    display: block;
    position: relative;

    &:hover {
      cursor: zoom-in;
    }

    .Article__image_zoom__btn {
      background: rgba(0, 0, 0, 0.6);
      border-radius: ${UIVars.borderRadius} 0 0 0;
      bottom: 0;
      color: white;
      display: flex;
      font-size: 12px;
      padding: 6px 10px;
      position: absolute;
      right: 0;
      z-index: 10;

      img.zoom_icon {
        width: 18px;
      }

      &.isDesktop {
        display: none;
      }
    }

    .Article__image_zoom__message {
      overflow: hidden;

      span {
        display: block;
        margin-right: 10px;
        transition: margin 0.25s;
        white-space: nowrap;
      }
    }
  }

  &.seen {
    .Article__image_zoom__message span {
      margin-right: -130px;
    }

    &:hover {
      .Article__image_zoom__message span {
        margin-right: 10px;
      }
    }
  }

  img.main_image {
    width: 100%;
    background-color: #fff;
  }

  @media screen and (max-width: 768px) {
    a {
      .Article__image_zoom__btn {
        border-radius: 10px;
        bottom: unset;
        left: 50%;
        margin: -15px 0 0 -87.5px;
        right: unset;
        top: 50%;
      }
    }
  }
`
