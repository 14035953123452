// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Dependencies
import BaseForm from './BaseForm'

// @Component
class Textarea extends Component {
  handleChange = e => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(e)
    }
  }

  render () {
    const {
      info,
      inputSize,
      label,
      name,
      placeholder,
      required,
      rows,
      tooltip,
      value,
      onKeyDown,
      clearInvalidChars
    } = this.props

    return (
      <StyledTextArea>
        <BaseForm
          info={info}
          inputSize={inputSize}
          label={label}
          required={required}
          name={name}
          tooltip={tooltip}>
          <textarea
            className={`form-control span-${inputSize || 'md'}`}
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={this.handleChange}
            required={required}
            rows={rows}
            defaultValue={value}
            onKeyDown={onKeyDown}
            onKeyUp={clearInvalidChars}
          />
        </BaseForm>
      </StyledTextArea>
    )
  }
}

// @Proptypes
Textarea.propTypes = {
  info: PropTypes.node,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  tooltip: PropTypes.string,
  value: PropTypes.string
}

Textarea.defaultProps = {
  rows: 3
}

// @Export Component
export default Textarea

// @Styles
const StyledTextArea = styled.div`
  .form-group {
    align-items: flex-start;
  }
  .label-defect {
    margin-top: 1rem;
  }
  textarea {
    min-height: 160px;
    resize: vertical;
  }
  .icon {
    top: 1.1429rem;
    transform: translateY(0);
  }
`
