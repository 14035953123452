// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'
import { QuestionCreateInput } from '@eclass/api'

/**
 * Agrega una pregunta al ayudante
 * @returns {Promise<Payload>}
 */
const QuestionCreate = (input: QuestionCreateInput) => {
  const requestId = 'QuestionCreate'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation QuestionCreate($QuestionCreateInput: QuestionCreateInput!) {
        QuestionCreate(input: $QuestionCreateInput) {
          status {
            success
            code
            name
            detail
          }
          url
          questionAnswer{
            id
            text
            created
            Childrens{
              id
              text
              created
              Student {
                Person {
                  fullName
                  picture
                  id
                }
              }
            }
            Student {
              profileId
                Person {
                  fullName
                  picture
                  id
                }
              }
            parentId
          }
        }
      }
    `
    const variables = {
      QuestionCreateInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        // const payload: QuestionCreatePayload = response.data.data.QuestionCreate
        const payload = response.data.data.QuestionCreate
        return payload
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default QuestionCreate
