// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'
import { QuestionDeleteInput, QuestionDeletePayload } from '@eclass/api'

/**
 * Agrega una pregunta al ayudante
 * @returns {Promise<QuestionDeletePayload>}
 */

const QuestionDelete = (input: QuestionDeleteInput) => {
  const requestId = 'QuestionDelete'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation QuestionDelete($QuestionDeleteInput: QuestionDeleteInput!) {
        QuestionDelete(input: $QuestionDeleteInput) {
          status {
            success
            code
            name
            detail
          }
          url
        }
      }
    `
    const variables = {
      QuestionDeleteInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const payload: QuestionDeletePayload = response.data.data.QuestionDelete
        return payload
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default QuestionDelete
