import React from 'react'

export const CevLoading = () => (
  <svg
    role="IconCevLoading"
    width="45"
    height="46"
    viewBox="0 0 45 46"
    id="ezg025xn7g0a1">
    <style
      dangerouslySetInnerHTML={{
        __html:
          '#ezg025xn7g0a2 { animation: ezg025xn7g0a2_c_o 2000ms linear infinite normal forwards } @keyframes ezg025xn7g0a2_c_o{  0% { opacity: 1 } 25% {opacity: 0.300000} 50% {opacity: 1} 75% {opacity: 0.300000} 100% {opacity: 1} } #ezg025xn7g0a9_tr { animation: ezg025xn7g0a9_tr__tr 2000ms linear infinite normal forwards }  @keyframes ezg025xn7g0a9_tr__tr { 0% { transform: translate(35.000011px, 36.000003px) rotate(90deg) } 100% {transform: translate(35.000011px,36.000003px) rotate(450deg)}}'
      }}
    />
    <g id="ezg025xn7g0a2">
      <path
        id="ezg025xn7g0a3"
        d="M0,40C0,40.530400,0.210714,41.039100,0.585786,41.414200C0.960859,41.789300,1.469570,42,2,42L41,42C41.795600,42,42.558700,41.683900,43.121300,41.121300C43.683900,40.558700,44,39.795600,44,39L44,14L0,14L0,40Z"
        fill="rgb(230,230,230)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="ezg025xn7g0a4"
        d="M42,4L2,4C1.469570,4,0.960859,4.210710,0.585786,4.585790C0.210714,4.960860,0,5.469570,0,6L0,14L44,14L44,6C44,5.469570,43.789300,4.960860,43.414200,4.585790C43.039100,4.210710,42.530400,4,42,4L42,4Z"
        fill="rgb(1,137,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="ezg025xn7g0a5"
        d="M32,9L32,1C32,0.734784,32.105400,0.480430,32.292900,0.292893C32.480400,0.105357,32.734800,0,33,0L35,0C35.265200,0,35.519600,0.105357,35.707100,0.292893C35.894600,0.480430,36,0.734784,36,1L36,9C36,9.265220,35.894600,9.519570,35.707100,9.707110C35.519600,9.894640,35.265200,10,35,10L33,10C32.734800,10,32.480400,9.894640,32.292900,9.707110C32.105400,9.519570,32,9.265220,32,9Z"
        fill="rgb(216,216,216)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="ezg025xn7g0a6"
        d="M8,9L8,1C8,0.734784,8.105360,0.480430,8.292890,0.292893C8.480430,0.105357,8.734780,0,9,0L11,0C11.265200,0,11.519600,0.105357,11.707100,0.292893C11.894600,0.480430,12,0.734784,12,1L12,9C12,9.265220,11.894600,9.519570,11.707100,9.707110C11.519600,9.894640,11.265200,10,11,10L9,10C8.734780,10,8.480430,9.894640,8.292890,9.707110C8.105360,9.519570,8,9.265220,8,9Z"
        fill="rgb(216,216,216)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <path
      id="ezg025xn7g0a7"
      d="M35,46C36.977800,46,38.911200,45.413500,40.555700,44.314700C42.200200,43.215900,43.481900,41.654100,44.238800,39.826800C44.995700,37.999600,45.193700,35.988900,44.807900,34.049100C44.422000,32.109300,43.469600,30.327500,42.071100,28.928900C40.672500,27.530400,38.890700,26.578000,36.950900,26.192200C35.011100,25.806300,33.000400,26.004300,31.173200,26.761200C29.345900,27.518100,27.784100,28.799800,26.685300,30.444300C25.586500,32.088800,25,34.022200,25,36C25,38.652200,26.053600,41.195700,27.928900,43.071100C29.804300,44.946400,32.347800,46,35,46Z"
      fill="rgb(96,121,142)"
      stroke="none"
      strokeWidth="1"
    />
    <rect
      id="ezg025xn7g0a8"
      width="2"
      height="7"
      rx="1"
      ry="1"
      transform="matrix(1 0 0 1 34 30)"
      fill="rgb(255,255,255)"
      stroke="none"
      strokeWidth="1"
    />
    <g
      id="ezg025xn7g0a9_tr"
      transform="translate(35.000011,36.000003) rotate(90)">
      <rect
        id="ezg025xn7g0a9"
        width="2"
        height="7"
        rx="1"
        ry="1"
        transform="translate(-1,-5.999989)"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
  </svg>
)
