// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { getToken, axiosError } from '../../../Services'

/**
 * @typedef {Object} FilterType
 * @property {number} programId - Programa
 * @property {number} studentId - Alumno Curso
 * @property {number} courseId - Curso
 * @property {number} unitId - Unidad
 * @property {number} resourceId - Recurso
 */

/**
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * @param {FilterType} input - Curabitur maximus tellus nec
 * @example
 * ```js
 *  const params = { programId, studentId, courseId, unitId, resourceId }
 *  const response = await EvaluationCreate(params)
 * ```
 * @returns {Promise<import('@eclass/api').EvaluationCreatePayload} Retorna el objeto de una evaluación nueva o alguna que ya exista.
 */
const EvaluationCreate = input => {
  const requestId = 'EvaluationCreate'
  return async dispatch => {
    getToken()

    const query = `
      mutation EvaluationCreate($EvaluationCreateInput: EvaluationCreateInput!) {
        EvaluationCreate(input: $EvaluationCreateInput) {
          status {
            success
            name
            detail
            code
          }
          Alerts {
            icon
            message
          }
          Evaluation {
            qualification {
              valueFormatted
              status {
                name
                detail
                style
              }
            }
            config {
              id
              hasTime
              timeRemaining
              state
              showAnswers
              score {
                total
                obtained
              }
              repeat {
                hideButton
                criterial
                total
                attempt
              }
              startDate
              endDate
              completedDate
              daysRemaining
              folio
              completed
              hasRevision
              showFeedback
              canAnswer
              showQualification
              Feedback {
                id
                text
                embeds {
                  id
                  name
                  configurations
                  typeId
                  ContentModuleType {
                    id
                  }
                }
              }

            }
            header {
              name
              description
              Type {
                id
                name
              }
            }
            Levels {
              id
              name
              description
              Stages {
                id
                name
                description
                Answers {
                  instructions
                  content
                  answer
                  embeds {
                    id
                    name
                    configurations
                    typeId
                      ContentModuleType {
                        id
                      }
                  }
                  state
                  config {
                    id
                    evaluationId
                    levelId
                    stageId
                    mandatory
                    sortOrder
                    canSkip
                    skipText
                    ItemType {
                      id
                      name
                    }
                  }
                  feedback {
                    correct
                    score {
                      total
                      obtained
                    }
                    skipped
                    comment
                    text
                    comment
                    status {
                      id
                      enabled
                      name
                      detail
                      style
                    }
                  }
                }
              }
            }
            Menu{
              Navigation {
                prev {
                  name
                  href
                  type {
                    id
                    name
                  }
                  status {
                    id
                    enabled
                    id
                    detail
                    style
                  }
                }
                next {
                  name
                  href
                  type {
                    id
                    name
                  }
                  status {
                    id
                    enabled
                    id
                    detail
                    style
                  }
                }
              }
            }
            embeds {
              id
              name
              configurations
              typeId
              ContentModuleType {
                id
              }
            }
          }
        }
      }
    `
    const variables = {
      EvaluationCreateInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.EvaluationCreate)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default EvaluationCreate
