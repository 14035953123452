// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError } from '../../../Services'
import { PersonPersonalInformationInput } from '@eclass/api'

const PersonPersonalInformation = (input: PersonPersonalInformationInput) => {
  const requestId = 'PersonPersonalInformation'
  return async (dispatch: PersonPersonalInformationInput) => {
    const query = `
      mutation PersonPersonalInformation($PersonPersonalInformationInput: PersonPersonalInformationInput!) {
        PersonPersonalInformation(input: $PersonPersonalInformationInput) {
          status {
            success
            name
            detail
            code
          }
        }
      }
    `

    const variables = {
      PersonPersonalInformationInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => response.data.data.PersonPersonalInformation)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default PersonPersonalInformation
