import { useState, useEffect, useCallback } from 'react'

const INTERVAL = 500

/**
 * Retorno si la posicion es bottom
 * @example
 *  const fixed = usePosition({match})
 */
function usePosition ({ match }: any) {
  const [bottom, setBottom] = useState(false)
  const [loading, setLoading] = useState(true)

  /**
   * comparo tamaño del contenedor con el del viewport
   */
  const handleState = useCallback(() => {
    const viewport = window.innerHeight
    const updatedContainer = document.getElementById('MainContainer')
    const updatedContainerHeight = updatedContainer
      ? updatedContainer.clientHeight
      : 0
    setLoading(false)
    const footer = document.getElementById('Footer')
    const footerHeight = !bottom && footer ? footer.clientHeight : 0
    /**
     * @todo Se hace el re render infinitas veces
     */
    setBottom(viewport > updatedContainerHeight + footerHeight)
  }, [bottom])

  useEffect(() => {
    window.addEventListener('resize', handleState)
    window.addEventListener('scroll', handleState)
    return () => {
      window.removeEventListener('resize', handleState)
      window.removeEventListener('scroll', handleState)
    }
  }, [handleState])

  const getLoading = useCallback(() => {
    const loadingContainer = document.getElementsByClassName('LoadingSVG')
    return loadingContainer && loadingContainer.length
  }, [])

  /**
   * render inicial
   * cuando se va el loading, voy a la funcion handleState
   */
  useEffect(() => {
    setBottom(false)
    setLoading(true)
    const loadingComponent = setInterval(() => {
      if (getLoading() === 0) {
        handleState()
        clearInterval(loadingComponent)
      }
    }, INTERVAL)
    return () => {
      clearInterval(loadingComponent)
    }
  }, [match, getLoading, setBottom, handleState])

  return { bottom, loading }
}

export default usePosition
