import React from 'react'
import { Props } from '../types'

/**
 * @example <SliderImages slide={slide} />
 */
export const SliderImages = ({ slide }: Props.SliderImage) => {
  return (
    <div className="carousel_item">
      <figure className="img_container">
        <img src={`//cdn.eclass.com/${slide.image}`} alt={slide.title} />
      </figure>
    </div>
  )
}
