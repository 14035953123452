import { Types } from '../types'
type props = {
  stages: Types.stageFull[]
  id: number
  hasLevelsStages: boolean
  index: number
  stageIndex: number
}
/**
 * Devuelvo número de la pregunta con respecto a las preguntas totales del nivel
 * @param stages {}
 * @param id {number}
 * @param hasLevelsStages {boolean}
 * @param index {number}
 * @param stageIndex {number}
 */
export const numberOfQuestion = ({
  stages,
  id,
  hasLevelsStages,
  index,
  stageIndex
}: props) => {
  let position = index + 1
  if (hasLevelsStages) {
    let flag = 0
    const stageShort = [...stages].splice(0, stageIndex + 1)
    stageShort.forEach((stage: Types.stageFull, key: number) => {
      if (key !== stageIndex) {
        flag += Array.from(stage?.Answers!).length
      } else {
        stage.Answers?.forEach((answer: Types.answerFull) => {
          flag++
          if (answer?.config?.id === id) {
            position = flag
          }
        })
      }
    })
  }
  return position
}
