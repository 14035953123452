import styled from 'styled-components'

import { UIVars } from '~ui'
import { blueQuote } from '~assets/svg'

/**
 * Estilos CSS
 */
export default styled.section`
  margin: 50px 55px 0;
  p {
    color: ${UIVars.setColors.brownishGrey};
    font: 20px / 33px 'Lora';
    margin-bottom: 30px;
  }

  .Article__quote__container {
    align-items: flex-start;
    display: flex;
    &::before {
      content: url(${blueQuote});
      flex-shrink: 0;
      height: 23px;
      margin-top: 8px;
      margin-right: 10px;
      width: 30px;
    }
  }
  footer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    p {
      margin: 0;
      padding: 0px;
      font-size: 12px;
      color: ${UIVars.setColors.blackTwo};
      display: block;
      &-:nth-child(2) {
        color: ${UIVars.setColors.brownishGrey};
      }
      &::before {
        content: none;
      }
    }
    figure {
      margin: 0px;
      img {
        border-radius: 50%;
        width: 52px;
        margin: 0px 10px;
      }
    }

    div {
      text-align: left;
      p {
        font: 12px / normal 'Roboto';
        margin-bottom: 5px;
        &:first-child::before {
          content: none;
        }
        &:last-child {
          margin-bottom: 0;
          color: ${UIVars.setColors.brownishGrey};
        }
        &.underline {
          color: ${UIVars.setColors.primary};
          font-size: 12px;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 30px 0 0;
    p {
      font: 18px / 30px 'Lora';
      margin-bottom: 30px;
    }
  }
`
