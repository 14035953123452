import { UserReducerAction, UserReducer, PersonAttributesExtra } from './types'

/**
 * Guarda los datos del usuario una vez logueado.
 *
 * @retuns Objeto con los datos del usuario
 */
export const loggedUser = (
  state = {},
  action: UserReducerAction
): UserReducer => {
  switch (action.type) {
    case 'LOGIN':
    case 'LOGIN_EXTERNAL':
      return {
        ...action.payload
      }
    case 'GET_USER':
      return {
        ...state,
        ...action.payload
      }
    case 'LOGOUT':
      return {}
    case 'UPDATE_USER':
      return {
        ...state,
        ...dispatchSetData(state, action.payload)
      }
    case 'MORE_USER_DATA':
      return {
        ...state,
        ...dispatchSetData(state, action.payload)
      }
    default:
      return state
  }
}

/** Seteo datos manteniendo los demas de la store */
const dispatchSetData = (
  state: UserReducer,
  payload: PersonAttributesExtra
) => {
  return {
    user: {
      ...state.user,
      ...payload
    }
  }
}
