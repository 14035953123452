import React, { useState, useEffect } from 'react'
import { Progress } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useInterval from './useInterval'
import Wrapper from './Style'
import { Types } from '../types'

const TO_SECOND = 60
const INTERVAL_SECOND = 1000
const COMPLETE_PERCENTAGE = 100
const AVERAGE_PERCENTAGE = 50
const LAST_PERCENTAGE = 8

/**
 * Maneja el tiempo restante de una evaluación, cuando se recarga trae el tiempo restante de la api.
 *
 * @example <TimeProgress config={config} testActions={testActions} />
 */
const TimeProgress = ({ config, testActions }: Types.TimeProgressProps) => {
  const { t } = useTranslation()
  const { isMobile } = useSelector((store: Types.Store) => store.ui)

  const [percentage, setPercentage] = useState(0)
  const [time, setTime] = useState(0)

  /**
   * Asigno valores iniciales solo si no esta completo el tiempo
   */
  useEffect(() => {
    if (!config.completed) {
      // Redondeo valores y paso de minutos a segundos
      const initialPercentage = Math.round(config.timeRemaining)
      const initialTime = Math.round(config.hasTime * TO_SECOND)
      setPercentage(initialPercentage)
      // obtengo el valor en tiempo de acuerdo a su porcentaje
      setTime(
        Math.round((initialTime * initialPercentage) / COMPLETE_PERCENTAGE)
      )
    }
  }, [config])

  useEffect(() => {
    if (time < 0) {
      testActions({ type: 'FINALIZE' })
    }
  }, [time, testActions])

  /**
   * Modifico estados cada un segundo
   */
  useInterval(() => {
    const newTime = time - 1
    const percentageRemaining =
      (newTime * COMPLETE_PERCENTAGE) / (Math.round(config.hasTime) * TO_SECOND)
    setTime(newTime)
    // obtengo el porcentaje en relación a los minutos que lleva
    setPercentage(percentageRemaining)
  }, INTERVAL_SECOND)

  if (!config.hasTime || config.completed) {
    return null
  }

  /**
   * Textos de la cuenta regresiva
   */
  let timeRemaining = time
  let typeOfTime = t('Sec')
  if (time >= TO_SECOND) {
    timeRemaining = Math.round(time / TO_SECOND)
    typeOfTime = 'min'
  }

  return (
    <Wrapper id="Test__timer">
      <>
        {config.hasTime > 0 ? (
          <div
            className={`Timer ${
              percentage <= AVERAGE_PERCENTAGE ? 'yellow' : ''
            } ${percentage <= LAST_PERCENTAGE ? 'red' : ''}`}>
            <span className="Capital">
              {isMobile ? '' : t('ResourceTimeLeft')}
            </span>
            <Progress value={percentage} />
            <span className="Text__Time">
              {timeRemaining} {typeOfTime}
            </span>
          </div>
        ) : null}
      </>
    </Wrapper>
  )
}

export default TimeProgress
