// @Dependencies
import axios from 'axios'
import { store } from '../../Redux'
import jwtDecode from 'jwt-decode'
import { LoginToken } from './types'
/**
 * Devuelve el token con el que está logueado el usuario y lo setea en las cabeceras de Axios
 *
 * Además valida que el token no esté expirado
 * @example
 *   import { getToken } from '../../../Services'
 *   const decoded = getToken()
 */
export const getToken = (): LoginToken | null => {
  const token = store.getState().loggedUser.token
  const href = window.location.href
  let expired = false
  if (token) {
    const tokenDecoded: LoginToken = jwtDecode(token)
    const currentTime = new Date().getTime() / 1000
    if (currentTime < tokenDecoded.exp) {
      axios.defaults.headers.common.Authorization = `bearer ${token}`
      axios.defaults.headers['app-referer'] = href
      return tokenDecoded
    } else {
      expired = true
    }
  } else {
    expired = true
  }

  if (expired) {
    window.sessionStorage.setItem('session', 'expired')
    window.sessionStorage.setItem('lastURL', window.location.pathname)
    if (window.localStorage) {
      window.localStorage.removeItem('persist:platform')
    }
    window.location.href = '/logout/inactive'
  }
  return null
}

export default getToken
