// @External Dependencies
import axios from 'axios'
// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'
/**
 * @TODO JAVIER:
 * Completar lo que hace este action, que es basicamente descargar el HTML del certificado,
 * completar con ejemplos también.
 *
 *
 * Lista los cursos de una persona según los filtros
 *
 * Ocupamos esta query para Dashboard de Cursos y Registro Académico
 *
 * @param {InputType} input
 * @example
 * ```js
 *    const input = {
 *      type: 'PAST'
 *    }
 *    const students = await this.props.getCourseList(input)
 * ```
 * @returns {Promise<import('@eclass/api').CourseListPayload>}
 */
const getCertificate = (input: any) => {
  const requestId = 'getDownloadCertificate'
  return async dispatch => {
    getToken()
    const query = `
      query getDownloadCertificate($input: DownloadCertificateInput!) {
        getDownloadCertificate(input: $input) {
          status {
            success
            detail
          }
          html
          certificateId
          pdfConfig {
            file_name
            landscape
            margin_top
            margin_bottom
            margin_left
            margin_right
            scale
            header_template
            footer_template
            header_spacing
            footer_spacing
            page_size
          }
        }
      }
    `
    const variables = {
      input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.getDownloadCertificate
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getCertificate
