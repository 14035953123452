// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { getToken, axiosError } from '../../../Services'

/**
 * Busca todos los términos y condiciones de un programa
 * @example
 * ```js
 *    const response = await getTermsConditions()
 * ```
 * @returns {Promise<import('@eclass/api').ProgramListPayload>} Retorna una Lista con los textos de los términos y condiciones.
 */
const getTermsConditions = () => {
  const requestId = 'getTermsConditions'
  return async dispatch => {
    getToken()

    const query = `
      query getProgramList {
        getProgramList {
          status {
            success
            detail
            name
            code
          }
          Programs {
            id
          }
          Terms {
            id
            text
            programId
            Program {
              name
            }
          }
        }
      }

    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const { getProgramList } = response.data.data
        return getProgramList
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getTermsConditions
