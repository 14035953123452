// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

// @Dependencies
import { path } from './../../../assets/svg'
import variables from './variables'

// @Component Paginator
export default function Paginator ({
  pageCount,
  onPageChange,
  initialPage = 0,
  disableInitialCallback
}) {
  return (
    <Wrapper>
      <ReactPaginate
        initialPage={initialPage}
        disableInitialCallback={disableInitialCallback}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={<img src={path} alt="arrow-previuos" />}
        nextLabel={<img src={path} alt="arrow-next" />}
        onPageChange={onPageChange}
        containerClassName="Paginate"
        previousClassName="arrow Paginate_Previous"
        nextClassName="arrow Paginate_Next"
        activeClassName="Page_Active"
        pageClassName="Pages"
      />
    </Wrapper>
  )
}

Paginator.propTypes = {
  pageCount: PropTypes.number.isRequired,
  initialPage: PropTypes.number,
  disableInitialCallback: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired
}

const Wrapper = styled.div`
  .Paginate {
    list-style: none;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: baseline;
    .arrow {
      a {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${variables.setColors.deepSkyBlue};
        &:focus {
          outline: none;
        }
      }
    }
    .break {
      margin: 0 6px;
    }
    .Pages {
      cursor: pointer;
      width: 20px;
      text-align: center;
      margin: 0 1px;
      font-size: 14px;
      line-height: 18px;
      color: ${variables.setColors.black};
      margin-bottom: 4px;
    }
    .Page_Active {
      a {
        color: ${variables.setColors.deepSkyBlue};
      }
      border-bottom: 1px solid ${variables.setColors.deepSkyBlue};
    }
    .Paginate_Previous {
      cursor: pointer;
      margin-right: 10px;
      img {
        transform: rotate(180deg);
      }
    }
    .Paginate_Next {
      cursor: pointer;
      margin-left: 10px;
    }
  }
`
