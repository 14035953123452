import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from '~ui'
import { Wrapper } from './Style'
import { iObjectivesModal } from './types'
import { LearningTopicAttributes } from '@eclass/sequelize-models'

export const ObjectivesModal = ({
  isOpen,
  toggleModal,
  topics,
  topic
}: iObjectivesModal) => {
  const { t } = useTranslation()
  return (
    <Modal
      type="full"
      isOpen={isOpen}
      toggle={toggleModal}
      closeModal={toggleModal}
      iconHeader="objectives"
      header={t('ResourceObjective')}>
      {topics && topics.length > 0
        ? topics.map((topicAttr, index: number) => (
            <ModalContent topic={topicAttr} key={index} />
          ))
        : topic && <ModalContent topic={topic} />}
    </Modal>
  )
}

export const ModalContent = ({ topic }: { topic: LearningTopicAttributes }) => {
  if (!topic) {
    return null
  }
  return (
    <Wrapper color={topic.colour} className="Topic">
      <div className="Topic__Title__Box">
        <div className="Topic__Initials">{topic.initials}</div>
        <div className="Topic__Title">{topic.title}</div>
      </div>
      <div className="Topic__Description">{topic.description}</div>
      {Array.from(topic.LearningGoals!).map((goal, index) => (
        <ul className="LearningGoals" key={index}>
          <li>{goal.title}</li>
        </ul>
      ))}
    </Wrapper>
  )
}

export default ObjectivesModal
