import React, { useState, useCallback } from 'react'
import { DropdownItem } from 'reactstrap'
import { Pressed, DelayLink } from '~ui'
import { CoursesIcon, AcademicHistorialIcon, ProfileIcon } from '~ui/Icons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { disconnect } from '~actions'
import * as ItemStyle from './ItemStyle'

const MenuItemAvatar = () => {
  const { t } = useTranslation()
  const [delay] = useState(50)
  const dispatch = useDispatch()

  const onDisconect = useCallback(async () => {
    await dispatch<any>(disconnect())
  }, [dispatch])

  const onLogout = () => {
    setTimeout(() => {
      onDisconect()
      if (window.localStorage) {
        setTimeout(() => {
          window.localStorage.removeItem('persist:platform')
          window.location.href = '/'
        }, 1000)
      }
    }, delay)
  }

  return (
    <>
      <DropdownItem>
        <Pressed dark>
          <DelayLink to="/courses">
            <ItemStyle.ItemName>
              <CoursesIcon />
              {t('CrossMyActiveCourses')}
            </ItemStyle.ItemName>
          </DelayLink>
        </Pressed>
      </DropdownItem>
      <DropdownItem>
        <Pressed dark>
          <DelayLink to="/courses-past">
            <ItemStyle.ItemName>
              <AcademicHistorialIcon />
              {t('AcademicHistory')}
            </ItemStyle.ItemName>
          </DelayLink>
        </Pressed>
      </DropdownItem>
      <DropdownItem>
        <Pressed dark>
          <DelayLink to="/profile">
            <ItemStyle.ItemName>
              <ProfileIcon />
              {t('CrossMyProfile')}
            </ItemStyle.ItemName>
          </DelayLink>
        </Pressed>
      </DropdownItem>
      <ItemStyle.AvatarLogout>
        <DropdownItem className="logout" onClick={onLogout}>
          <Pressed dark>
            <ItemStyle.Logout>
              <span>{t('CrossEndSession')}</span>
            </ItemStyle.Logout>
          </Pressed>
        </DropdownItem>
      </ItemStyle.AvatarLogout>
    </>
  )
}

export default MenuItemAvatar
