import React from 'react'

// @Dependencies
import { Button } from '~ui'
import { Icon } from '~ui/Icons'
import { WrapperDocs } from './Style'

type Docs = {
  name: string
  href: string
}

type Props = {
  docs: Docs[]
}

export const DownloadFiles = ({ docs }: Props) => {
  return (
    <WrapperDocs className="DownloadFiles">
      {docs.map(({ href, name }, key: number) => {
        const documentExtension = href.split('.')[href.split('.').length - 1]

        return (
          <li key={key} className="File flex">
            <div>
              <strong>{`${name}`}</strong>
              <span>{`(${documentExtension})`}</span>
            </div>
            <Button link={href} blank>
              <Icon name="download" width={14} height={16} fill="white" />{' '}
            </Button>
          </li>
        )
      })}
    </WrapperDocs>
  )
}
