/* Importamos las imagenes por defecto */
import bgDefaultCoursesDay from './bgDefaultCoursesDay.jpg'
import bgDefaultCoursesTabletDay from './bgDefaultCoursesTabletDay.jpg'
import bgDefaultCoursesMobileDay from './bgDefaultCoursesMobileDay.jpg'
import bgDefaultCoursesNight from './bgDefaultCoursesNight.jpg'
import bgDefaultCoursesTabletNight from './bgDefaultCoursesTabletNight.jpg'
import bgDefaultCoursesMobileNight from './bgDefaultCoursesMobileNight.jpg'
import bgDefaultLogin from './bgDefaultLogin.jpg'

import { BrandingImages, Images } from './types'

/** Imagenes de día */
const day: Images = {
  desktop: bgDefaultCoursesDay,
  tablet: bgDefaultCoursesTabletDay,
  mobile: bgDefaultCoursesMobileDay
}

/** Imagenes de noche */
const night: Images = {
  desktop: bgDefaultCoursesNight,
  tablet: bgDefaultCoursesTabletNight,
  mobile: bgDefaultCoursesMobileNight
}

/**
 * Estructura del branding por defecto.
 */
const branding: BrandingImages = {
  day,
  night,
  login: bgDefaultLogin
}

export default branding
