import React, { useState, useEffect } from 'react'
import { PropAvatar } from './types'
import { Wrapper } from './Styled'
import loadingAvatar from '../../../../assets/svg/animated/Avatar/loading-avatar-animado.svg'
import { avatarPlaceHolder } from '~assets/svg'

type ImgElementProps = {
  url: string
  fn: (state: boolean) => void
}

/**
 * Hace el render del Avatar o un Placeholder con las primeras 2 letras del nombre de la persona.
 */
const Avatar = ({
  alt,
  size,
  userId,
  picture,
  fullName,
  fontSize
}: PropAvatar) => {
  const [selectedColor, setColor] = useState('')
  const [validUrl, setValidUrl] = useState(false)
  const loadingSvg = <img src={loadingAvatar} alt="loadingAvatar" />

  useEffect(() => {
    const colors = [
      '#0076ba',
      '#229f9c',
      '#2cb26b',
      '#30baed',
      '#95569e',
      '#9bc03a',
      '#d84315',
      '#f5a623',
      '#f6712f',
      '#ff6363'
    ]

    let colorPosition = 0
    if (userId >= 0) {
      const numbers = userId.toString().split('')
      colorPosition = parseInt(numbers[numbers.length - 1], 10)
    } else {
      colorPosition = Math.floor(Math.random() * colors.length)
    }

    setColor(colors[colorPosition])
  }, [userId])

  if (fullName.length === 0) {
    return (
      <Wrapper
        role="Avatar"
        fontSize={fontSize}
        size={size}
        selectedColor={'#fff'}
        className="RoundedImage">
        <img src={avatarPlaceHolder} alt="email" />
      </Wrapper>
    )
  }

  const splitLetterFromName = (input: string) =>
    input
      .split(' ')
      .map(letter => letter[0])
      .slice(0, 2)
      .join('')

  const ImgElement = ({ url, fn }: ImgElementProps) => {
    return (
      <img
        className={validUrl ? ' ' : 'test-img'}
        alt={alt}
        src={url}
        onLoad={() => fn(true)}
        onError={() => fn(false)}
      />
    )
  }

  if (picture) {
    if (!validUrl) {
      return (
        <Wrapper
          role="Avatar"
          fontSize={fontSize}
          size={size}
          selectedColor={selectedColor}
          className="RoundedImage">
          <span>{splitLetterFromName(fullName)}</span>
          <ImgElement
            url={picture}
            fn={(status: any) => {
              setValidUrl(status)
            }}
          />
        </Wrapper>
      )
    }
    return (
      <Wrapper
        role="Avatar"
        fontSize={fontSize}
        size={size}
        selectedColor={selectedColor}
        className="RoundedImage">
        {selectedColor === '' ? loadingSvg : <img src={picture} alt={alt} />}
      </Wrapper>
    )
  }
  return (
    <Wrapper
      role="Avatar"
      fontSize={fontSize}
      size={size}
      selectedColor={selectedColor}
      className="PlaceholderName flex-center">
      {selectedColor === '' ? (
        loadingSvg
      ) : (
        <span>{splitLetterFromName(fullName)}</span>
      )}
    </Wrapper>
  )
}

Avatar.defaultProps = {
  alt: 'Rounded Image',
  fontSize: 14,
  size: 50
}

export default Avatar
