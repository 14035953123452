/**
 * Detecto navegador y devuelvo boolean
 * @example
 * detectBrowser('Safari')
 */
export const detectBrowser = () => {
  const ua = navigator.userAgent
  if (ua.indexOf('Edge') > -1 && navigator.appVersion.indexOf('Edge') > -1) {
    return 'Edge'
  } else if (ua.indexOf('Opera') !== -1 || ua.indexOf('OPR') !== -1) {
    return 'Opera'
  } else if (ua.indexOf('Chrome') !== -1) {
    return 'Chrome'
  } else if (ua.indexOf('Safari') !== -1) {
    return 'Safari'
  } else if (ua.indexOf('Firefox') !== -1) {
    return 'Firefox'
  } else if (ua.indexOf('MSIE') !== -1) {
    return 'IE'
  } else {
    return 'unknown'
  }
}
