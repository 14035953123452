import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Label } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Button, Modal } from '~ui'
import { flashWarning } from '~assets/svg'

import { useAlerts } from './useAlerts'
import { Alert as Style } from './style'
import { Types } from './types'

/**
 * @example <Alerts />
 */
export const Alerts = () => {
  const { t } = useTranslation()
  const {
    comments: { type, show }
  } = useSelector((store: Types.Store) => store)

  const { closed, handleAgreed } = useAlerts()
  const [check, setCheck] = useState(false)
  const [state, setState] = useState({
    title: t('CommentSystemFileExceeds', { size: 50 }),
    info: t('CommentSystemTryWithFile')
  })

  useEffect(() => {
    const body = document.getElementsByTagName('body') as HTMLCollectionOf<
      HTMLBodyElement
    >
    if (body) {
      if (show) {
        body[0].style.overflowY = 'hidden'
      } else if (!show && body[0].style.overflowY === 'hidden') {
        body[0].style.overflowY = 'scroll'
      }
    }
  }, [show])

  useEffect(() => {
    if (type === 'redirect') {
      setState({
        title: t('CommentSystemLeavingEclass'),
        info: t('CommentSystemTrustedSite')
      })
    } else if (type === 'download') {
      setState({
        title: t('CommentSystemDownloadFiles'),
        info: t('CommentSystemTrustedSource')
      })
    } else if (type === 'delete') {
      setState({
        title: t('CommentSystemDeleteFiles'),
        info: t('CommentSystemIrrevesibleAction')
      })
    } else if (type === 'size') {
      setState({
        title: t('File exceeds', { size: 50 }),
        info: t('CommentSystemTryWithFile')
      })
    }
  }, [type, t])

  return (
    <>
      <Style.Wrapper type={type} />
      <Modal
        className="modal-comment"
        isOpen={show}
        toggle={() => closed()}
        closeModal={() => closed()}
        footer={
          <>
            {type !== 'size' && (
              <Button
                text={
                  type === 'download'
                    ? t('ResourceStopDownload')
                    : t('ResourceBack')
                }
                border
                onClick={() => {
                  closed()
                }}
              />
            )}
            <Button
              text={
                type === 'download'
                  ? t('ResourceAllowDownload')
                  : t('DashboardUnderstand')
              }
              border
              onClick={() => {
                handleAgreed(check)
              }}
            />
          </>
        }>
        <Style.Message>
          <img className="image" src={flashWarning} alt=" " />
          <h4>{state.title}</h4>
          <p>{state.info}</p>
          {type !== 'size' ||
            ('delete' && (
              <Style.Check>
                <Label check>
                  <Input
                    data-testid="inputSize"
                    checked={check}
                    name="check"
                    type="checkbox"
                    onChange={e => setCheck(e.target.checked)}
                  />
                  {t('CommentSystemNotShowMsg')}
                </Label>
              </Style.Check>
            ))}
        </Style.Message>
      </Modal>
    </>
  )
}
