const FancyBox = `
 .fancybox {
    &-container {
      > .fancybox-bg {
        background: #fff;
      }
      > .fancybox-inner {
        .fancybox-button {
          background: rgb(255,255,255);
          color: black;
        }
      }
      .fancybox-toolbar {
        background: rgba(0,0,0,.6);
        border-radius: 0 0 0 10px;
        opacity: 1;
        visibility: visible;

        .fancybox-button {
          background: none;
          color: white;
          height: 53px;
          width: 53px;
        }
      }
      .fancybox-image {
        background-color: #fff;
      }
    }
  }
`
export default FancyBox
