// @External Dependencies
import React, { useState } from 'react'
// @ts-ignore
import { ReCaptcha } from 'react-recaptcha-google'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Recaptcha = ({ className, verify }: any) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  if (typeof ReCaptcha === 'function') {
    setTimeout(() => {
      // @ts-ignore
      window.grecaptcha?.ready ? setShow(true) : setShow(false)
    }, 500)
  }

  return (
    <div className={className}>
      <p>{t('LogInNotRobot')}</p>
      {show && (
        <ReCaptcha
          render="explicit"
          sitekey="6LffuakUAAAAAD6DpHSbw5bxOlfHf714Daz-8X2m"
          verifyCallback={() => verify(true)}
        />
      )}
    </div>
  )
}

// @Proptypes
Recaptcha.propTypes = {
  verify: PropTypes.func.isRequired
}

export default styled(Recaptcha)`
  margin-bottom: 20px;
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }
`
