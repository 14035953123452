// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// @External Dependencies
import Select from './Select'
import Input from './Input'

// @Component
class InputDocument extends Component {
  constructor () {
    super()

    this.state = {
      placeholder: '',
      options: [],
      type: '',
      selected: '',
      selectedSelect: '',
      selectedInput: '',
      loginTypes: [],
      inputSize: 0,
      disabled: false,
      nameSelect: '',
      labelSelect: '',
      nameInput: '',
      required: true,
      icon: '',
      noDelete: false,
      tabIndex: ''
    }
  }

  componentDidMount () {
    const { t } = this.props
    this.setState({
      type: this.props.selectValue,
      selected: this.props.inputValue,
      selectedSelect: this.props.selectedSelect,
      selectedInput: this.props.selectedInput,
      loginTypes: this.props.loginTypes,
      inputSize: this.props.inputSize,
      disabled: this.props.disabled,
      nameSelect: this.props.nameSelect,
      labelSelect: this.props.labelSelect,
      nameInput: this.props.nameInput,
      required: this.props.required,
      icon: this.props.icon,
      noDelete: this.props.noDelete,
      tabIndex: this.props.tabIndex
    })

    let type = this.state.selectedSelect
    if (typeof selectedSelect === 'number') {
      type = this.selectNumber(this.state.selectedSelect)
    }

    let labelInput = this.props.labelInput
    if (type) {
      labelInput = this.selectName(type)
    }

    const placeholder = this.setPlaceholder(type)

    let options = []

    if (this.props.loginTypes && this.props.loginTypes.length) {
      this.props.loginTypes.forEach(id => {
        const nombre = this.selectName(id)
        options.push({ id, nombre })
      })
    } else {
      options = [
        { id: 'RUT', name: t('LogInRUT') },
        { id: 'USERNAME', name: t('LogInUser') },
        { id: 'EMAIL', name: t('LogInEmail') },
        { id: 'CC', name: t('LogInCC') },
        { id: 'DNI', name: t('LogInDNI') },
        { id: 'PASSPORT', name: t('LogInPassport') },
        { id: 'CI', name: t('LogInCI') },
        { id: 'RUC', name: t('LogInRUC') },
        { id: 'CE', name: t('LogInID') }
      ]
    }

    this.setState({
      type,
      placeholder,
      selected: this.state.selectedInput,
      options,
      labelInput
    })
  }

  componentDidUpdate (prevProps) {
    // es true si: el valor del label del input es diferente al que debiera tener con respecto a la seleccion de documento
    const labelDocumentSelected =
      this.state.labelInput !== this.selectName(this.state.options[0])

    if (prevProps !== this.props || labelDocumentSelected) {
      if (this.props.loginTypes && this.props.loginTypes.length === 1) {
        const labelInput = this.selectName(this.props.loginTypes[0])
        const placeholder = this.setPlaceholder(this.props.loginTypes[0])
        this.setState({
          options: this.props.loginTypes,
          labelInput,
          placeholder
        })
      }
    }
  }

  selectName = id => {
    let name
    switch (id) {
      case 'RUT':
        name = this.props.t('LogInRUT')
        break
      case 'USERNAME':
        name = this.props.t('LogInUser')
        break
      case 'EMAIL':
        name = this.props.t('LogInEmail')
        break
      case 'CC':
        name = this.props.t('LogInCC')
        break
      case 'DNI':
        name = this.props.t('LogInDNI')
        break
      case 'PASSPORT':
        name = this.props.t('LogInPassport')
        break
      case 'CI':
        name = this.props.t('LogInCI')
        break
      case 'RUC':
        name = this.props.t('LogInRUC')
        break
      case 'CE':
        name = this.props.t('LogInID')
        break
      default:
        name = id
        break
    }
    return name
  }

  selectNumber = selected => {
    let type
    switch (selected) {
      case 1:
        type = 'RUT'
        break
      case 2:
        type = 'USERNAME'
        break
      case 3:
        type = 'EMAIL'
        break
      case 4:
        type = 'CC'
        break
      case 5:
        type = 'DNI'
        break
      case 6:
        type = 'PASSPORT'
        break
      case 13:
        type = 'CI'
        break
      case 14:
        type = 'RUC'
        break
      case 15:
        type = 'CE'
        break
      default:
        type = undefined
        break
    }
    return type
  }

  setPlaceholder = type => {
    const { t } = this.props
    let placeholder
    switch (type) {
      case 'RUT':
        placeholder = t('LogInRUT')
        break
      case 'USERNAME':
        placeholder = t('LogInUser')
        break
      case 'EMAIL':
        placeholder = t('LogInExample')
        break
      case 'CC':
        placeholder = t('LogInCC')
        break
      case 'DNI':
        placeholder = t('LogInDNI')
        break
      case 'PASSPORT':
        placeholder = t('LogInPassport')
        break
      case 'CI':
        placeholder = t('LogInCI')
        break
      case 'RUC':
        placeholder = t('LogInRUC')
        break
      case 'CE':
        placeholder = t('LogInID')
        break
      default:
        placeholder = t('LogInUser')
    }
    return placeholder
  }

  changeType = e => {
    const type = e.target.value
    const placeholder = this.setPlaceholder(type)
    const labelInput = this.selectName(type)
    const selected = ''
    this.props.onChange(e)
    this.setState({
      type,
      placeholder,
      selected,
      labelInput
    })
  }

  changeInput = (e, clean) => {
    this.props.onChange(e)
    this.setState({
      selected: clean ? '' : e.target.value
    })
  }

  render () {
    const {
      placeholder,
      type,
      options,
      selected,
      labelInput,
      inputSize,
      disabled,
      nameSelect,
      labelSelect,
      nameInput,
      required,
      icon,
      noDelete,
      tabIndex
    } = this.state

    const inputDisabled =
      disabled || (disabled === false && !type && options && options.length > 1)

    const LOGIN_TYPES = 1
    return (
      <div>
        {options && options.length > LOGIN_TYPES && (
          <Select
            icon={icon}
            inputIcon={icon}
            name={nameSelect}
            options={options}
            onChange={this.changeType}
            label={labelSelect}
            inputSize={inputSize}
            required={required}
            select={type}
            disabled={this.props.disabled}
          />
        )}
        <Input
          disabled={inputDisabled || this.props.disabled}
          noDelete={noDelete}
          tabIndex={tabIndex}
          inputSize={inputSize}
          name={nameInput}
          placeholder={placeholder}
          label={labelInput}
          required={required}
          value={selected}
          onBlur={this.changeInput}
        />
      </div>
    )
  }
}

// @Proptypes
InputDocument.propTypes = {
  nameInput: PropTypes.string.isRequired
}

// @Export Component
export default withTranslation()(InputDocument)
