import styled from 'styled-components'

import { Types } from '../types'

export const desktop = '60px'
export const mobile = '25px'

export const Section = styled.div`
  padding-left: ${desktop};
  position: relative;
  &.show {
    > .answers {
      margin-top: 25px;
      height: 100%;
      opacity: 1;
      > svg {
        margin-left: -${desktop};
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 640px) {
    padding-left: ${mobile};
    &.show {
      > .answers {
        > svg {
          margin-left: -${mobile};
        }
      }
    }
  }
`

export const Answers = styled.div<Types.StyleRef>`
  height: 0;
  opacity: 0;
  transition: opacity 0.3s;
  > svg {
    opacity: 0;
    position: absolute;
    top: 0;
  }
  &.fixed {
    > svg {
      position: fixed;
      top: 80px;
    }
  }
  &.fixedBottom {
    > svg {
      bottom: 32px;
      top: inherit;
    }
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
  margin-top: 8px;
  padding: 0;
  &.show {
    margin-top: 15px;
  }
  > div {
    align-items: center;
    cursor: pointer;
    display: flex;
    span {
      text-transform: lowercase;
      :first-letter {
        text-transform: uppercase;
      }
    }
  }
`

export const ListAvatar = styled.div`
  display: flex;
  margin-right: 10px;
  > div {
    span {
      text-transform: uppercase;
    }
    :not(:last-child) {
      margin-right: 7px;
    }
  }
`
