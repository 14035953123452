import { Types } from './types'
/**
 * Retorno clase que tendra el botton que corresponde al tipo que es.
 * Actualmente la validación es por el href, pero esto cambiará
 * cuando envien el tipo desde la api
 * @param status
 */
export const typeOfItem = ({ status, type, href, name }: Types.TypeOfItem) => {
  if (href!.includes('resource')) {
    return 'isResourse'
  } else if (href!.includes('exercise')) {
    if (href!.includes('practice')) {
      if (status === null && type === null) {
        return 'isExercise'
      } else {
        return 'isSetExercise'
      }
    } else if (href!.includes('view')) {
      return 'isApplied'
    }
  } else if (href!.includes('test')) {
    if (name!.toLowerCase().includes('encuesta')) {
      return 'isPoll'
    } else {
      return 'isEvaluation'
    }
  }
  return ''
}
