// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError } from '../../../Services'

/**
 * Retorna la ruta de redirección y estado de cancelamiento
 *
 * @example
 * ```
 *   await resourceCancellation(this.props.config)
 * ```
 */
const resourceCancellation = (input: any) => {
  const requestId = 'ResourceCancellation'
  return async (dispatch: any) => {
    const query = `
      mutation ResourceCancellation($ResourceCancellationInput: ResourceCancellationInput!) {
        ResourceCancellation(input: $ResourceCancellationInput) {
          status {
            success
            name
            detail
            code
          }
          redirect
        }
      }
    `
    const variables = {
      ResourceCancellationInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.ResourceCancellation
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default resourceCancellation
