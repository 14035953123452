import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderAvatar } from '../HeaderAvatar'
import { Button, filterString } from '~ui'
import { useModal } from './useModal'

import { TextLink as StyleTextLink } from '../style'
import * as Style from './style'

import { Props } from '../types'

/**
 * Componente Wrapper de un editor
 *
 * Agregar un headerAvatar
 * y una botonera adicional al children que se le pasa
 *
 * @example
 * <CommentSystem.Editor user={user} editor={editorConfig}>
 *   <TextEditor />
 * </CommentSystem.Editor>
 */
export const Editor = ({
  children,
  editor,
  user,
  edit = false,
  draft
}: Props.Editor) => {
  const { t } = useTranslation()
  const refsWrapper = useRef<HTMLDivElement>(null)
  const { openModal } = useModal(refsWrapper.current)

  /**
   * Cambia el estado del editor, si tiene un borrador tiene un borde de color, sino un gris por defecto.
   */
  const [isDraft, setIsDraft] = useState(false)

  const handleCancel = () => {
    if (!editor.loading) {
      editor.cancel()
    }
  }

  useEffect(() => {
    if (draft !== undefined) {
      if (
        filterString(draft?.text!) > 0 &&
        draft?.ResourceCommentAttacheds!.length !== 0
      ) {
        setIsDraft(true)
      } else {
        setIsDraft(false)
      }
    } else {
      setIsDraft(false)
    }
  }, [draft])

  const onCancel = () => {
    if (!editor.loading) {
      handleCancel()
    }
  }

  return (
    <Style.Section
      role="Editor"
      edit={edit}
      isDraft={isDraft}
      words={editor.words}>
      {user && (
        <header>
          <HeaderAvatar user={user} />
        </header>
      )}
      <Style.Editor
        ref={refsWrapper}
        onClick={() => openModal(refsWrapper.current)}>
        {children}
      </Style.Editor>
      <Style.Footer>
        {editor.showCancel && !editor.loading && (
          <StyleTextLink data-testid="EditorCancel" onClick={onCancel}>
            {t('ContentCanceled')}
          </StyleTextLink>
        )}
        {user && (
          <Button
            text={edit ? t('ResourceUpdate') : t('ResourceComment')}
            onClick={editor.save}
            disabled={editor.loading || !editor.words}
            loading={editor.loading}
          />
        )}
      </Style.Footer>
    </Style.Section>
  )
}
