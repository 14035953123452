import React from 'react'

export const Poll = () => (
  <svg
    role="iconPoll"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="24.5"
        cy="24.5"
        r="24.5"
        fill="#E0EEFA"
        fillRule="nonzero"></circle>
      <path
        fill="#0189FF"
        d="M40.143 43.612a2 2 0 01-.212.895l-1.574 3.142a1.997 1.997 0 01-3.571 0l-1.574-3.142a2 2 0 01-.212-.895v-12.66h7.143v12.66zM33 27a2 2 0 012-2h3.143a2 2 0 012 2v1.571H33V27z"></path>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25.276 44.143H8.952c-1.63 0-2.952-1.227-2.952-2.74V9.882c0-1.513 1.322-2.74 2.952-2.74h2.132m20.952 0h2.012c1.63 0 2.952 1.227 2.952 2.74V21.56"></path>
      <path
        stroke="#60798E"
        strokeWidth="2"
        d="M14 5V4a3 3 0 013-3h9a3 3 0 013 3v1"></path>
      <rect
        width="21"
        height="7"
        x="11"
        y="5"
        stroke="#60798E"
        strokeWidth="2"
        rx="3"></rect>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M16 36h9m-9-8h9m-9-8h9m-14 0h1m-1 8h1m-1 8h1"></path>
    </g>
  </svg>
)
