// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

// @Component
const Form = ({ onSubmit, children, required, t }) => {
  const handleSubmit = e => {
    if (onSubmit) {
      onSubmit(e)
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit} encType="multipart/form-data">
      {required && <p className="isRequired">{t('Required fields')}</p>}
      {children}
    </StyledForm>
  )
}

// @Proptypes
Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  required: PropTypes.bool
}

// @Export Component
export default withTranslation()(Form)

// @Styles
const StyledForm = styled.form`
  > p.isRequired {
    margin: 20px 0 0;
    color: #adadad;
    & + div {
      margin-top: 20px;
    }
  }
`
