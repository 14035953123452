import React, { useEffect, useState } from 'react'
import { Props } from '../types'
import { IFrame } from '~ui/IFrame'
import { CDN_URL } from '~app/Config/constants'

/**
 * Insertar un Iframe a un recurso, este tiene la lógica de que cuando es mobile se cambia a una modal
 *
 * @example
 *    <HTMLIFrame config={options} id={5} />
 */
export const HTMLIFrame = ({ config }: Props.IFrame) => {
  const [placeholder, setPlaceholder] = useState(
    `${CDN_URL}apps/v8/iframe-placeholder-open.jpg`
  )
  const handleURL = () => {
    const url = config.url.split('.')
    const ext = url[url.length - 1]
    if (ext === 'pdf') {
      return `https://docs.google.com/gview?embedded=true&url=${config.url}`
    }
    return config.url
  }

  useEffect(() => {
    if (config.placeholder.length > 0) {
      setPlaceholder(config.placeholder)
    }
  }, [config.placeholder])

  return (
    <IFrame
      src={handleURL()}
      height={Number(config.height) || 500}
      freeWidth={config.freeWidth}
      placeholder={placeholder}
    />
  )
}
