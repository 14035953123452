import styled from 'styled-components'

/**
 * Estilos CSS
 */
export default styled.section`
  img {
    width: 100%;
  }
  p {
    padding-left: 8px;
    font: italic 12px / 16px 'Roboto' !important;
    margin-top: 10px;
  }
  &.float {
    width: 250px;
    &.left {
      float: left;
      margin-left: -125px;
      margin-right: 40px;
    }
    &.right {
      float: right;
      margin-right: -125px;
      margin-left: 40px;
    }
  }
  @media screen and (max-width: 990px) {
    &.float {
      &.left,
      &.right {
        float: none;
        margin: 40px 0 0;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin: 40px -15px 0;
  }
`
