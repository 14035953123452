// @External Dependencies
import React from 'react'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import vars from '../variables'
import {
  flashDanger,
  flashInfo,
  flashSuccess,
  flashWarning,
  cerrarDefinicion
} from '../../../../assets/svg/'

// @Component
const AlertUI = ({
  icon,
  color,
  close,
  children,
  typeLabel,
  inputSize,
  className,
  state
}) => {
  const icons = new Map([
    ['success', flashSuccess],
    ['warning', flashWarning],
    ['danger', flashDanger]
  ])
  const iconSrc = icons.has(state) ? icons.get(state) : flashInfo

  const handleClose = () => close && close()

  return (
    <div className={`wrapper ${typeLabel ? 'row' : ''}`}>
      <StyledAlertUI
        className={typeLabel && `col-sm-${inputSize} col-sm-offset-2`}>
        <Alert toggle={close} className={className} color={color}>
          <img className="alert__icon" src={iconSrc} alt={`icon ${state}`} />
          <div className="content">{children}</div>
          {close ? (
            <span className="alert__close" onClick={handleClose}>
              <img src={cerrarDefinicion} alt="close flash" />
            </span>
          ) : null}
        </Alert>
      </StyledAlertUI>
    </div>
  )
}

// @Proptypes
AlertUI.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  close: PropTypes.func,
  icon: PropTypes.string,
  inputSize: PropTypes.number,
  color: PropTypes.string,
  typeLabel: PropTypes.bool
}

AlertUI.defaultProps = {
  icon: 'info',
  inputSize: 8,
  color: 'warning',
  state: ''
}

export default AlertUI

const StyledAlertUI = styled.div.attrs({ className: 'alertUI' })`
  .alert {
    align-items: center;
    border: 0;
    border-radius: 10px;
    color: #333;
    display: flex;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 50px 10px 20px;
    position: relative;
    text-align: left;
    transition: all ease-in-out 0.3s;
    width: 100%;

    &__ico {
      right: 15px;
      width: 60px;
    }
    &__close {
      cursor: pointer;
      line-height: 0;
      padding: 0;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    i {
      align-items: center;
      border-radius: 4px 0 0 4px;
      color: white;
      display: flex;
      font-size: 20px;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;
    }

    ul {
      margin-bottom: 0;
      padding-left: 15px;
    }

    button {
      display: none;
    }

    &-warning {
      background-color: ${vars.setColors.warning};
    }
    &-success {
      background-color: ${vars.setColors.success};
    }
    &-info {
      background-color: ${vars.setColors.info};
    }
    &-danger {
      background-color: ${vars.setColors.danger};
    }
  }
`
