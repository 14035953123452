import { Types } from './types'

/**
 * Retorna formato básico para pasar al componente FooterNavigatio
 * @param navigation
 */
export const dataInitial = (navigation: Types.Navigation) => {
  return {
    navigation,
    prev: undefined,
    next: undefined
  }
}
