import { CampusBrandingPayload } from '@eclass/api'

import { UIReducer } from './types'
import { ConfigType, Images } from '../../../../Config/domains/types'

/**
 * Payload Info que viene desde el Action ~actions/getCampusBranding
 */
type Payload = {
  result: CampusBrandingPayload
  config: ConfigType
}

export const dispatchBranding = (payload: Payload): UIReducer => {
  const { result, config } = payload
  let branding = config.branding
  if (result.status && result.status.success) {
    if (result.CampusBranding) {
      branding = {
        text: result.CampusBranding!.text!,
        day: parseDay(payload),
        night: parseNight(payload),
        logos: result.CampusBranding.BrandingLogos!
          ? result.CampusBranding.BrandingLogos!
          : [],
        login: parseLoginImage(payload)
      }
    }
  }

  return {
    branding,
    pdfPrivacy: result.PDF,
    loginTypes: result.CampusLoginTypes ? result.CampusLoginTypes : [],
    campusId: result.CampusBranding && result.CampusBranding.campusId,
    programId: config.programId,
    // Idioma de la plataforma, por defecto Español (spa).
    i18n: result.i18nTag || 'spa'
  }
}

const parseLoginImage = (payload: Payload): string => {
  const { result, config } = payload
  return result.CampusBranding?.loginImage!
    ? result.CampusBranding?.loginImage!
    : config.branding?.login!
}

/**
 * Ordena las imágenes de día.
 * @returns {Images} - Imágenes parseadas.
 */
const parseDay = (payload: Payload): Images => {
  const { result, config } = payload

  const desktop = result.CampusBranding?.desktopImage
    ? result.CampusBranding?.desktopImage!
    : config.branding?.day.desktop!

  const tablet = result.CampusBranding!.tabletImage
    ? result.CampusBranding?.tabletImage!
    : config.branding?.day.tablet!

  const mobile = result.CampusBranding!.mobileImage
    ? result.CampusBranding?.mobileImage!
    : config.branding?.day.mobile!

  return {
    desktop,
    tablet,
    mobile
  }
}

/**
 * Ordena las imágenes de noche.
 * @returns {Images} - Imágenes parseadas.
 */
const parseNight = (payload: Payload): Images => {
  const { result, config } = payload

  const desktop = result.CampusBranding!.desktopImage
    ? result.CampusBranding?.desktopImage!
    : config.branding?.night.desktop!

  const tablet = result.CampusBranding!.tabletImage
    ? result.CampusBranding?.tabletImage!
    : config.branding?.night.tablet!

  const mobile = result.CampusBranding!.mobileImage
    ? result.CampusBranding?.mobileImage!
    : config.branding?.night.mobile!

  return {
    desktop,
    tablet,
    mobile
  }
}
