import styled from 'styled-components'
import UIVars from '../variables'

const Wrapper = styled.div`
  background: white;

  .ResourceNav {
    &__back {
      height: 66px;
      a {
        &:hover {
          color: ${UIVars.setColors.brownGreyTwo};
        }
        align-items: center;
        display: flex;
        line-height: 1.3;
      }
    }
  }

  .ResourceNav__menu {
    .Objectives__Button {
      background-color: ${UIVars.setColors.veryLightBlue};
      color: ${UIVars.setColors.deepSkyBlue};
      font-weight: 500;
      .Pressed__content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 16px;
        .button__container {
          font-size: 14px;
          color: ${UIVars.setColors.deepSkyBlue};
        }
      }
      &.isMobile {
        .Pressed__content {
          padding: 0 10px;
          height: 30px;
          line-height: 21px;
          .Svg__Icon {
            margin-right: 0px;
          }
        }
      }
    }
  }

  &.isFixed {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
  }

  button {
    flex-shrink: 0;
  }

  a {
    color: ${UIVars.setColors.brownGreyTwo};
    font-size: 14px;
    text-decoration: none;

    img {
      font-size: 14px !important;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0;

    .ResourceNav {
      &__back {
        height: 50px;
      }
    }

    .container {
      padding: 0;
    }

    .ResourceNav__menu {
      padding: 10px 0;
    }

    &.fixed {
      top: 0px;
    }
  }
`
export default Wrapper
