// @External Dependencies
import React, { useState, useEffect } from 'react'

import { IconProps } from './types'
import { Icon } from '~ui/Icons'
import { IconNames } from '../Icons/Icon/types'

const maxPercentage = 100
const minPercentage = 0

// @Component
export const HeaderTabsSlick = (props: IconProps) => {
  const { progress, visible, sizeIcon, color } = props
  const [name, setName] = useState<IconNames>('')
  const [viewBox, setvVewBox] = useState(sizeIcon)

  useEffect(() => {
    if (progress === maxPercentage) {
      setName('done')
    } else if (progress > minPercentage) {
      setName('in-progress')
      const fixCenterSVG = 2
      setvVewBox(sizeIcon - fixCenterSVG)
    } else {
      setName('opened')
    }
  }, [progress, sizeIcon])

  if (!visible) return null

  return (
    <Icon
      name={name}
      className="svg-icons"
      height={sizeIcon}
      width={sizeIcon}
      viewBox={viewBox}
      fill={color}
    />
  )
}

export default HeaderTabsSlick
