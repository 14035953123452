import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Props } from '../types'
import Wrapper from './Style'

/**
 * ## Función General
 * Se utiliza para reforzar ideas fuerzas del artículo.
 *
 * ## Función Metodológica
 * Este recurso apela a la memoria visual del alumno, que este pueda recordar la información señalada en el destacado. Se reconocieron 2 tipos de destacados: de lectura continua y de lectura no continua.
 * @example
 *    <Featured config={options} id={4} />
 */
export const Featured = ({ config, id }: Props.Featured) => {
  const [className, setClassName] = useState('')

  useEffect(() => {
    if (config.type === 'outstanding') {
      setClassName('Article__outstanding')
    } else if (Number(config.float) === 0) {
      setClassName('Article__featured block')
    } else {
      setClassName(`Article__featured float ${config.position}`)
    }
  }, [config])

  return (
    <Wrapper className={className} key={id} role="Featured">
      {ReactHtmlParser(config.text)}
    </Wrapper>
  )
}
