/**
 * Setea la mediaquery para ver si es responsive o no la visualización
 * @param {boolean} isMobile
 */
const setMediaquery = isMobile => {
  return dispatch => {
    dispatch({
      type: 'SET_MEDIAQUERY',
      payload: isMobile
    })
  }
}

export default setMediaquery
