import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderAvatar } from '../HeaderAvatar'
import { setDate } from './setDate'
import { useLocale } from '~services'
import { FileList, useAlerts } from '../'
import { Label } from '~ui'

import { TextLink as StyleTextLink } from '../style'
import * as Style from './style'

import { Store } from '~app_store/types'
import { Props } from '../types'

/**
 * Componente que muestra el comentario realizado por un usuario,
 * con información como avatar del usuario, fecha de creación del comentario,
 * y acciones asociadas a este (respondes y editar si tu hiciste el comentario),
 * como tambien casos especiales (pregunta sugerida, pregunta nueva)
 * @example
 * <Content
 *   comment={ReactHtmlParser(comment.text)}
 *   date={new Date()}
 *   edit={edit}
 *   helper={false}
 *   respond={respond}
 *   user={user}
 *  />
 */
export const Content = ({
  comment,
  date,
  edit,
  profileId,
  respond,
  suggestedAnswer,
  user,
  files,
  id,
  isDraft,
  isNew
}: Props.Content) => {
  const { t } = useTranslation()
  const { Locale } = useLocale()
  const {
    loggedUser,
    courses: { getCourseInfo: courseInfo }
  } = useSelector((state: Store) => state)
  const { modalInit } = useAlerts()

  const helper = profileId === 3
  const teacher = profileId === 2
  const coordinator = profileId === 6

  const helperMode = helper || suggestedAnswer || teacher || coordinator
  const isHelper = helperMode ? 'isHelper' : ''

  const handleLink = (event: MouseEvent<HTMLDivElement>) => {
    const e = event.target as Element
    if (e.tagName === 'A') {
      modalInit({
        event,
        type: 'redirect'
      })
    }
  }

  return (
    <Style.Article role="Comment" id={`comment-${id}`}>
      <div className={`header ${isHelper}`} data-testid="CommentHeader">
        <HeaderAvatar user={user} profileId={profileId} />
      </div>
      <Style.Comment role="CommentText" onClick={handleLink}>
        {isNew && <Label className="isNew" text={t('QuestionNewAnswer')} />}
        {suggestedAnswer && (
          <span className="suggestedAnswer">{t('ResourceSuggested')}</span>
        )}
        {comment}
        <FileList files={files || []} download />
      </Style.Comment>
      <Style.Footer>
        <span>{setDate(date, Locale)}</span>
        {!suggestedAnswer && courseInfo.Student?.dates?.canGenerateActivity! && (
          <Style.Actions>
            {loggedUser.user!.id === user.id && (
              <StyleTextLink
                data-testid="CommentEdit"
                onClick={() => edit && edit()}>
                {t('ResourceEdit')}
              </StyleTextLink>
            )}
            <StyleTextLink
              data-testid="CommentRespond"
              onClick={() => respond && respond()}>
              {t('ContentAnswer')}
            </StyleTextLink>
          </Style.Actions>
        )}
      </Style.Footer>
    </Style.Article>
  )
}
