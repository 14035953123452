import React from 'react'

import MenuItemTest from './MenuItemTest'
import * as Style from './Style'
import { numberOfQuestion } from '~views/Tests/helpers/numberOfQuestion'

export const MenuItemLevel = ({ level, activeQuestion, items }: any) => {
  const totalLevels = items.length || 0
  const hasDescription = items[0]?.description !== ''
  const totalStages = items[0]?.Stages.length || 0
  return (
    <Style.Levels>
      {(totalLevels > 1 || hasDescription) && (
        <span className="levelName">{level?.name}</span>
      )}
      {level?.Stages.map((stage, key: number) => (
        <span key={key} className="itembox">
          {(totalStages > 1 || stage.description !== '') && (
            <span className="stageName">{stage.name}</span>
          )}
          {stage.Answers.map((answer, index: number) => {
            return (
              <MenuItemTest
                activeQuestion={activeQuestion}
                key={answer.config.id}
                number={
                  numberOfQuestion({
                    stages: level?.Stages,
                    id: answer.config.id,
                    hasLevelsStages: true,
                    index,
                    stageIndex: key
                  }) - 1
                }
                answer={answer}
              />
            )
          })}
        </span>
      ))}
    </Style.Levels>
  )
}
