import styled from 'styled-components'
import { UIVars } from '~ui'

/**
 * Estilos CSS
 */
export default styled.section`
  align-items: center;
  display: flex;
  margin: 50px 0px 0px 0px;

  figure {
    margin: 0 50px 0 0;
  }

  p {
    color: ${UIVars.setColors.deepSkyBlue} !important;
    font: 20px / 34px 'Lora';
    margin: 0;
  }

  &.round {
    figure img {
      max-width: 250px;
      border-radius: 50%;
    }
  }
  &.framed {
    figure img {
      max-width: 250px;
    }
  }
  @media screen and (max-width: 990px) {
    margin: 50px 0 0;
  }
  @media screen and (max-width: 768px) {
    figure {
      margin: 0 30px 0 0;
    }

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    text-align: center;
    figure {
      margin: 0 0 30px 0;
    }
  }
`
