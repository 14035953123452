// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Confirma la hora para una clase en vivo.
 *
 * @example
 * ```
 *   await addResourceConfirm({
 *    "studentId": 2261051,
 *    "courseId": 27438,
 *    "unitId": 496531,
 *    "resourceId": 958584,
 *  	"date": "2020-06-22T14:50:00.000Z"
 *  })
 * ```
 */
const addResourceConfirm = (input: any) => {
  const requestId = 'addResourceConfirm'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation ResourceConfirm($ResourceConfirmInput: ResourceConfirmInput!) {
        ResourceConfirm(input: $ResourceConfirmInput) {
          status {
            success
            name
            detail
            code
          }
        }
      }
    `
    const variables = {
      ResourceConfirmInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.ResourceConfirm
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default addResourceConfirm
