// @External Dependencies
import React, { useState, useEffect, useCallback } from 'react'

import { ComponentProps } from './types'
import { UIVars } from '~ui'
import { StyleWrapper, StyleEmpty } from './Style'
import Progress from './Progress'
import Icon from './Icon'

const strokeWidthSM = 3
const strokeWidthLG = 7
const sizeSM = 16
const sizeLG = 44

// @Component
export const HeaderTabsSlick = (props: ComponentProps) => {
  const initial = {
    color: props.color || UIVars.setColors.primary,
    colorEmpty: props.colorEmpty || '#d6d6d6',
    icon: props.icon || false,
    progress: props.progress || 0,
    size: props.size || (props.icon ? sizeLG : sizeSM),
    sizeIcon: props.sizeIcon || sizeSM,
    strokeWidth:
      props.strokeWidth || (props.icon ? strokeWidthLG : strokeWidthSM)
  }

  const [state, setState] = useState(initial)

  // actualizo datos iniciales
  const toUpdate = useCallback(() => {
    const withIcon = props.icon
      ? {
          size: props.size || sizeLG,
          strokeWidth: props.strokeWidth || strokeWidthLG
        }
      : {}
    setState({ ...initial, ...withIcon })
  }, [props, setState, initial])

  useEffect(() => {
    if (props.progress !== state.progress) {
      toUpdate()
    }
  }, [props.progress, state.progress, toUpdate])

  return (
    <StyleWrapper
      className="CircularProgressBar"
      size={state.size}
      strokeWidth={state.strokeWidth}
      data-testid="CircularProgressBar">
      <Progress
        color={state.color}
        progress={state.progress}
        size={state.size}
        strokeWidth={state.strokeWidth}
      />
      <StyleEmpty
        colorEmpty={state.colorEmpty}
        size={state.size}
        strokeWidth={state.strokeWidth}
      />
      <Icon
        visible={state.icon}
        progress={state.progress}
        color={state.color}
        sizeIcon={state.sizeIcon}
      />
    </StyleWrapper>
  )
}

export default HeaderTabsSlick
