import styled from 'styled-components'

import { ComponentStyleProps, ProgressStyleProps } from './types'

export const StyleWrapper = styled.div<ComponentStyleProps>`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${props => props.size}px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${props => props.size}px;
  svg {
    margin: 0;
  }
`
export const StyleProgress = styled.div<ProgressStyleProps>`
  height: ${props => props.size}px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: ${props => props.size / 2}px;
  z-index: 2;

  &.Cpb {
    &__right {
      right: 0;
    }

    &__left {
      left: 0;
      .Cpb {
        &__wrap {
          transform: translateX(100%) rotate(${props => props.left}deg);
          transform-origin: center left;
        }
        &__circle {
          transform: translateX(-50%);
        }
      }
    }
  }
  .Cpb {
    &__wrap {
      height: ${props => props.size}px;
      overflow: hidden;
      position: relative;
      transform-origin: center right;
      transform: translateX(-100%) rotate(${props => props.right}deg);
      width: ${props => props.size / 2}px;
    }
    &__circle {
      border-radius: 50%;
      border: solid ${props => props.color} ${props => props.strokeWidth}px;
      height: ${props => props.size}px;
      width: ${props => props.size}px;
    }
  }
`
export const StyleEmpty = styled.div<ComponentStyleProps>`
  border-radius: 50%;
  border: solid ${props => props.colorEmpty} ${props => props.strokeWidth}px;
  height: ${props => props.size}px;
  position: absolute;
  top: 0;
  width: ${props => props.size}px;
`
