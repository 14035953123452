// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'

/**
 * Cancela la clase en vivo y obtiene nuevos valores del botón
 *
 * @example
 * ```
 *   await UnitCancellation(this.props.config)
 * ```
 */
const UnitCancellation = (input: any) => {
  const requestId = 'getUnitCancellation'
  return async (dispatch: any) => {
    getToken()
    const query = `
    mutation   UnitCancellation ($input: UnitCancellationInput!) {
      UnitCancellation(input: $input) {
        status {
          success
          name
          code
          detail
        },
        Cancellation {
          id
        }
        typeCancellation
      }
    }
    `
    const variables = {
      input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.UnitCancellation
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default UnitCancellation
