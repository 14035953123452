import React from 'react'

import * as Styles from './style'
import { Props, Types } from './types'

/**
 * Dibujo el logo del branding,
 * si este posee mas de uno estos igual los dibujo
 * @example <BrandingLogos logos={[]} />
 * @param defaultLogo
 * @param logos
 * @param sameSize
 */
export const BrandingLogos = ({
  defaultLogo,
  logos,
  sameSize
}: Props.BrandingLogos) => {
  /**
   * signo clase para que el primer logo sea mas grande
   * o para que todos tengan el mismo tamaño
   * @param index {number}
   */
  const isMain = (index: number) => {
    if (!sameSize && index === 0) {
      return 'main'
    }
    if (sameSize) {
      return 'sameSize'
    }
    return ''
  }

  if (logos?.length === 0 && !defaultLogo) {
    return null
  }

  return (
    <Styles.Wrapper data-testid="brandingLogos">
      {logos && logos.length > 0 ? (
        logos.map((logo: Types.BrandingLogoAttributes, index: number) => (
          <figure
            data-testid="brandingImages"
            className={isMain(index)}
            key={logo.name}>
            <img src={logo.url} alt={logo.name} />
          </figure>
        ))
      ) : (
        <figure className="main">
          <img src={defaultLogo} alt="Logo" data-testid="defaultLogo" />
        </figure>
      )}
    </Styles.Wrapper>
  )
}
