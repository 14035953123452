import { useState, useCallback, useEffect } from 'react'

/**
 * Polify para hacer el corte de texto en IE
 * @param ref
 * @param text
 */
export const usePolifyIE = (ref: any, text: string) => {
  const [polify, setPolify] = useState(false)
  const [desktop, setDesktop] = useState(true)
  const [lineHeight, setLineHeight] = useState(28)

  /**
   * hago el corte del texto cuando este es mas grande que el componente
   */
  const handleTruncate = useCallback(() => {
    const base = `${text}`
    const current = ref.current
    if (current) {
      current.innerHTML = text
      const wordArray = base.split(' ')
      while (current.scrollHeight > current.offsetHeight) {
        wordArray.pop()
        current.innerHTML = wordArray.join(' ') + '...'
      }
    }
  }, [ref, text])

  /**
   * hace que solo ejecute handleTruncate cuando se pase a otra tipo de viewport (desktop y mobile)
   */
  const handleBreakPoint = useCallback(() => {
    if (window.innerWidth >= 768) {
      if (!desktop) {
        setDesktop(true)
        handleTruncate()
      }
    } else {
      if (desktop) {
        setDesktop(false)
        handleTruncate()
      }
    }
  }, [desktop, handleTruncate])

  useEffect(() => {
    const requiresPolify = navigator.userAgent.indexOf('MSIE') > -1
    // lo ejecuta solo si el navegador es IE y si viene texto
    if (requiresPolify && text !== null) {
      setPolify(true)
      if (ref.current) {
        // obtengo y seteo el line-height del componente
        setLineHeight(parseInt(window.getComputedStyle(ref.current).lineHeight))
      }
      handleTruncate()
      window.addEventListener('resize', handleBreakPoint)
    }
    return () => {
      window.removeEventListener('resize', handleBreakPoint)
    }
  }, [handleBreakPoint, handleTruncate, text, ref])

  return { polify, lineHeight }
}
