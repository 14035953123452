// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Obtiene la data de la vista de detalles en clases en vivo
 *
 * @example
 * ```
 *   await getResourceDetail(this.props.config)
 * ```
 */
const getResourceDetail = (input: any) => {
  const requestId = 'getResourceDetail'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getResourceDetail($input: ResourceDetailInput!) {
        getResourceDetail(input: $input) {
          status {
            success
            name
            code
            detail
          }
          Menu {
            Navigation {
              prev {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
              next {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
            }
            Links {
              id
              name
              href
              indicators {
                id
                name
                image
                obtained
                max
              }
              status {
                id
                name
                detail
                style
                enabled
              }
              Childrens {
                id
                name
                href
                status {
                  id
                  name
                  detail
                  style
                  enabled
                }
                indicators {
                  id
                  name
                  image
                  obtained
                  max
                }
              }
            }
            Unit {
              id
              name
              title
            }
          }
          Teacher {
            id
            picture
            name
            firstSurname
            lastSurname
          }
          Unit {
            id
            name
            url
            status {
              value
              text
            }
            messages {
              date
              duration
              gmt
            }
          }
          ScheduleMessage {
            cancel
            scheduled
            messages {
              message
            }
          }
          Recommendations {
            icon
            message
          }
          Provider {
            name
            urlRequirements
            urlAndroid
            urlIos
          }
        }
      }
    `
    const variables = { input }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => response.data.data.getResourceDetail)
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getResourceDetail
