import { useState, useEffect, useCallback } from 'react'

import { Types } from './types'

export const useFixed = () => {
  const [isFixed, setIsFixed] = useState<Types.isFixed>('')

  const handleScroll = useCallback(() => {
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    // barra que contienes el logo de eclass
    const MainHeader = document.getElementById('MainHeader')
    // barra fixed
    const ResourceNav = document.getElementsByClassName('ResourceNav')
    // contenedor del contenido del recurso
    const ViewContainer = document.getElementById('ViewContainer')

    if (MainHeader && ViewContainer && ResourceNav && ResourceNav.length > 0) {
      if (windowScrollTop >= MainHeader.clientHeight) {
        if (isFixed !== 'isFixed') {
          ViewContainer.style.marginTop = `${ResourceNav[0].clientHeight}px`
          setIsFixed('isFixed')
        }
      } else {
        if (ViewContainer.style.marginTop !== '0px') {
          ViewContainer.style.marginTop = '0px'
          setIsFixed('')
        }
      }
    }
  }, [isFixed])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return { isFixed }
}
