// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'

/**
 * Obtiene el estado del botón cancelar en el detalle de la clase presencial
 *
 * @example
 * ```
 *   await getUnitCancellation(this.props.config)
 * ```
 */
const getUnitCancellation = (input: any) => {
  const requestId = 'getUnitCancellation'
  return async (dispatch: any) => {
    getToken()
    const query = `
    query getUnitCancellation ($input: UnitGetCancellationInput!) {
      getUnitCancellation(input: $input) {
        status {
          success
          name
          code
          detail
        }
        totalCourse
        total
        statusCancellation
        typeCancellation
      }
    }
    `
    const variables = {
      input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.getUnitCancellation
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getUnitCancellation
