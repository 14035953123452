// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Guarda el avance de un Recurso al visitarlo
 * @typedef {import('@eclass/api').ResourceProgressPayload} ResourceProgressPayload
 * @typedef {import('@eclass/api').ResourceProgressInput} ResourceProgressInput
 *
 * @param {ResourceProgressInput} input - Parámetros para registrar el ingreso.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444,
 *      entryId: 999
 *    }
 *    const response = await getQuestionList(input)
 * ```
 * @returns {Promise<ResourceProgressPayload>} Retorna el Estado si se guardó el avance del alumno
 */
const addResourceProgress = input => {
  const requestId = 'addResourceProgress'
  return async dispatch => {
    const { entryId } = getToken()
    if (entryId > 0) {
      const query = `
        mutation addResourceProgress($ResourceProgressInput: ResourceProgressInput!) {
          addResourceProgress(input: $ResourceProgressInput) {
            status {
              success
              name
              code
              detail
            }
          }
        }
      `
      const variables = {
        ResourceProgressInput: {
          studentId: parseInt(input.studentId, 10),
          courseId: parseInt(input.courseId, 10),
          unitId: parseInt(input.unitId, 10),
          resourceId: parseInt(input.resourceId, 10)
        }
      }

      return axios
        .post(`${apiURL}/graphql`, { query, variables }, { requestId })
        .then(response => response.data.data.addResourceProgress)
        .catch(err => axiosError(err, requestId, dispatch))
    }
  }
}

export default addResourceProgress
