import React from 'react'
import styled from 'styled-components'

import { UIVars } from '~ui'

type Props = {
  type?: 'omitted'
  className?: string
  text: string
}

/**
 * Componente que genera un wrapper label para el texto que se le pase
 */
export const Label = ({ type, className = '', text }: Props) => {
  return (
    <StyleWrapper className={`Label ${type} ${className}`} data-testid="label">
      <div>{text}</div>
    </StyleWrapper>
  )
}

const StyleWrapper = styled.div`
  line-height: 1;
  position: relative;

  & > div {
    background: ${UIVars.setColors.primary};
    border-radius: 3px;
    color: ${UIVars.setColors.whiteThree};
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: initial;
    margin-left: 10px;
    padding: 3px 10px 3px 2px;
    position: relative;
    text-transform: none;

    :before {
      content: '';
      border-color: transparent;
      border-style: solid;
      border-right-color: ${UIVars.setColors.primary};
      border-width: 10px 10px 10px 0;
      display: inline-block;
      height: 100%;
      left: -9px;
      position: absolute;
      top: 0;
      width: 0;
    }
  }

  &.omitted {
    & > div {
      background: ${UIVars.setColors.icons};
      color: ${UIVars.setColors.black};
      padding-left: 12px;
      padding-right: 12px;
      :before {
        border-right-color: ${UIVars.setColors.icons};
      }
    }
  }
`
