// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} InputType
 * @property {number} studentId - Alumno
 * @property {number} courseId - Curso
 * @property {number} unitId - Unidad
 * @property {number} resourceId - Recurso
 * @property {number} programId - Programa
 */

/**
 * Obtiene los datos de una pregunta aplicada.
 *
 * @param {InputType} input - Parámetros para buscar los datos de la pregunta aplicada.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444,
 *      programId: 555
 *    }
 *    const response = await getResource(input)
 * ```
 * @returns {Promise<any>} Retorna el Recurso con sus partes (status, Exercise, ExerciseAnswers).
 */

const getExercise = input => {
  const requestId = 'getResource'
  return async dispatch => {
    getToken()
    const answer = `
      id
      text
      modified
      studentId
      User {
        Person {
          id
          name
          fullName
          picture
        }
      }
    `
    const query = `
      query getExercise($ExerciseViewInput: ExerciseViewInput!) {
        getExercise(input: $ExerciseViewInput) {
          status {
            success
            detail
            name
            code
          }
          Menu {
            Navigation {
              next{
                href
                name
                status{
                  enabled
                  style
                }
              }
              prev{
                href
                name
                status{
                  enabled
                  style
                }
              }
            }
            Links {
              id
              name
              href
              indicators {
                id
                name
                image
                obtained
                max
              }
              status {
                id
                detail
                style
                enabled
              }
              Childrens {
                id
                name
                href
                status {
                  id
                  name
                  detail
                  enabled
                  style
                }
                indicators {
                  id
                  name
                  image
                  obtained
                  max
                }
              }
            }
            Unit{
              id
              name
            }
          }
          Exercise {
            name
            statement
            question
            teacherAnswer
            hideAnswers
            answered
            modified
            Author{
              fullName
              picture
              id
            }
            Unit{
              id
              name
            }
            qualification {
              value
              valueFormatted
              status {
                id
                enabled
                name
                detail
              }
            }
            embeds {
              id
              name
              configurations
              typeId
                ContentModuleType {
                  id
                }
            }
          }
          ExerciseAnswers{
            ${answer}
            SubExerciseAnswers{
              ${answer}
            }
          }
          total
          pages
        }
      }

    `

    const variables = {
      ExerciseViewInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10),
        unitId: parseInt(input.unitId, 10),
        resourceId: parseInt(input.resourceId, 10),
        programId: parseInt(input.programId, 10),
        page: parseInt(input.page, 10),
        limit: parseInt(input.limit, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.getExercise)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getExercise
