// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import UIVars from '../variables'

// @Component
const Label = ({ name, value, required, link, className, disabled }) => (
  <label
    htmlFor={name}
    className={`${className} col-xl-12 label-defect ${
      required ? 'isRequired' : ''
    } ${disabled ? 'disabled' : ''}`}>
    {value}
    {link}
  </label>
)

// @Proptypes
Label.propTypes = {
  disabled: PropTypes.bool,
  link: PropTypes.node,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string
}

// @Export Component
export default styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.disabled {
    color: ${UIVars.setColors.brownGreyTwo};
  }
  a {
    font-family: Roboto;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    text-decoration: underline;
    color: ${UIVars.setColors.deepSkyBlue};
  }
`
