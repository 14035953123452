import React from 'react'

export const Exercise = () => (
  <svg
    role="iconExercise"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="24.5"
        cy="24.5"
        r="24.5"
        fill="#E0EEFA"
        fillRule="nonzero"></circle>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M29.276 44.143H8.952A2.958 2.958 0 016 41.18V7.106a2.958 2.958 0 012.952-2.963h25.096A2.958 2.958 0 0137 7.106V20.81"></path>
      <path
        stroke="#60798E"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M30 9H13a2 2 0 00-2 2v4a2 2 0 002 2h17a2 2 0 002-2v-4a2 2 0 00-2-2z"></path>
      <path
        fill="#0189FF"
        d="M41.096 43.612a2 2 0 01-.212.895L39.31 47.65a1.997 1.997 0 01-3.572 0l-1.574-3.142a2 2 0 01-.211-.895v-12.66h7.142v12.66zM33.953 27a2 2 0 012-2h3.142a2 2 0 012 2v1.571h-7.142V27z"></path>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M22 24h7m-7 12h7"></path>
      <path
        stroke="#60798E"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.5 21a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm0 11a3.5 3.5 0 100 7 3.5 3.5 0 000-7z"></path>
    </g>
  </svg>
)
