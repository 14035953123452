import { useState, useEffect, useCallback } from 'react'

import { Types } from '../types'

/**
 * Manejo posicion y dimension de los modal del editor
 * @example
 * const { openModal } = useModal(refsWrapper.current)
 */
export const useModal = (refsWrapper: Types.Ref) => {
  const [state, setstate] = useState<Types.Ref>(refsWrapper)
  const Mobile = 640
  const Padding = 25

  const handleModal = useCallback(() => {
    if (state) {
      /** lista de los modal que se editaran */
      const list = ['image', 'link', 'emoji']
      list.forEach((item: string) => {
        const modalImage = document.getElementsByClassName(
          `rdw-${item}-modal`
        ) as HTMLCollectionOf<HTMLDivElement>

        /** pregunto si el modal esta rendereado */
        if (modalImage?.length > 0) {
          /** Ancho del editor */
          const btnImage = state.getElementsByClassName(
            `rdw-${item}-wrapper`
          ) as HTMLCollectionOf<HTMLDivElement>
          const editorContainerRect = state.getBoundingClientRect()

          /** el padinng depender del ancho del vieport */
          const paddingFinal = window.innerWidth < Mobile ? 0 : Padding

          /** ancho correspondiente */
          modalImage[0].style.width =
            editorContainerRect.width - paddingFinal * 2 + 'px'
          /** posicion correspondiente */
          modalImage[0].style.left =
            -1 * btnImage[0].getBoundingClientRect().x +
            editorContainerRect.x +
            paddingFinal +
            'px'
        }
      })
    }
  }, [state])

  /** Funcion que se ejecuta con delay para que se alcance a dibujar */
  const openModal = useCallback(
    (newRef: Types.Ref) => {
      if (state === null) {
        setstate(newRef)
      }
      setTimeout(() => {
        handleModal()
      }, 1)
    },
    [handleModal, state]
  )

  /** Cuando se inicia y cada vez que se redimension se llama a handleModal */
  useEffect(() => {
    handleModal()
    window.addEventListener('resize', handleModal)
    return () => {
      window.addEventListener('resize', handleModal)
    }
  }, [handleModal])

  return { openModal }
}
