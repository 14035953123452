import React from 'react'
import { UIVars } from '~ui'
import { Icon } from '~ui/Icons'
import { SlideFlashProps } from './helpers/types'
import getIcon from './helpers/getIcon'
import styled from 'styled-components'

export const SideFlash = ({
  children = 'Texto para mensaje flash. Lorem ipsum dolor sit amet',
  onClose,
  type = 'success',
  margin = 140,
  maxWidth = true,
  withShadow = true,
  toasted
}: SlideFlashProps) => {
  return (
    <Wrapper
      margin={margin}
      maxWidth={maxWidth}
      withShadow={withShadow}
      toasted={toasted}>
      <div id="alert" className={`alert ${type}`}>
        {type === 'warning' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="48"
            viewBox="0 0 60 48">
            <g fill="none" fillRule="evenodd">
              <circle cx="21.642" cy="24.405" r="21.642" fill="#FFF" />
              <circle cx="18.5" cy="24.5" r="1.5" fill="#F5A623" />
              <circle cx="23.5" cy="24.5" r="1.5" fill="#F5A623" />
              <circle cx="28.5" cy="24.5" r="1.5" fill="#F5A623" />
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeWidth="2"
                d="M43.678 15.783C40.657 10.534 34.991 7 28.5 7 18.835 7 11 14.835 11 24.5S18.835 42 28.5 42c6.108 0 11.484-3.129 14.615-7.871"
              />
              <g
                stroke="#F5A623"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5">
                <path d="M48.782 18.318v4.567M48.782 29.318l.046.046" />
              </g>
            </g>
          </svg>
        ) : (
          <img className="image" src={getIcon(type)} alt={type} />
        )}
        <div className="message">{children}</div>
        {onClose ? (
          <Icon
            onClick={onClose}
            className="close"
            name="close"
            fill="#566D82"
          />
        ) : null}
      </div>
    </Wrapper>
  )
}
type WrapperProp = {
  margin: number
  maxWidth?: boolean
  withShadow?: boolean
  toasted?: boolean
}

const Wrapper = styled.div<WrapperProp>`
  ${({ margin }: WrapperProp) => ({
    marginTop: margin
  })};

  .alert {
    align-items: center;
    border-radius: 10px;
    box-shadow: ${props =>
      props.withShadow ? '2px 2px 7px -1px rgba(0, 0, 0, 0.35)' : ''};
    display: flex;
    max-width: ${props => (props.maxWidth ? '540px' : '')};
    padding: 10px 10px 10px 20px;

    .image {
      height: 48px;
      margin-right: 15px;
      width: unset;
    }
    .message {
      flex-grow: 2;
      font-size: 14px;
      line-height: 1.29;
      margin-right: 30px;
      span {
        span {
          display: block;
          text-align: left !important;
        }
      }
    }
    .close {
      align-self: start;
      cursor: pointer;
      color: #566d82;
      opacity: 1;
      margin: 0;
    }
    &.success {
      background-color: ${UIVars.setColors.success};
    }
    &.info {
      background-color: ${UIVars.setColors.info};
    }
    &.warning {
      background-color: ${UIVars.setColors.warning};
      margin-right: 15px;
    }
    &.error {
      background-color: ${UIVars.setColors.danger};
    }
  }

  @media (max-width: 425px) {
    margin-top: ${props => (props.toasted ? '0px' : '')};
    line-height: ${props => (props.toasted ? '18px' : '')};
    width: ${props => (props.toasted ? '100vw' : '')};
    .alert {
      border-radius: ${props => (props.toasted ? '0px' : '')};
      &.warning {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 375px) {
    margin-top: 0px;
    .alert {
      border-radius: ${props => (props.toasted ? '0' : '10px')};
      box-shadow: ${props =>
        props.withShadow ? '2px 2px 7px -1px rgba(0, 0, 0, 0.35)' : ''};
      max-width: 540px;
      .message {
        text-align: left;
        margin-right: 0;
      }
      &.warning {
        margin-right: 0px;
      }
    }
  }
`
