import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal } from '~ui'

export const IFrame = ({ src, height, placeholder, freeWidth }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isMobile } = useSelector(state => state.ui)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }
  const isFreeWidth = freeWidth === '1'

  const styleGeneral = {
    padding: '8px 0px',
    width: isMobile ? '100%' : isFreeWidth ? '940px' : ''
  }

  return (
    <>
      <div style={styleGeneral}>
        {isMobile ? (
          <div
            style={{ display: 'block', cursor: 'pointer' }}
            onClick={() => toggleModal()}>
            <img
              alt="placeholder_iframe"
              src={placeholder}
              style={{
                display: 'block',
                maxWidth: '100%'
              }}
            />
          </div>
        ) : (
          <iframe
            src={src}
            frameBorder="0"
            title="iframe_content"
            style={{
              height: `${height}px`,
              width: isFreeWidth ? '940px' : '100%',
              margin: isFreeWidth ? '0 0 0 -140px' : '0'
            }}></iframe>
        )}
      </div>
      <Modal
        type="iframe"
        isOpen={isOpen}
        toggle={toggleModal}
        closeModal={toggleModal}>
        <iframe
          src={src}
          frameBorder="0"
          title="iframe_content"
          style={{ height: '100%', width: '100%' }}></iframe>
      </Modal>
    </>
  )
}
