let urlApi = ''

if (
  process.env.NODE_ENV === 'development' ||
  !window.location.host.includes('serveo.net')
) {
  urlApi = process.env.REACT_APP_API_URL || 'http://localhost:8000'
}
export const apiURL = urlApi
export const CDN_URL = 'https://cdn.eclass.com/'
export const a11y = process.env.REACT_APP_A11Y || false
