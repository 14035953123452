import { branding } from '~assets/img'
import { ConfigType } from './types'

/**
 * Listado de Dominios
 */
export const domains: { [url: string]: ConfigType } = {
  default: {
    appMobile: false,
    campusId: 0,
    defaultLogo: 'eclass',
    pdfPrivacy: '',
    programId: 0,
    showHeaderLogo: true,
    byEclass: true,
    branding: {
      text: '',
      day: branding.day,
      night: branding.night,
      login: branding.login,
      logos: [],
      loginTypes: []
    },
    i18n: 'es-419',
    keyZendesk: ''
  },
  'inglesapp.eclass.io': {
    campusId: 476,
    programId: 1956,
    appMobile: true
  },
  'dev-inglesapp.eclass.io': {
    campusId: 99,
    programId: 1956,
    appMobile: true
  },
  'platform.eclass.io': {
    campusId: 476,
    programId: 1956
  },
  'openuandes.eclass.com': {
    campusId: 803,
    programId: 1837,
    showHeaderLogo: false,
    defaultLogo: 'uandes'
  },
  'open.eclass.com': {
    campusId: 815,
    programId: 1860,
    keyZendesk: ''
  },
  'aguasandinasv8.eclass.com': {
    campusId: 259,
    programId: 1950
  },
  'cajalosandesv8.eclass.com': {
    campusId: 250,
    programId: 1953
  },
  'upla.eclass.com': {
    campusId: 30,
    programId: 1954
  },
  'autonoma.eclass.com': {
    campusId: 641,
    programId: 1422
  },
  'sjfarmsv8.eclass.com': {
    campusId: 765,
    programId: 1952
  },
  'hunterdouglasv8.eclass.com': {
    campusId: 690,
    programId: 1955
  },
  'campusinglesv8.eclass.com': {
    campusId: 24,
    programId: 1941
  },
  'methanex.eclass.com': {
    campusId: 831,
    programId: 1925
  },
  'compas.eclass.com': {
    campusId: 845,
    programId: 1949
  },
  'mowi.eclass.com': {
    campusId: 712,
    programId: 1959
  },
  'uddccp.eclass.com': {
    campusId: 50,
    programId: 1964
  },
  'uddsantiago.eclass.com': {
    campusId: 50,
    programId: 1963
  },
  'alumnosjpedianestlev8.eclass.com': {
    campusId: 743,
    programId: 1965
  },
  'campuseclassv8.eclass.com': {
    campusId: 57,
    programId: 1976
  },
  'agrosuperv8.eclass.com': {
    campusId: 583,
    programId: 1960
  },
  'cornershopv8.eclass.com': {
    campusId: 722,
    programId: 1962
  },
  'tanner.eclass.com': {
    campusId: 429,
    programId: 1986
  },
  'programaseclassv8.eclass.com': {
    campusId: 484,
    programId: 1990
  },
  'v8.diplomasunabonline.cl': {
    campusId: 800,
    programId: 2005,
    keyZendesk: ''
  },
  'uniandesv8.eclass.com': {
    campusId: 123,
    programId: 1987
  },
  'mastologia.eclass.com': {
    campusId: 770,
    programId: 2013
  },
  'bboschv8.eclass.com': {
    campusId: 547,
    programId: 2008
  },
  'nielsen.eclass.com': {
    campusId: 693,
    programId: 2014
  },
  'tensuandes.eclass.com': {
    campusId: 498,
    programId: 2021
  },
  'nexus.eclass.com': {
    campusId: 189,
    programId: 2026
  },
  'carozziv8.eclass.com': {
    campusId: 497,
    programId: 2030
  },
  'inacap.eclass.com': {
    campusId: 672,
    programId: 2042
  },
  'inacapv8.eclass.com': {
    campusId: 672,
    programId: 2042
  },
  'hdsv8.eclass.com': {
    campusId: 762,
    programId: 2022
  },
  'mutualcapacitacionv8.eclass.com': {
    campusId: 529,
    programId: 1872
  },
  'aiep.eclass.com': {
    campusId: 854,
    programId: 1994,
    showHeaderLogo: false,
    defaultLogo: 'empty'
  },
  'duocv8.eclass.com': {
    campusId: 398,
    programId: 2064
  },
  'issworld.eclass.com': {
    campusId: 505,
    programId: 2061
  },
  'clarochilev8.eclass.com': {
    campusId: 134,
    programId: 2081
  },
  'columbus.eclass.com': {
    campusId: 880,
    programId: 2086
  },
  'despegamipe.eclass.com': {
    campusId: 882,
    programId: 2088
  },
  'bancodelarepublicav8.eclass.com': {
    campusId: 294,
    programId: 2087
  },
  'fifv8.eclass.com': {
    campusId: 560,
    programId: 2090
  },
  'chilematv8.eclass.com': {
    campusId: 737,
    programId: 2091
  },
  'programasadvancedv8.eclass.com': {
    campusId: 876,
    programId: 2097
  },
  'powerclassgalaxynote20v8.eclass.com': {
    campusId: 867,
    programId: 2050
  },
  'usatv8.eclass.com': {
    campusId: 704,
    programId: 2102
  },
  'bancodelanacionv8.eclass.com': {
    campusId: 449,
    programId: 2117
  },
  'programaempleabilidadv8.eclass.com': {
    campusId: 891,
    programId: 2121
  },
  'metsov8.eclass.com': {
    campusId: 723,
    programId: 2123
  },
  'agrichile.eclass.com': {
    campusId: 888,
    programId: 2124
  },
  'preumonteclaro.eclass.com': {
    campusId: 875,
    programId: 2068
  },
  /**
   * Campus Demo v8 - Programa Demo v8
   * Es de uso interno.
   */
  'demov8.eclass.io': {
    campusId: 6,
    programId: 1956
  },
  'deploy-translations-spa.eclass.com': {
    campusId: 6,
    programId: 1956
  },
  'deploy-translations-eng.eclass.com': {
    campusId: 847,
    programId: 1957
  },
  'deploy-translations-por.eclass.com': {
    campusId: 745,
    programId: 1958
  },
  localhost: {
    // campus demo (6)
    campusId: 745,
    // programa demo v8 (1956)
    programId: 1958
  }
}

const domainConfig = (): ConfigType => {
  const hostname = window.location.hostname

  return {
    ...domains.default,
    ...domains[hostname]
  }
}

/**
 * Retorna la configuración de un dominio
 * @example
 *
 * ```js
 *   const config = domainConfig()
 * ```
 * @returns {ConfigType} Configuración del dominio
 */
export default domainConfig()
