import React from 'react'
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'

import Wrapper from './Style'
import { Props } from '../../types'

/**
 * @example
 *    <ImageBox config={options} id={61} />
 */
export const ImageBox = ({ config, id }: Props.ImageBox) => {
  return (
    <Wrapper
      className={classNames('Article__image_box', {
        round: config.style === 'circle',
        framed: config.style === 'framed'
      })}
      role="ImageBox"
      key={id}>
      <figure>
        <img
          src={`https://cdn.eclass.com/${config.image}`}
          alt={`https://cdn.eclass.com/${config.image}`}
        />
      </figure>
      {ReactHtmlParser(config.text)}
    </Wrapper>
  )
}
