const initialState = {
  answerType: {},
  answers: [],
  answered: false,
  seeCorrect: false,
  deleteIncorrect: false,
  questionCorrect: false,
  send: {
    status: false,
    type: ''
  },
  hint: null,
  callToAction: null,
  score: {
    obtained: 0,
    max: 3
  },
  alert: false,
  embeds: undefined,
  reorder: [],
  redo: false,
  answer: {}
}

export const exercises = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case 'EXERCISE_INITIAL':
      return {
        ...initialState,
        ...payload
      }
    case 'EXERCISE_UPDATE':
      return {
        ...state,
        ...payload
      }
    case 'EXERCISE_SET':
      return { ...state, answers: [...payload] }
    case 'EXERCISE_SEE':
      return { ...state, seeCorrect: payload }
    case 'EXERCISE_EXIT':
      return initialState
    case 'EXERCISE_REDO':
      return {
        ...state,
        seeCorrect: false,
        deleteIncorrect: true,
        answered: false,
        send: false,
        hint: null,
        redo: true,
        reorder: []
      }
    case 'EXERCISE_DELETE_WRONG':
      return {
        ...state,
        seeCorrect: false,
        deleteIncorrect: true,
        answered: false,
        answers: [...payload],
        hint: null,
        redo: false
      }
    case 'EXERCISE_SEND':
      return { ...state, send: payload }
    case 'EXERCISE_ALERT_CLOSE':
      return { ...state, alert: false }
    default:
      return state
  }
}
