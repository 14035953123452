// @External Dependencies
import axios from 'axios'
// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'
import { ApiRequest } from '@eclass/api'

type Response = {
  data: {
    data: { logout: ApiRequest }
  }
}

/**
 * Obtiene el branding de un campus
 *
 * @example
 * ```
 *   const { config, CampusBranding } = this.props
 *   await CampusBranding(config, this.props.ui)
 * ```
 */
const disconect = () => {
  const requestId = 'disconect'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation logout {
        logout {
          status {
            code
            name
            detail
            success
          }
        }
      }
    `
    /**
     * Llamamos a la mutación para desconectarse del API.
     */
    return axios
      .post(`${apiURL}/graphql`, { query })
      .then((response: Response) => {
        if (response.data.data?.logout?.status?.success) {
          dispatch({ type: 'LOGOUT' })
          return 'DISCONECT_OK'
        } else {
          return 'DISCONECT_FAIL'
        }
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
        return 'DISCONECT_FAIL'
      })
  }
}
export default disconect
