// Integración LogRocket
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

LogRocket.init('m7masl/v8')
setupLogRocketReact(LogRocket)

type Props = {
  id: string
  name: string
  email: string
  token: string
}

export const logRocket = ({ id, name, email, token }: Props) => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.identify(`${id}`, {
      name,
      email,
      token
    })
  }
}
