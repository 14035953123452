// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

import { useLessons } from './useLessons'

/**
 * @typedef {Object} InputType
 * @property {number} studentId - Alumno
 * @property {number} courseId - Curso
 */
/**
 * Lista las Clases y sus recursos. Además de traer el Timeline
 *
 * @param {InputType} input - Alumno
 * @example
 * ```js
 *    const response = await getUnitList(333)
 * ```
 * @returns Retorna las Unidades / Timeline y Estado.
 */
const getUnitList = input => {
  const requestId = 'getUnitList'
  return async dispatch => {
    getToken()
    const query = `
      query getUnitList($UnitListInput: UnitListInput!) {
        getUnitList(input: $UnitListInput) {
          status {
            success
            name
            code
            detail
          }
          Timeline {
            id
            name
            title
            enabled
            releaseDate
            progress
            total
            completed
            presential
            status {
              id
              name
              detail
              enabled
              style
            }
          }
          Units {
            id
            name
            title
            detail
            releaseDate
            progress
            href
            enabled
            lessons {
              id
              name
              href
              Parent {
                id
                name
              }
              label {
                id
                name
                enabled
                detail
                style
              }
              indicators {
                name
                image
                max
                obtained
              }
            }
            status {
              id
              name
              detail
              style
            }
            Goals {
              title
              description
            }
            transversal
            ResourceTypeId
            ResourceRefTypeId
            qualification {
              value
              valueFormatted
              status {
                id
                name
                detail
                style
              }
            }
            indicators {
              name
              image
            }
            items {
              id
              name
              intro
              thumbnail
              completed
              releaseDate
              enabled
              href
              Topics {
                colour
                initials
                title
                description
                LearningGoals {
                  title
                  description
                }
              }
              ResourceType {
                id
              }
              indicators {
                name
                image
                max
                obtained
              }
              qualification {
                value
                valueFormatted
                status {
                  name
                  style
                }
              }
              label {
                id
                name
                enabled
                detail
                style
              }
              status {
                id
                name
                enabled
                detail
                style
              }
            }
          }
        }
      }
    `

    const variables = {
      UnitListInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        dispatch({
          type: 'SET_LESSONS',
          payload: useLessons(response.data.data.getUnitList.Units)
        })
        return response.data.data.getUnitList
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getUnitList
