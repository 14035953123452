import React from 'react'
import { bgWelcomeFooter } from '../../../../assets/img'
import { FeedBackFlashProps } from './helpers/types'
import styled from 'styled-components'
import { Icon } from '~ui/Icons'
import { CountStars } from '~ui'
import handleHide from './helpers/handleHide'

export const FeedBackFlash = ({
  auto,
  star,
  subTitle,
  hidden,
  time = 100,
  timeOut = 5000,
  children = 'Texto para mensaje flash',
  type = 'exercisesFeedback'
}: FeedBackFlashProps) => {
  const alert = document.getElementById('alert')

  return (
    <Wrapper className={'visible'}>
      <div id="alert" className={`alert ${type} visible`}>
        <div className="message_container">
          <div className="close">
            <Icon
              onClick={() => {
                hidden()
                handleHide(alert, time)
              }}
              className="close"
              name="close"
              fill="#60798E"
            />
          </div>
          <div className="message">
            {children}
            <span className="countStars">
              <CountStars obtained={star.obtained} max={star.max} size="m" />
            </span>
          </div>
          <div className="second_message">{subTitle}</div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &:first-child {
    margin-top: 30px;
  }

  .alert {
    display: flex;
    padding: 16px;
    height: 115px;
    align-items: center;
    background-image: url(${bgWelcomeFooter});
    background-position: center;
    min-height: 115px;
    min-width: 350px;
    background-size: 700px;
    border-radius: 10px;
    box-shadow: -1px 6px 11px rgba(47, 47, 47, 0.289937);

    &.visible {
      display: flex;
    }
    &.visuallyenable {
      opacity: 1;
      top: 20px;
    }
    &.visuallyhidden {
      opacity: 0;
      top: -20px;
    }
    &.hidden {
      display: none;
      opacity: 0;
    }

    .message {
      font-weight: bold;
      display: flex;
      justify-content: left;
      font-size: 18px;

      .countStars {
        margin-left: 16px;
      }
    }

    .message_container {
      text-align: left;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .close {
        svg {
          margin-right: -5px;
        }
      }
    }

    .second_message {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 435px) {
    &:first-child {
      margin-top: 0px;
    }

    .alert {
      height: 115px;
      min-width: 330px;
    }
  }
`
