import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Wrapper } from './Style'
import { HeaderProps } from './types'
import { NewDropdown } from '~ui'
import { Store } from '~app_store/types'
import { LogoEclass } from '~ui/Icons'

/**
 * Componente cabacera de toda la aplicación.
 *
 * @example <Header config={config} />
 */
export const Header = ({ config: { showHeaderLogo } }: HeaderProps) => {
  const [loading, setLoading] = useState(true)
  const [logged, setLogged] = useState(false)
  const [user, setUser] = useState({})
  const {
    loggedUser,
    ui: { isMobile }
  } = useSelector((state: Store) => state)

  useEffect(() => {
    const currentLogged = Object.keys(loggedUser).length > 0

    if (currentLogged) {
      setUser(loggedUser.user!)
    }
    setLogged(currentLogged)
    setLoading(false)
  }, [loggedUser])

  if (loading) {
    return null
  } else {
    return (
      <Wrapper className="Header" id="MainHeader" data-testid="MainHeader">
        <Container fluid={isMobile}>
          <Row>
            <Col md="12">
              <div className="Header__topbar flex">
                <div className="flex">
                  {showHeaderLogo && (
                    <Link to="/courses">
                      <LogoEclass />
                    </Link>
                  )}
                  {/* {programs.length > 1 && !programsMobile && (
                    <DropDownPrograms
                      isOpen={programsDropdown}
                      activeProgram={activeProgram}
                      programs={programs}
                      toggleMenu={toggleMenu}
                      toogleOut={toogleOut}
                    />
                  )} */}
                </div>
                {logged && (
                  <NewDropdown dropdownType="avatar" userInfo={user} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default Header
