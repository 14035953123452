const Base = `
  html {
    scroll-behavior: smooth;
  }

  body{
    width: 100%;
    overflow-x: hidden;
  }

  .nucleo-icon {
    vertical-align: middle;
  }

  select{
    appearance: none
  }

  article {
    padding: 15px 0;
  }

  footer {
    padding: 15px 30px;
  }

  .form-control {
    color: #777781;

    &::-webkit-input-placeholder {
      color: #dedee8;
    }
  }

  svg {
    flex-shrink: 0;
  }

  .animationContainer{
    overflow: hidden;
  }

  video {
    max-width: 100%;
  }
`

export default Base
