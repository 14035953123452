const handleHide = (alert: HTMLElement | null, time: number) => {
  if (alert) {
    if (alert.classList.contains('visible')) {
      if (alert.classList.contains('visuallyenable')) {
        alert.classList.remove('visuallyenable')
      }
      alert.classList.add('visuallyhidden')
      setTimeout(function () {
        alert.classList.remove('visible')
      }, time)
      setTimeout(function () {
        alert.classList.add('hidden')
      }, time + 500)
    } else if (alert.classList.contains('hidden')) {
      alert.classList.remove('hidden')
      alert.classList.add('visible')
      setTimeout(function () {
        alert.classList.add('visuallyenable')
        alert.classList.remove('visuallyhidden')
      }, time / 2)
    } else {
      alert.classList.remove('visible')
      alert.classList.add('hidden')
    }
  }
}

export default handleHide
