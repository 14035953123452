import React from 'react'
import { UnitIconProps } from './types'

export const status = (active: boolean) => (active ? '#0189FF' : '#C6C6C6')
export const GridIcon = ({ active }: UnitIconProps) => (
  <svg
    className="GridIcon"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={16}
    height={16}
    viewBox="0 0 16 16">
    <g fill={status(active!)} fillRule="nonzero">
      <path d="M6 0H1C.4 0 0 .4 0 1v5c0 .6.4 1 1 1h5c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM15 0h-5c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h5c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM6 9H1c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h5c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1zM15 9h-5c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h5c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1z" />
    </g>
  </svg>
)
