import React from 'react'
import styled from 'styled-components'
import { Props } from '../types'

/**
 * ## Función General
 * Permite incrustar un audio con el Tag <audio></audio> interpretando la configuración de la base de datos.
 * @example
 *    <Audio config={options} id={3} />
 */
export const Audio = ({ config, id }: Props.Audio) => {
  return (
    <Wrapper className="Article__audio" key={id}>
      <audio controls>
        <source src={`//cdn.eclass.com/${config.audio}`} />
      </audio>
    </Wrapper>
  )
}

/**
 * Estilos CSS
 */
const Wrapper = styled.section`
  width: 100%;
  audio {
    width: 100%;
  }
`
