import React from 'react'

// @Dependencies
import { Button } from '~ui'
import { Icon } from '~ui/Icons'
import { WrapperDoc } from './Style'

type Doc = {
  titulo: string
  ruta: string
  extension: string
}

type Props = {
  doc: Doc
  id: number
}

export const DownloadFile = ({ doc, id }: Props) => {
  return (
    <WrapperDoc className="DownloadFiles">
      <div id={`${id}`} className="File flex">
        <div>
          <strong>{`${doc.titulo}`}</strong>
          <span>{`(${doc.extension})`}</span>
        </div>
        <Button link={doc.ruta} blank>
          <Icon name="download" width={14} height={16} fill="white" />{' '}
        </Button>
      </div>
    </WrapperDoc>
  )
}
