import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Wrapper from './Style'
import { Props } from '../types'

/**
 * ## Función General
 * Lo que señala el autor(a) referenciado en el texto, o información de un artículo académico de forma textual.
 * ## Función metodológica
 * Este recurso se utiliza para que el/la estudiante reconozca una fuente directa al contenido que se está tratando en el artículo. Como segunda función, pueda referenciar otros contenidos o incluso, generar una hipertextualidad con el contenido.
 * @example
 *    <Appointment config={options} id={2} />
 */
export const Appointment = ({ config, id }: Props.Appointment) => {
  return (
    <Wrapper className="Article__quote" key={id}>
      <div className="Article__quote__container" id={`quote_content_${id}`}>
        {ReactHtmlParser(config.text)}
      </div>
      <footer>
        <figure>
          <img src={`//cdn.eclass.com/${config.image}`} alt="" />
        </figure>
        <div>{ReactHtmlParser(config.abstract)}</div>
      </footer>
    </Wrapper>
  )
}
