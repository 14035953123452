// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { getToken, axiosError } from '../../../Services'

/**
 * Busca todas industrias ordenadas por nombre ascendente
 *
 * @example
 * ```js
 *    const response = await getIndustries()
 * ```
 * @returns Retorna una Lista con las Industrias (id, name)
 */
const getIndustries = () => {
  const requestId = 'getIndustries'
  return async dispatch => {
    getToken()

    const query = `
      query allIndustrys {
        allIndustrys(
          orderBy: name_ASC
        ){
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const { allIndustrys } = response.data.data
        if (response.status === 200 && allIndustrys) {
          return {
            data: allIndustrys,
            status: {
              success: true,
              detail: 'Petición exitosa'
            }
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar las Industrias.'
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getIndustries
