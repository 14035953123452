import React from 'react'

import { Featured } from './Featured'
import { HTMLIFrame } from './Iframe'
import { Image } from './Image'
import { Slideshow } from './Slideshow'
import { Accordion } from './Accordion'
import { Audio } from './Audio'
import { DownloadFile } from '~ui'
import { Appointment } from './Appointment'
import { ArticleVideo } from './ArticleVideo'

import { ContentModuleProps } from '../types'

const CITA = 1
const DESTACADO = 2
const CARRUSEL = 3
const ACORDEON = 4
const IMAGENES = 5
const IFRAME = 6
// const LIBRE = 7
const AUDIO = 8
const DESCARGAR_ARCHIVO = 9
const IFRAME_MODAL = 10
const VIDEO = 11

const ContentModule = ({ config }: ContentModuleProps) => {
  const options = JSON.parse(config.configurations!)
  switch (config.ContentModuleType!.id) {
    case CITA:
      return <Appointment config={options} id={config.id!} />
    case DESTACADO:
      return <Featured config={options} id={config.id!} />
    case CARRUSEL:
      return <Slideshow config={options} id={config.id!} />
    case ACORDEON:
      return <Accordion config={options} id={config.id!} />
    case IMAGENES:
      return <Image config={options} id={config.id!} />
    case IFRAME:
      return <HTMLIFrame config={options} id={config.id!} />
    case 7:
      return <p>{`__RENDER_MODULE____??____${config.ContentModuleType!.id}`}</p>
    case AUDIO:
      return <Audio config={options} id={config.id!} />
    case DESCARGAR_ARCHIVO:
      return <DownloadFile doc={options} id={config.id!} />
    case IFRAME_MODAL:
      return <HTMLIFrame config={options} id={config.id!} />
    case VIDEO:
      return <ArticleVideo config={options} id={options.id!} />

    default:
      return <div>Modulo invalido</div>
  }
}

export default ContentModule
