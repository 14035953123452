const initialState = {
  getCourseInfo: {
    Course: {
      Product: {
        brandingLogo: '',
        name: ''
      },
      activeUnit: {
        id: 0,
        title: ''
      },
      campusId: 0,
      haveQuestions: 0,
      href: '',
      navigationId: 0,
      progress: 0,
      remaining: 0,
      revisedWelcome: false,
      studenteId: 0
    }
  },
  programs: []
}

/**
 * Export reducer de courses.
 *
 * @param {Object} state - Estado inicial
 * @param {string} action - Acción a procesar
 */
export const courses = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'GET_COURSE_INFO':
      return {
        ...state,
        getCourseInfo: payload
      }
    case 'COURSE_INFO_UPDATE_TERMS':
      return {
        ...state,
        getCourseInfo: {
          ...state.getCourseInfo,
          Terms: []
        }
      }
    case 'SET_PROGRAMS':
      /**
       * Dejar esto comentado hasta que se defina la lógica del Branding y Select de:
       * Cursos eClass
       * Cursos Mutual
       * Cursos Falabella, etc
       */
      return {
        ...state,
        programs: payload
      }
    default:
      return state
  }
}
