import React from 'react'

import { Props } from './types'
import * as Style from './style'
import { Button, typeOfItem } from './'

export const FooterNavigation = ({ data }: Props.FooterNavigation) => {
  let prev: any = {}
  let next: any = {}
  if (data.prev) {
    prev = {
      ...data.navigation.prev,
      ...data.prev
    }
  }
  if (data.next) {
    next = {
      ...data.navigation.next,
      ...data.next
    }
  }

  let layout = ''
  if (data.prev && data.next) {
    layout = 'both'
  } else if (data.prev) {
    layout = 'prev'
  } else if (data.next) {
    layout = 'next'
  }

  return (
    <Style.Wrapper
      role="FooterNavigation"
      className={`FooterNavigation layout-${layout}`}>
      {data.prev && (
        <Button
          prev
          statusStyle={prev.status && prev.status.style}
          type={typeOfItem(prev)}
          disabled={prev.disabled}
          href={prev.href}
          label={prev.label}
          title={prev.title}
        />
      )}
      {data.next && (
        <Button
          next
          statusStyle={next.status && next.status.style}
          type={typeOfItem(next)}
          disabled={next.disabled}
          href={next.href}
          label={next.label}
          title={next.title}
        />
      )}
    </Style.Wrapper>
  )
}
