// @Dependencias externas
import React, { useState, useEffect } from 'react'

import { Wrapper } from './StyleSlide'
import { SliderImages } from './SliderImages'
import { SliderText } from './SliderText'
import { Props, Types } from '../types'
import { UIVars } from '~ui'
import { ChevronNext, ChevronPrev } from '~ui/Icons'

/**
 * ## Función General
 * Este recurso considera información que puede ser desplegada como
 * - Definición de conceptos,
 * - Descripción de pasos y/o procesos
 * - Ejemplos.
 *
 * ## Función metodológica
 * permite conjugar lo visual con lo semántico para que el/la alumna pueda retener la información, a partir de esta representación gráfica de la información señalada.
 * Este recurso está determinado por la interacción que le da el/la alumna, es decir, debe apretar un botón para seguir la secuencia de la información.
 * @example
 *    <Slideshow config={options} id={7} />
 */
export const Slideshow = ({ config, id }: Props.Slideshow) => {
  const [slideCount, setSlideCount] = useState(0)
  const [moveImage, setMoveImage] = useState(0)
  const [textHeight, setTextHeight] = useState(100)
  const [transition, setTransition] = useState('0.5s')

  const slideContent: Types.Slide[] = []

  const [slides, setSlides] = useState(slideContent)

  const getContent = () => {
    for (let i = 0; i < config.images.length; i++) {
      if (i < config.images.length - 1) {
        slideContent.push(config.images[i])
      } else if (i === config.images.length - 1) {
        slideContent.unshift(config.images[i])
        slideContent.push(config.images[i])
      }
    }
  }
  getContent()

  const BackArrow = () => (
    <div
      onClick={previousImage}
      className="carousel-control carousel-controls-prev"
      id="back">
      <ChevronPrev className="prev-icon" color={UIVars.setColors.whiteThree} />
    </div>
  )
  const NextArrow = () => (
    <div
      onClick={nextImage}
      className="carousel-control carousel-controls-next"
      id="next">
      <ChevronNext className="next-icon" color={UIVars.setColors.whiteThree} />
    </div>
  )

  const nextImage = () => {
    const MainContainer = document.querySelector<HTMLElement>(`.slider${id}`)

    if (MainContainer) {
      const widthMainContainer = Math.floor(MainContainer.offsetWidth)
      const texto = document.querySelectorAll<HTMLElement>(
        `.slider${id} .slider_content .item-content`
      )
      const elementWrapper = document.getElementById('wrapper')
      if (elementWrapper) {
        const fakeTransition = () => {
          setTransition(() => '0s')
          setMoveImage(() => widthMainContainer * config.images.length + 1)
          elementWrapper.removeEventListener('transitionend', fakeTransition)
        }

        setMoveImage(() => {
          const indexImGoing = slideCount + 1
          if (slideCount === 0) {
            if (slides.length === config.images.length + 1) {
              setSlideCount(i => i + 1)
              texto[slideCount].style.display = 'none'
              texto[indexImGoing].style.display = ''
              texto[indexImGoing].style.visibility = ''
              return widthMainContainer * 2
            } else {
              setSlideCount(i => i + 1)
              texto[slideCount].style.display = 'none'
              texto[indexImGoing].style.display = ''
              texto[indexImGoing].style.visibility = ''
              return moveImage + widthMainContainer
            }
          } else if (slideCount <= config.images.length - 2) {
            if (slides.length === config.images.length + 1) {
              setSlideCount(i => i + 1)

              texto[slideCount].style.display = 'none'
              texto[indexImGoing].style.display = ''
              texto[indexImGoing].style.visibility = ''

              return (slideCount + 2) * widthMainContainer
            } else if (
              slides.length !== config.images.length + 1 &&
              slideCount === config.images.length - 2
            ) {
              setSlideCount(i => i + 1)
              texto[slideCount].style.display = 'none'
              texto[indexImGoing].style.display = ''
              texto[indexImGoing].style.visibility = ''
              elementWrapper.addEventListener('transitionend', fakeTransition)
              return widthMainContainer + moveImage
            } else {
              setSlideCount(i => i + 1)
              texto[slideCount].style.display = 'none'
              texto[indexImGoing].style.display = ''
              texto[indexImGoing].style.visibility = ''

              return moveImage + widthMainContainer
            }
          } else {
            if (slides.length === config.images.length + 1) {
              setSlideCount(i => i * 0)
              texto[slideCount].style.display = 'none'
              texto[0].style.display = ''
              texto[slideCount].style.visibility = ''
              const roundForward = () => {
                for (let i = 0; i <= config.images.length - 1; i++) {
                  slideContent.push(config.images[i])
                }
              }
              roundForward()

              setSlides([...slideContent])
              return widthMainContainer * slides.length
            } else {
              setSlideCount(i => i * 0)
              setTransition(() => '0.5s')
              texto[slideCount].style.display = 'none'
              texto[0].style.display = ''
              texto[slideCount].style.visibility = ''

              return widthMainContainer * (config.images.length + 1)
            }
          }
        })
      }
    }
  }
  const previousImage = () => {
    const MainContainer = document.querySelector<HTMLElement>(`.slider${id}`)

    if (MainContainer) {
      const widthMainContainer = Math.floor(MainContainer.offsetWidth)
      const texto = document.querySelectorAll<HTMLElement>(
        `.slider${id} .slider_content .item-content`
      )
      const elementWrapper = document.getElementById('wrapper')

      if (elementWrapper) {
        setMoveImage(() => {
          const indexImGoing = slideCount - 1
          const fakeTransition = () => {
            setTransition(() => '0s')
            setMoveImage(() => widthMainContainer * config.images.length)

            elementWrapper.removeEventListener('transitionend', fakeTransition)
          }
          if (slideCount === 0) {
            if (slides.length === config.images.length + 1) {
              setSlideCount(() => config.images.length - 1)
              texto[config.images.length - 1].style.display = ''
              texto[config.images.length - 1].style.visibility = ''
              const goToTheEnd = () => {
                for (let i = config.images.length - 2; i >= 0; i--) {
                  slideContent.unshift(config.images[i])
                  texto[i].style.display = 'none'
                }
                slideContent.unshift(config.images[config.images.length - 1])
              }
              goToTheEnd()

              setSlides(() => slideContent)

              elementWrapper.addEventListener('transitionend', fakeTransition)
              return moveImage - widthMainContainer
            } else {
              setSlideCount(() => config.images.length - 1)

              elementWrapper.addEventListener('transitionend', fakeTransition)
              texto[config.images.length - 1].style.display = ''
              texto[config.images.length - 1].style.visibility = ''
              const makeLastTextShow = () => {
                for (let i = config.images.length - 2; i >= 0; i--) {
                  texto[i].style.display = 'none'
                }
              }
              makeLastTextShow()
              return moveImage - widthMainContainer
            }
          } else if (slideCount <= config.images.length - 1) {
            setSlideCount(i => i - 1)
            setTransition(() => '0.5s')
            texto[slideCount].style.display = 'none'
            texto[indexImGoing].style.visibility = ''
            texto[indexImGoing].style.display = ''
            return moveImage - widthMainContainer
          } else {
            setSlideCount(i => i * 0)
            texto[slideCount].style.display = 'none'
            texto[0].style.display = ''
            texto[slideCount].style.visibility = ''
            return moveImage - widthMainContainer
          }
        })
      }
    }
  }
  useEffect(() => {
    const Content = [
      // @ts-ignore
      ...document.querySelectorAll<any>(
        `.slider${id} .slider_content .item-content`
      )
    ]

    const sizes = Content.map(box => box.offsetHeight)
    const maxSize = Math.max(...sizes)
    setTextHeight(() => maxSize)

    const cajaText = document.querySelector<HTMLElement>(
      `.slider${id} .slider_content`
    )
    if (cajaText) {
      cajaText.style.height = `${textHeight}px`
    }
  }, [textHeight, id])

  useEffect(() => {
    const MainContainer = document.querySelector<HTMLElement>(`.slider${id}`)
    const imgContainer = document.querySelectorAll<HTMLElement>(
      `.slider${id} .slider_wrapper .carousel_item .img_container`
    )

    const setWidthImgContainer = () => {
      if (MainContainer) {
        const widthMainContainer = MainContainer.offsetWidth
        for (let i = 0; i < slides.length; i++) {
          imgContainer[i].style.width = `${widthMainContainer}px`
        }
      }
    }
    setWidthImgContainer()
  }, [slides, id])

  useEffect(() => {
    const MainContainer = document.querySelector<HTMLElement>(`.slider${id}`)
    if (MainContainer) {
      const widthMainContainer = Math.floor(MainContainer.offsetWidth)
      setMoveImage(() => widthMainContainer)
    }
    const texto = document.querySelectorAll<HTMLElement>(
      `.slider${id} .slider_content .item-content`
    )
    const hideText = () => {
      for (let i = 1; i < config.images.length; i++) {
        texto[i].style.visibility = 'hidden'
      }
    }
    hideText()
  }, [config.images.length, id])

  return (
    <Wrapper role="Slideshow">
      <section className={`slider${id}`}>
        <div
          id="wrapper"
          className="slider_wrapper"
          style={{
            transform: `translateX(-${moveImage}px)`,
            transitionDuration: transition
          }}>
          {slides.map((slide, key) => {
            return <SliderImages key={key} slide={slide} />
          })}
        </div>
        <section className="slider_content">
          {config.images.map((slide, key) => {
            return <SliderText key={key} slide={slide} />
          })}
        </section>
        <div className="slide-footer">
          <BackArrow />
          <ol className="carousel-indicators">
            {config.images.map((slide, key) => {
              return (
                <li
                  key={key}
                  className={
                    slideCount === key
                      ? 'carousel-indicator active'
                      : 'carousel-indicator'
                  }>
                  {' '}
                  <br />{' '}
                </li>
              )
            })}
          </ol>
          <NextArrow />
        </div>
      </section>
    </Wrapper>
  )
}
