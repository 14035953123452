import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Props } from '../types'

/**
 * @example <SliderText slide={slide} />
 */
export const SliderText = ({ slide }: Props.SliderImage) => {
  return (
    <div key={slide.orden} className="item-content">
      <span className={`item-text ${slide.orden}`}>
        {slide.title === '' ? '' : <h5>{slide.title}</h5>}
        {ReactHtmlParser(slide.text)}
      </span>
    </div>
  )
}
