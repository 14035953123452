import { PresencialResumeReducer } from './types'

export const initialState: PresencialResumeReducer = {
  totalClass: 0,
  totalAttend: 0,
  averageAttend: 0,
  monthLessons: new Map(),
  nextLesson: undefined,
  lessons: []
}
