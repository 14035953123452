// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
// @Dependencies
import vars from '../variables'

// @Component
const BaseInputSelection = ({ children, type, size }) => (
  <StyledBaseInputSelection type={type} size={size}>
    {children}
  </StyledBaseInputSelection>
)

export default BaseInputSelection

// @Proptypes
BaseInputSelection.propTypes = {
  children: PropTypes.object,
  size: PropTypes.string,
  type: PropTypes.string.isRequired
}

const StyledBaseInputSelection = styled.div`
  margin-top: 15px;

  .container-option {
    align-items: center;
    display: flex;
  }
  .radio,
  .checkbox {
    margin: 0;

    input {
      margin-left: 6px;
      margin-top: 3px;
      opacity: 0;
    }
    label {
      align-items: center;
      display: flex;
    }
    &:first-child {
      label {
        padding-left: 0;
      }
    }
    span {
      margin-left: 0;
      position: relative;
    }
    p {
      margin: 0;
      span {
        font-size: 0.8rem;
        font-weight: normal;
        color: #c9c9d3;
        opacity: 0.8;
      }
    }
  }
  .vertical {
    &.form-group {
      align-items: flex-start;
    }

    .container-option {
      align-items: flex-start;
      flex-direction: column;
    }
    .radio,
    .checkbox {
      :not(:last-child) {
        margin-bottom: 5px;
      }
      label {
        padding-left: 0;
      }
    }
  }

  input {
    margin: 0;
    position: absolute;

    label {
      align-items: center;
      display: flex;
      padding: 0;
      position: relative;
      top: 2px;
      &:not(:first-child) {
        padding-left: 0;
      }
    }

    &:checked + span,
    &:checked:active + span,
    &:checked:hover + span {
      ${props => colors(vars.setColors.primary)};
    }

    &:checked:disabled + span {
      ${props => colors(vars.setColors.default)};
    }

    &:hover + span {
      ${props => colors(vars.setColors.default)};
    }

    &:active + span {
      ${props => colors(vars.setColors.active)};
    }

    &:disabled + span {
      background-color: white;
      border-color: ${vars.setColors.default};
      cursor: default;
    }

    & + span {
      align-items: center;
      background-color: #fff;
      border: 2px solid ${vars.setColors.default};
      color: white;
      cursor: pointer;
      display: flex;
      float: left;
      font-family: FontAwesome;
      justify-content: center;
      margin: 0 10px;
      overflow: hidden;
      padding: 0;
      &:after {
        content: '';
      }
    }
  }

  span.inputUI {
    ${props => span(props.type, props.size, vars)};
  }
`
const colors = color => ({
  backgroundColor: color,
  borderColor: color
})

const span = (type, size, v) => {
  return {
    borderRadius: type === 'radio' ? '50%' : v.borderRadius,
    fontSize: size === 'sm' ? v.inputOptionFontSizeSm : v.inputOptionFontSize,
    height: size === 'sm' ? v.inputOptionSizeSm : v.inputOptionSize,
    width: size === 'sm' ? v.inputOptionSizeSm : v.inputOptionSize
  }
}
