import React, { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import ZendeskReact from 'react-zendesk'
import { Props, Types } from './types'

/**
 * Render de componente Zendesk.
 *
 * @param {Props} props - Props componente.
 */
export const Zendesk = ({ value }: Props) => {
  const {
    loggedUser: { user }
  } = useSelector((store: Types.Store) => store)
  const { pathname } = useLocation()

  const values: Types.ZendeskValue[] = [
    {
      id: 'name',
      prefill: {
        '*': user?.fullName?.trim()
      }
    },
    {
      id: 'email',
      prefill: {
        '*': user?.email!
      }
    }
  ]

  /** Reseteo posicion del boton */
  const resetClass = useCallback(() => {
    const zendeskButton: HTMLElement = document.getElementById(
      'launcher'
    ) as HTMLElement
    if (zendeskButton && zendeskButton.style.bottom !== '0px') {
      zendeskButton.style.bottom = '0px'
    }
  }, [])

  /** Manejo posicion del boton con respecto al viewport */
  const scrollListener = () => {
    /** Solo si estoy en las siguientes url  */
    const inVisible = ['exercise', 'resource']
    if (itemContainsList(inVisible, pathname)) {
      const footerNavigation = document.getElementsByClassName(
        'FooterNavigation'
      )
      if (footerNavigation && footerNavigation.length === 1) {
        const zendeskButton: HTMLElement = document.getElementById(
          'launcher'
        ) as HTMLElement
        /** Solo si el boton existe  */
        if (zendeskButton) {
          const scrollPosition = window.innerHeight + window.pageYOffset
          const highBody = document.body.offsetHeight
          /** capturo la posicion cuando se muestra el FooterNavigation y comienzo a mover el boton */
          if (scrollPosition + footerNavigation[0].clientHeight >= highBody) {
            const newPosition =
              highBody - scrollPosition - footerNavigation[0].clientHeight
            zendeskButton.style.bottom = `${newPosition * -1}px`
          } else {
            resetClass()
          }
        }
      }
    }
  }

  /** Funcion que valida si un string contiene al menos un items de una lista */
  function itemContainsList (list: string[], item: string) {
    let containsIt = false
    list.forEach((word: string) => {
      if (item.includes(word)) {
        containsIt = true
      }
    })
    return containsIt
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
      resetClass()
    }
  })

  return value ? (
    <ZendeskReact zendeskKey={value} contactForm={{ fields: values }} />
  ) : null
}
