// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError } from '../../../Services'
import { ConfigType } from '../../../../Config/domains/types'

import { CampusBrandingPayload } from '@eclass/api'

type Response = {
  data: {
    data: {
      CampusBranding: CampusBrandingPayload
    }
  }
}
/**
 * Obtiene el branding de un campus
 *
 * @example
 *   const { config, CampusBranding } = this.props
 *   await CampusBranding(config, this.props.ui)
 * @returns {Promise<import('@eclass/api').CampusBrandingPayload>}
 */
const getCampusBranding = (config: ConfigType) => {
  const requestId = 'CampusBranding'
  return async (dispatch: any) => {
    const query = `
      query CampusBranding($CampusBrandingInput: CampusBrandingInput!) {
        CampusBranding(input: $CampusBrandingInput) {
          CampusEmail
          CampusLoginTypes
          CampusBranding {
            id
            campusId
            text
            desktopImage
            tabletImage
            mobileImage
            loginImage
            BrandingLogos {
              id
              name
              url
            }
            Campus2 {
              name
            }
          }
          PDF
          i18nTag
          status {
            code
            name
            success
            detail
          }
        }
      }
    `
    const variables = {
      CampusBrandingInput: {
        campusId: config.campusId,
        programId: config.programId
      }
    }

    /**
     * Si el campusId del Prop de branding es distinto a la Configuración del dominio
     * entonces ejecutamos la búsqueda y guardamos en el Store de Redux los valores
     */
    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: Response) => {
        dispatch({
          type: 'SET_BRANDING',
          payload: {
            result: response.data.data.CampusBranding,
            config
          }
        })
        return response.data.data.CampusBranding
      })
      .catch((err: any) => {
        dispatch({
          type: 'SET_BRANDING',
          payload: { result: {}, config }
        })
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getCampusBranding
