// @External Dependencies
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Store } from '~app_store/types'
// @Dependencies
import { Icon, PowerByEclass } from '~ui/Icons'
import Wrapper from './Style'
import usePosition from './usePosition'

type Props = {
  inside?: boolean
}

/**
 * Componente footer
 * @example
 *  <Footer inside />
 */
export default ({ inside }: Props) => {
  const match = useRouteMatch()
  const {
    ui: { isMobile, pdfPrivacy },
    loggedUser
  } = useSelector((state: Store) => state)
  const { bottom, loading } = usePosition({ match })
  const [path, setPath] = useState(match.path)

  const isLogged = loggedUser && Object.keys(loggedUser).length > 0
  const { t } = useTranslation()

  useEffect(() => {
    setPath(match.path)
  }, [match, path])

  return (
    <Wrapper
      inside={inside}
      bottom={bottom}
      loading={loading ? 1 : undefined}
      className="Footer"
      id="Footer">
      <Container fluid={isMobile}>
        <Row>
          <Col md="12" className="flex">
            <Link to="/courses">
              <PowerByEclass />
            </Link>
            <nav className={`Footer__Nav ${isMobile ? 'Mobile' : ''}`}>
              <ul className="noStyledList flex">
                <li className={`${path === '/courses' ? 'Active_View' : ''}`}>
                  {isLogged ? (
                    <Link to="/courses">{t('DashboardCourses')}</Link>
                  ) : (
                    <Link to="/login">{t('ContentEnter')}</Link>
                  )}
                </li>
                <li>
                  <a href="https://ayuda.eclass.com">
                    {t('DashboardFAQ')}
                    <Icon
                      className="arrow-top-right"
                      name="arrow-top-right"
                      fill="#B0CFE0"
                    />
                  </a>
                </li>
                <li
                  className={`${
                    path === '/requirements' ? 'Active_View' : ''
                  }`}>
                  <Link to="/requirements">{t('DashboardTechnical')}</Link>
                </li>
                {isLogged && (
                  <li className={`${path === '/terms' ? 'Active_View' : ''}`}>
                    <Link to="/terms">{t('DashboardTC')}</Link>
                  </li>
                )}
                <li>
                  <a href={pdfPrivacy || ''}>
                    {t('DashboardPrivacy')}
                    <Icon
                      className="arrow-top-right"
                      name="arrow-top-right"
                      fill="#B0CFE0"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
