import { UIReducer } from './types'

const ImageBranding = {
  desktop: '',
  mobile: '',
  tablet: ''
}

export const InitialState: UIReducer = {
  courseWelcome: false,
  isMobile: false,
  campusId: 0,
  programId: 0,
  HELP_CENTER: undefined,
  pdfPrivacy: '',
  loginTypes: [],
  i18n: 'spa',
  i18nCourses: {},
  branding: {
    logos: [],
    login: '',
    text: '',
    day: ImageBranding,
    night: ImageBranding
  }
}
