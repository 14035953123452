import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Props } from '../types'
import * as Icons from '../icons'

const MAX_FILES = 5
/**
 * Custom plugin para el toolbar, despliega un botón que permitirá subir un archivo.
 * @example <AttachFile setAttached={fn} />
 */
export const AttachFile = ({ setAttached, total }: Props.AttachFile) => {
  const { t } = useTranslation()
  const inputFile = useRef(null)

  /** Click a la referencia del inputFile */
  const onClick = () => {
    if (total < MAX_FILES) {
      // @ts-ignore
      inputFile.current?.click()
    }
  }

  /**
   * Cuando cambia el input file mandamos el archivo al setAttached
   */
  const handleChange = (selectorFiles: FileList) => {
    if (setAttached) {
      setAttached(selectorFiles[0])
    }
  }

  return (
    <div
      className={`rdw-image-wrapper ${total >= MAX_FILES ? 'disabled' : ''}`}
      aria-label="rdw-image-control"
      onClick={onClick}>
      <div
        className="rdw-option-wrapper toolbar-option"
        title={t('ResourceAttachment')}>
        <img src={Icons.attach} alt={t('ResourceAttachment')} />
        <input
          id="AttachFile"
          type="file"
          disabled={total >= MAX_FILES}
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={(e: any) => handleChange(e.target.files)}
        />
      </div>
    </div>
  )
}
