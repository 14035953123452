import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import UIVars from '../variables'
import { Icon } from '~ui/Icons'
import { Wrapper } from './Style'
import { formatDate, dateUTC } from '~services'
import { Indicator } from '@eclass/api'

const LUDICO = 3
interface IndicatorsProps {
  indicators: Indicator[]
  showName?: boolean
  activeNavigation?: boolean
}

export const ItemIndicators = ({
  indicators,
  showName = true,
  activeNavigation = false
}: IndicatorsProps) => {
  const { t } = useTranslation()
  const isMobile = useSelector((state: any) => state.ui.isMobile)

  const date = (name: string) => {
    return `${formatDate(
      new Date(name.replace(/-/g, '/')),
      `E d MMM yyyy, HH:mm`
    )} hrs. ${dateUTC()} Santiago`
  }

  const mobileIndicator = (indicator: any) => {
    let title: string
    switch (indicator.image) {
      case 'repetition':
        title = t('ResourceQuestionsOf', {
          answered: indicator.obtained,
          total: indicator.max
        })
        break
      case 'questions':
        title = indicator.max
        break
      case 'time':
        title = indicator.max + `${t('ResourceMin')}`
        break
      case 'calendar':
        title = date(indicator.name)
        break
      default:
        title = indicator.name
    }
    return title
  }

  const desktopIndicator = (indicator: any) => {
    let title: string
    switch (indicator.image) {
      case 'calendar':
        title = date(indicator.name)
        break
      default:
        title = indicator.name
    }
    return title
  }

  const cev = (indicatorList: any) => {
    const indicatorClass = indicatorList.find(elem => elem.image === 'calendar')
      ?.image!
    return indicatorClass
  }

  return indicators && indicators.length > 0 ? (
    <Wrapper>
      <ul
        className={`Classes__Indicators ${
          indicators.length > 1 ? 'Multiple' : ''
        } ${cev(indicators)}`}>
        {indicators
          .filter(indicator => indicator.id !== LUDICO || !activeNavigation)
          .map(
            (indicator, key) =>
              indicator && (
                <li
                  key={key}
                  className={`Indicator Indicator-li ${indicator.image}`}>
                  <Icon
                    name={indicator.image}
                    fill={
                      ['stars', 'estrellas'].includes(indicator.image)
                        ? UIVars.setColors.orangeyYellow
                        : UIVars.setColors.icons
                    }
                  />
                  {showName && (
                    <span className="Indicator__Name">
                      {isMobile
                        ? mobileIndicator(indicator)
                        : desktopIndicator(indicator)}
                    </span>
                  )}
                </li>
              )
          )}
      </ul>
    </Wrapper>
  ) : null
}
