import styled from 'styled-components'
import { UIVars } from '~ui'

type Props = {
  bottom: boolean
  inside: boolean | undefined
  loading: any
}

export default styled.footer<Props>`
  background: white;
  border-top: 1px solid ${UIVars.setColors.veryLightPinkTwo};
  bottom: 0;
  left: 0;
  margin-top: ${({ inside }: Props) => (inside ? '80px' : 0)};
  padding: 20px 0 50px;
  position: ${({ bottom }: Props) => (bottom ? 'absolute' : 'relative')};
  width: 100%;
  opacity: 0;
  ${({ loading }: any) => {
    if (!loading) {
      return {
        animation: 'entry .3s linear forwards'
      }
    } else {
      return null
    }
  }}

  @keyframes entry {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .Footer__Nav {
    align-self: end;

    svg {
      margin-right: 0;
      margin-top: 2px;
      margin-left: 5px;
    }
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &::after {
        content: '';
        background-color: ${UIVars.setColors.whiteThree};
        width: 23px;
        height: 3px;
      }

      a {
        color: ${UIVars.setColors.brownThree};
        font-size: 12px;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.3s;

        &:hover {
          color: ${UIVars.setColors.primary};
        }
      }

      &.Active_View {
        a {
          color: ${UIVars.setColors.deepSkyBlue};
        }
        &::after {
          background-color: ${UIVars.setColors.deepSkyBlue};
        }
      }
    }

    &.Mobile {
      align-self: center;
      li {
        margin: 0;
        &:not(:last-child) {
          margin: 0 0 30px 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .flex.col-md-12 {
      flex-direction: column;
      justify-content: center;
    }
    .Footer__Nav {
      margin-top: 20px;
      align-self: center;
    }
  }

  @media screen and (max-width: 992px) {
    .Footer__Nav.Mobile {
      li {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 0;
    text-align: center;

    .logo__PowerByEclass {
      margin-bottom: 30px;
    }
    .Footer__Nav {
      &.Mobile {
        margin-top: 0;
        li {
          &:not(:last-child) {
            margin-bottom: 30px;
            margin-right: 0;
          }
        }
      }
      ul {
        display: block;
        li {
          text-align: center;
        }

        a {
          padding-left: 0;
        }
      }
    }
  }
`
