const Modal = `
  .modal {
    .modal-dialog {
      .modal-content {
        max-width: 95%;
        margin: 20px auto 0 auto;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .modal-header{
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    border-radius: 0px;
    .modal {
      border-radius:0px;
      .modal-dialog {
        .modal-content {
          max-width: 100%;
          margin: 0px auto 0 auto;
          overflow-y: auto;
          border-radius: 0px;
        }
      }
    }
  }
  @media screen and (max-width: 767px){
    .modal {
    .modal-dialog {
      margin: 0 auto;
      .modal-content {
        margin: 0 auto 0 auto;
        max-width: 100%
      }
    }
  }
  }
`

export default Modal
