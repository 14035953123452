// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Lista los compañeros de un curso
 *
 * Pagina y filtra por un parámetro de búsqueda.
 *
 * @param {import('@eclass/api').CourseClassmateInput} input - Filtros para el listado de compañeros
 * @returns {Promise<import('@eclass/api').CourseClassmatePayload>}
 */
const getClassmatesList = input => {
  const requestId = 'getClassmatesList'
  return async dispatch => {
    getToken()
    const query = `
      query getCourseClassmates($CourseClassmateInput: CourseClassmateInput!) {
        getCourseClassmates (input: $CourseClassmateInput){
          status {
            success
            detail
          }
          Classmates{
            personId
            courseId
            Person {
              id
              name
              firstSurname
              lastSurname
              fullName
              birthday
              birthdayPrivate
              email
              emailPrivate
              picture
              professionalResume
              Phone2s (
                filter: {
                  private: 0
                }
              ){
                PhoneType2 {
                  name
                }
                countryCode
                areaCode
                number
                private
              }
              Address2s (
                filter: {
                  private: 0
                }
              ) {
                id
                Country {
                  name
                }
                Region2 {
                  name
                }
              }
              Online2s {
                id
              }
              Jobs(
                filter: {
                  private: 0
                }
                orderBy: current_DESC
              ) {
                id
                name
                position
                ExecutivePosition {
                  name
                }
                place
                current
              }
            }
          }
          pages
          totalClassmates
        }
      }
    `
    const variables = {
      CourseClassmateInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.getCourseClassmates)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getClassmatesList
