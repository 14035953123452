import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Wrapper from './style'

type Props = {
  id?: string
}

export const ReadingProgress = ({ id = 'body' }: Props) => {
  const { pathname } = useLocation()
  const showProgress = pathname.includes('resource/view')
  const [readingProgress, setReadingProgress] = useState(0)

  /** Función que se ejecuta cada vez que se hace scroll en la página. */
  const scrollListener = () => {
    const element = document.getElementById(id)
    let totalHeight = 0

    if (element) {
      totalHeight =
        element.clientHeight + element.offsetTop - window.innerHeight
    } else {
      totalHeight = 0
    }

    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    const progress = (windowScrollTop / totalHeight) * 100
    const progressRound = Math.round(progress * 100) / 100

    if (windowScrollTop) {
      if (windowScrollTop === 0) {
        return setReadingProgress(0)
      }

      if (windowScrollTop > totalHeight) {
        return setReadingProgress(100)
      }
    }

    setReadingProgress(progressRound)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  })

  return showProgress ? (
    <Wrapper
      className="reading-progress-bar"
      style={{ width: `${readingProgress}%` }}
    />
  ) : null
}
