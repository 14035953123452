// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'
import { ResourceCommentsInput } from '@eclass/api'

/**
 * Busca comentarios.
 *
 * @param {import('@eclass/api').ResourceCommentsInput} input - Parámetros para buscar los comentarios.
 * @returns {Promise<import('@eclass/api').ResourceCommentsPayload>}
 */
const getResourceComments = (input: ResourceCommentsInput) => {
  const requestId = 'getResourceComments'
  return async (dispatch: any) => {
    getToken()

    const comment = `
      id
      text
      created
      studentId
      parentId
      draft
      Student {
        id
        personId
        Person {
          id
          fullName
          picture
        }
        Profile {
          id
          name
        }
      }
      ResourceCommentAttacheds {
        id
        name
        extension
        path
      }
    `

    const query = `
       query getResourceComments($ResourceCommentsInput: ResourceCommentsInput!) {
        getResourceComments(input: $ResourceCommentsInput) {
          status {
            name
            success
            code
            detail
          }
          total
          pages
          DraftComment {
            ${comment}
          }
          Comments {
            ${comment}
            Childrens {
              ${comment}
            }
          }
        }
      }
    `

    const variables = {
      ResourceCommentsInput: input
    }
    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => response.data.data.getResourceComments)
      .catch((err: any) => axiosError(err, requestId, dispatch))
  }
}

export default getResourceComments
