import React from 'react'
import { useHistory } from 'react-router-dom'

import { Props } from './types'
import {
  AppliedQuestion,
  // ComplementaryMaterial,
  Evaluation,
  Exercise,
  MainMaterial,
  Poll
} from '~ui/Icons'

export const HandleIcon = ({ type }: Props.HandleIcon) => {
  const {
    location: { pathname }
  } = useHistory()

  switch (type) {
    case 'isResourse':
      return pathname.includes('resource') ? null : <MainMaterial />
    case 'isSetExercise':
      return <Exercise />
    case 'isPoll':
      return <Poll />
    case 'isApplied':
      return <AppliedQuestion />
    case 'isEvaluation':
      return <Evaluation />
    default:
      return null
  }
}
