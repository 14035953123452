import React from 'react'

export const Question = () => (
  <svg
    role="IconQuestionApplied"
    className="Question"
    xmlnsXlink="http://www.w3.org/1999/svg"
    width={42}
    height={43}
    viewBox="0 0 42 43">
    <g fill="none" fillRule="evenodd">
      <circle fill="#CDE8FF" id="a" cx="20.664" cy="20.459" r="20.459" />
      <g>
        <circle
          cx="15.076"
          cy="16.141"
          r="5.359"
          stroke="#60798E"
          strokeWidth="2"
        />
        <path
          stroke="#60798E"
          strokeLinecap="round"
          strokeWidth="2"
          d="M28.802 35.474c-1.625-6.335-7.373-11.017-14.215-11.017-2.741 0-5.307.751-7.502 2.06"
        />
        <g stroke="#60798E" strokeWidth="2" transform="translate(23.391 11.74)">
          <circle cx="7.826" cy="4.891" r="3.891" />
          <path
            strokeLinecap="round"
            d="M13.638 14.255a11.685 11.685 0 0 0-5.812-1.538 11.69 11.69 0 0 0-7.289 2.537"
          />
        </g>
        <path
          stroke="#60798E"
          strokeWidth="2"
          d="M25 9.19l2.909-1.328h7.787a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H26a1 1 0 0 0-1 1v7.19z"
        />
        <path
          fill="#0189FF"
          d="M11.696 40.41H2a2 2 0 0 1-2-2v-4.862a2 2 0 0 1 2-2h7.57l3.268-1.493a.606.606 0 0 1 .858.55v7.805a2 2 0 0 1-2 2z"
        />
      </g>
    </g>
  </svg>
)
