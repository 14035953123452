// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'font-awesome/css/font-awesome.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { loadReCaptcha } from 'react-recaptcha-google'
import i18n from './app/I18n'

// @Dependencies locales
import * as Actions from './app/Redux/Actions'
import GlobalStyle from './app/Components/UI/Styles'
import './assets/css/nucleo.css'
import { RequestError } from '~components'
import { logRocket } from '~app/Config/logRocket'

// Integración Google Analytics
import ReactGA from 'react-ga'

// Integración Sentry
import * as Sentry from '@sentry/browser'

// @Component
class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      eventId: null
    }
    if (process.env.NODE_ENV === 'production') {
      // Sentry
      Sentry.init({
        dsn: 'https://d9dc12575ec648a489465e2587160f70@sentry.io/1249304',
        ignoreErrors: [
          /** Error ignorado por recomendación del desarrollador. */
          'ResizeObserver',
          /** En la práctica no es un error válido ya que al desmontarse un componente se llama a la referencia de otro componente antes que se desmonte y se haga el redirect con history.push(...) */
          'ChunkLoadError',
          /** Errores ignorados ya que son de respuesta del servidor que deben ser optimizados.  */
          'Network Error',
          'AbortError'
        ],
        environment: process.env.NODE_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE
      })
      Sentry.configureScope(scope => {
        scope.setUser(this.props.loggedUser)
        scope.setExtras({
          course: this.props.courseInfo.Course
            ? this.props.courseInfo.Course
            : {}
        })
      })
      // LogRocket
      if (this.props.loggedUser.user && this.props.loggedUser.token) {
        logRocket({
          id: this.props.loggedUser.user.id,
          name: this.props.loggedUser.user.fullName,
          email: this.props.loggedUser.user.email,
          token: this.props.loggedUser.token
        })
      }
    }
  }

  componentDidCatch (error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      this.setState({ error })
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  componentDidMount () {
    const { setMediaquery, history, setRequestError, requestError } = this.props
    loadReCaptcha()

    // media query change
    const WidthChange = mq => {
      setMediaquery(mq.matches)
    }

    // media query event handler
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 990px)')
      mq.addListener(WidthChange)
      WidthChange(mq)
    }

    if (history.action === 'POP' && requestError.code > 0) {
      setRequestError()
    }
    i18n.changeLanguage(this.props.i18nTag)
  }

  componentDidUpdate () {
    i18n.changeLanguage(this.props.i18nTag)
  }

  render () {
    const { children, className, requestError } = this.props

    if (requestError.code === 500) {
      return <RequestError.Error500 status={requestError} />
    }

    return (
      <>
        <GlobalStyle />
        <section className={className}>{children}</section>
      </>
    )
  }
}

/**
 * Filtramos las URL para Analytics quitando todos los numericos de la URL
 * Formato:
 * ```js
 *   /helper/123123/123123 -> /helper/
 *   /units/12312312/12313 -> /units/
 *   /resource/view/1/2/3/4 -> /resource/view/
 * ```
 */
const analyticsUrl = window.location.pathname
  .split('/')
  .filter(part => part.length > 0)
  .map(part => {
    if (!isNaN(Number(part))) {
      return ''
    }
    return part
  })
  .join('/')
  .replace('//', '')

if (analyticsUrl.length) {
  ReactGA.initialize('UA-26621430-9')
  ReactGA.pageview(analyticsUrl)
}

// @Proptypes
App.propTypes = {
  children: PropTypes.element
}

const mapStateToProps = state => ({
  courseInfo: state.courses.getCourseInfo,
  loggedUser: state.loggedUser,
  requestError: state.requestError,
  i18nTag: state.ui.i18nTag
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMediaquery: Actions.setMediaquery,
      setRequestError: Actions.requestError
    },
    dispatch
  )

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

// @Styles
export default styled(withRouter(ConnectedApp))`
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
`
