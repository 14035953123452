import React from 'react'
import { useTranslation } from 'react-i18next'

import { MyAvatar } from '../MyAvatar'
import { Props, Types } from '../types'
import { TextLink } from '../'

import { useAvatars } from './useAvatars'
import * as Style from './style'

/**
 * Componente que se encarga de dibujar lo avatares
 * y de mostrar el estado de la visualización de las repuestas
 * @example
 * <Footer
 *  answers={answers}
 *  setShow={setShow}
 *  show={show}
 *  showClass={showClass}
 * />
 */
export const Footer = ({ answers, setShow, show, showClass }: Props.Footer) => {
  const { t } = useTranslation()
  const { listAvatars } = useAvatars(answers)

  const statusText = show ? t('QuestionHide1') : t('QuestionView1')

  return (
    <Style.Footer role="AnswersFooter" className={showClass}>
      <div onClick={() => setShow()}>
        {!show && (
          <Style.ListAvatar>
            {listAvatars.map((answer: Types.DataUser, key: number) => {
              return <MyAvatar key={key} user={answer} small />
            })}
          </Style.ListAvatar>
        )}
        <TextLink>
          {`${statusText} ${answers.length} ${t('QuestionNAnswers')}${
            answers.length > 1 ? 's' : ''
          }`}
        </TextLink>
      </div>
    </Style.Footer>
  )
}
