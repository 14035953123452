// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// @Dependencies
import BaseInputSelection from './BaseInputSelection'
import Info from './Info'
import Label from './Label'

// @Component
class Checkbox extends Component {
  state = {
    checkedList: []
  }

  static getDerivedStateFromProps (nextProps) {
    if (nextProps.selected) {
      const checkedList = nextProps.options.map(option => {
        nextProps.selected.forEach(item => {
          if (item.id === option.id) {
            option.checked = true
          }
        })
        if (option.checked === undefined) {
          option.checked = false
        }
        return checkedList
      })
    }
    return {
      checkedList: nextProps.options
    }
  }

  handleChange = e => {
    const { checkedList } = this.state

    let value = e.target.value

    const newlist = checkedList.map(item => {
      const typeId = typeof item.id
      value = typeId === 'number' ? Number(value) : value
      if (value === item.id) {
        item.checked = e.target.checked
      }
      return item
    })

    if (this.props.onChange) {
      this.props.onChange(e, newlist)
    }
  }

  render () {
    const { checkedList } = this.state
    const {
      info,
      label,
      disabled,
      message,
      name,
      required,
      size,
      vertical
    } = this.props

    return (
      <BaseInputSelection type="checkbox" size={size}>
        <div className={`form-group row ${vertical && 'vertical'}`}>
          {label && <Label value={label} required={required} />}
          <div className={`col-sm-12 col-xs-12 container-option`}>
            {checkedList.map(input => (
              <div key={input.id} className="checkbox">
                <label>
                  <input
                    defaultChecked={input.checked}
                    name={name}
                    onChange={this.handleChange}
                    disabled={disabled}
                    type="checkbox"
                    value={input.id}
                  />
                  <span className="inputUI" />
                  <p>
                    {input.name} {message && <span>({input.id})</span>}
                  </p>
                </label>
              </div>
            ))}
          </div>
          {info && <Info value={info} label={label} />}
        </div>
      </BaseInputSelection>
    )
  }
}

// @Proptypes
Checkbox.propTypes = {
  info: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  selected: PropTypes.array,
  vertical: PropTypes.bool
}

// @Export Component
export default Checkbox
