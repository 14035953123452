import React from 'react'
import styled from 'styled-components'
import { UIVars } from '~ui'

import { Icon } from '~ui/Icons'
import handleHide from './helpers/handleHide'
import getIcon from './helpers/getIcon'
import { FlashProps } from './helpers/types'

export const Flash = ({
  auto,
  children = 'Texto para mensaje flash. Lorem ipsum dolor sit amet',
  time = 100,
  timeOut = 5000,
  type = 'success'
}: FlashProps) => {
  const alert = document.getElementById('alert')

  if (auto && alert && alert.classList.contains('visible')) {
    setTimeout(function () {
      handleHide(alert, time)
    }, timeOut)
  }

  return (
    <Wrapper>
      <div id="alert" className={`${type} alert visible`}>
        <img className="image" src={getIcon(type)} alt=" " />
        <div className="message">{children}</div>
        <Icon
          onClick={() => handleHide(alert, time)}
          className="close"
          name="close"
          fill="#566D82"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .alert {
    align-items: center;
    border-radius: 10px;
    box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.35);
    display: flex;
    max-width: 920px;
    padding: 10px 10px 10px 20px;
    position: fixed;
    top: 20px;
    transition: all 0.3s linear;
    width: 100%;
    z-index: 99;

    &.visible {
      display: flex;
    }
    &.visuallyenable {
      opacity: 1;
      top: 20px;
    }
    &.visuallyhidden {
      opacity: 0;
      top: -20px;
    }
    &.hidden {
      display: none;
    }

    .image {
      height: 48px;
      margin-right: 15px;
    }
    .message {
      flex-grow: 2;
      font-size: 14px;
      line-height: 1.29;
    }
    .close {
      align-self: start;
      cursor: pointer;
      margin: 0;
    }
    &.success {
      background-color: ${UIVars.setColors.success};
    }
    &.info {
      background-color: ${UIVars.setColors.info};
    }
    &.warning {
      background-color: ${UIVars.setColors.warning};
    }
    &.error {
      background-color: ${UIVars.setColors.danger};
    }
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    .alert {
      width: 90%;
    }
  }
`
