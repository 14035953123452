import {
  flashDanger,
  flashInfo,
  flashSuccess,
  flashWarning,
  tinyInfo
} from '~assets/svg'
import { alertType } from './types'

const getIcon = (type: alertType) => {
  const types = new Map([
    ['success', flashSuccess],
    ['error', flashDanger],
    ['warning', flashWarning],
    ['info', flashInfo],
    ['tinyInfo', tinyInfo]
  ])

  return types.has(type) ? types.get(type) : flashSuccess
}

export default getIcon
