import React from 'react'

export const MeetEclassIcon = () => (
  <svg width={60} height={48} data-testid="MeetEclassIcon">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="24.642" cy="23.167" r="21.642" fill="#F9DFDF" />
      <circle cx="24.5" cy="6.5" r="6.5" stroke="gray" strokeWidth={2} />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M26.11 44.147L15 44V24.143C15 19.646 19.03 16 24 16h1c3.75 0 6.966 2.076 8.318 5.027"
      />
      <circle cx={42} cy={12} r={5} stroke="gray" strokeWidth={2} />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth={2}
        d="M37.66 20.805A7.663 7.663 0 0 1 41.104 20h.79c3.069 0 5.683 1.76 6.677 4.226"
      />
      <circle
        cx={7}
        cy={14}
        r={5}
        stroke="gray"
        strokeWidth={2}
        transform="matrix(-1 0 0 1 14 0)"
      />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth={2}
        d="M11.34 22.805A7.663 7.663 0 0 0 7.896 22h-.79C3.181 22 0 24.878 0 28.429V37"
      />
      <path
        fill="#D32A2A"
        fillRule="nonzero"
        d="M55.607 25.204c.247.19.393.483.393.795v17a1 1 0 0 1-.737.965l-11 3a1.022 1.022 0 0 1-.526 0l-11-3A1 1 0 0 1 32 43V26c0-.312.146-.605.393-.795.248-.189.569-.25.87-.17L44 27.962l10.737-2.928a1.01 1.01 0 0 1 .87.17zM41.93 41.547l.526-1.93L36.526 38 36 39.93l5.93 1.617zm0-7l.526-1.93L36.526 31 36 32.93l5.93 1.617zm4.596 7l5.93-1.617L51.93 38 46 39.617l.526 1.93zm0-7l5.93-1.617L51.93 31 46 32.617l.526 1.93z"
      />
    </g>
  </svg>
)
