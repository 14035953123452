import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '~ui'

import { ShowMore as Style } from './style'
import { Types } from './types'

export const ShowMoreButton = ({
  totalPages,
  limit,
  page,
  loading,
  totalComments,
  moreResults
}: Types.ShowMore) => {
  const { t } = useTranslation()
  const [displayStatus, setDisplayStatus] = useState('Showing')
  const [lastLoading, setLastLoading] = useState(0)

  let total = 0
  if (totalPages * limit === totalComments) {
    total = limit
  } else {
    if (totalPages - 2 > page) {
      total = limit
    } else if (totalPages - 2 === page) {
      total = totalComments - (totalPages - 1) * limit
    } else if (totalPages - 2 < page && page > 0) {
      if (loading === true) {
      }
    }
  }

  useEffect(() => {
    if (limit > totalComments) {
      setDisplayStatus('Gone')
    } else if (limit < totalComments) {
      setDisplayStatus('Showing')
    }
  }, [limit, totalComments])
  useEffect(() => {
    if (totalPages - 2 < page && page > 0) {
      if (loading === true) {
        setLastLoading(lastLoading => lastLoading + 1)
      }
    }
  }, [loading, page, totalPages])

  useEffect(() => {
    if (lastLoading !== 0 && loading === false) {
      setDisplayStatus('Gone')
    }
  }, [lastLoading, loading])

  return (
    <Style.Wrapper role="ShowMoreButton">
      <Button
        className={displayStatus}
        text={t('ResourceViewMore', {
          context: total > 1 ? 'plural' : '',
          total
        })}
        loading={loading}
        onClick={moreResults}
      />
    </Style.Wrapper>
  )
}
