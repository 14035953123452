// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '~services'
import { QuestionViewInput, QuestionViewPayload } from '@eclass/api'

/**
 * Obtengo toda la información de la pregunta
 * @returns {Promise<QuestionViewPayload>}
 */
const getQuestion = (input: QuestionViewInput) => {
  const requestId = 'getQuestionView'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getQuestionView($QuestionViewInput: QuestionViewInput!) {
        getQuestionView(input: $QuestionViewInput) {
          status {
            success
            detail
            code
            name
          }
          Question{
            text
            title
            public
            created
            isAnswered
            Student{
              Person{
                id
                fullName
                picture
              }
            }
          }
          QuestionAnswers {
            id
            text
            parentId
            created
            viewedAt
            Childrens {
              id
              text
              created
              viewedAt
              Student {
                profileId
                Person {
                  fullName
                  picture
                  id
                }
              }
            }
            Student {
              profileId
              Person {
                fullName
                picture
                id
              }
            }
          }
          total
          pages
        }
      }
    `

    const variables = {
      QuestionViewInput: input
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const payload: QuestionViewPayload = response.data.data.getQuestionView
        return payload
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getQuestion
