import React from 'react'

export const AppliedQuestion = () => (
  <svg
    role="iconAppliedQuestion"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="24.5"
        cy="24.5"
        r="24.5"
        fill="#E0EEFA"
        fillRule="nonzero"></circle>
      <rect
        width="15"
        height="8"
        x="12"
        y="30"
        stroke="#60798E"
        strokeWidth="2"
        rx="1"></rect>
      <path
        fill="#0189FF"
        fillRule="nonzero"
        d="M32.636 27h14.728c.903 0 1.636.738 1.636 1.648v8.627c0 .91-.733 1.648-1.636 1.648h-10.94l-4.282 2.002A.801.801 0 0131 40.19V28.648c0-.91.733-1.648 1.636-1.648z"></path>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25.276 44.143H8.952A2.958 2.958 0 016 41.18V7.106a2.958 2.958 0 012.952-2.963h25.096A2.958 2.958 0 0137 7.106V22.81M12 12h19m-19 6h19m-19 6h7"></path>
    </g>
  </svg>
)
