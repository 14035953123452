import styled from 'styled-components'
import UIVars from '../variables'

export const Wrapper = styled.div`
  .Classes__Indicators {
    margin-top: 3px;
    display: flex;
    list-style: none;
    padding: 0px;
    font-size: 12px;
    margin-bottom: 0px;
    color: ${UIVars.setColors.brownThree};
    &.calendar {
      flex-direction: column;
      .Indicator-li {
        &.calendar {
          border-right: none !important;
          margin-bottom: 5px;
          padding-right: 0px !important;
        }
        &.time,
        &.map-indicator {
          padding-left: 0px !important;
          justify-content: flex-start;
        }
        &.remote {
          padding-left: 0px !important;
          justify-content: flex-start;
        }
        &.remote {
          padding-left: 0px !important;
          justify-content: flex-start;
        }
      }
    }
    .Indicator__Name.Material {
      display: none;
    }

    .Indicator-li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: none !important;
      svg {
        margin-right: 8px;
      }
    }

    &.Multiple {
      .Indicator {
        &:not(:first-child) {
          padding-left: 10px;
        }
        &:not(:last-child) {
          border-right: 1px solid ${UIVars.setColors.veryLightPinkTwo};
          padding-right: 10px;
        }
      }
    }
  }
`
