import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { formatDate, useLocale } from '~services'

import { UIVars } from '~ui'

type PropsInfo = {
  date: string | Date
}

export const LabelInfo = ({ date }: PropsInfo) => {
  const { t } = useTranslation()
  const { Locale } = useLocale()

  const available = `${t('ContentOpens')} ${formatDate(
    new Date(date),
    ' d MMM yyyy ',
    Locale
  )}`

  return (
    <Wrapper className="DataLabel__Indicator">
      <span className="arrow_text">{available}</span>
    </Wrapper>
  )
}

// @Proptypes
LabelInfo.propTypes = {
  date: PropTypes.string.isRequired
}

const Wrapper = styled.div`
  display: inline;
  padding: 3px 10px 3px 2px;
  margin-left: 10px;

  .arrow_text {
    background: ${UIVars.setColors.whiteTwo};
    border-radius: 0 3px 3px 0;
    font-size: 12px;
    padding: 4px 10px 2px 2px;
  }

  .arrow_text:before {
    content: '';
    position: relative;
    left: -12px;
    top: 5px;
    bottom: 0;
    width: 0;
    height: 0;
    display: inline-block;
    border-right: 10px solid ${UIVars.setColors.whiteTwo};
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
`
