// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} InputType
 * @property {number} studentId - id del Estudiante con el que se buscarán los datos del Curso
 * @property {number} courseId - id del Curso
 */
/**
 * @typedef {Object} Payload
 * @property {string} status - Estado de la petición.
 * @property {Object} Course - Datos del Curso.
 * @property {Object} Welcome - Información bienvenida del curso.
 */

/**
 * Busca la información del curso.
 * Retorna una promesa del `Payload` y ejecuta el `dispatch` para setear en la aplicación los datos del curso
 * @param {import('@eclass/api').CourseInfoInput} input
 * @example
 * ```js
 *   const response = await getCourseInfo({ studentId, courseId })
 * ```
 * @returns {Promise<import('@eclass/api').CourseInfoPayload>}
 */
const getCourseInfo = input => {
  const requestId = 'getCourseInfo'
  return async dispatch => {
    getToken()
    const query = `
      query getCourseInfo($CourseInfoInput: CourseInfoInput!) {
        getCourseInfo(input: $CourseInfoInput) {
          status {
            success
            detail
          }
          Terms {
            id
            text
          }
          Course {
            id
            studentId
            campusId
            name
            progress
            href
            revisedWelcome
            haveQuestions
            navigationId
            remaining
            programId
            i18nTag
            activeUnit {
              id
              title
            }
            Product {
              name
              brandingLogo
            }
            Language {
              id
              name
            }
          }
          Welcome {
            about
            objetives
            videos
            photo
            Documents {
              name
              href
            }
            Contacts {
              tag
              photo
              fullName
              email
              phone
              whatsapp
            }
            AcademicTeam {
              id
              Person {
                picture
                fullName
                professionalResume
              }
              Profile {
                name
              }
            }
          }
          footerBoxes {
            type
            title
            content
            photo
            fullName
            email
            phone
            whatsapp
            href
            links {
              name
              href
              status {
                enabled
              }
              type {
                id
                name
              }
            }
          }
          Sence {
            urls {
              name
              href
              status {
                id
                name
                enabled
                detail
              }
            }
            RutOtec
            Token
            CodSence
            CodigoCurso
            LineaCapacitacion
            RunAlumno
            IdSesionAlumno
            needLogin
          }
          Student {
            courseId
            profileId
            Profile {
              id
              name
            }
            dates {
              start
              end
              deactivation
              canGenerateActivity
            }
          }
        }
      }
    `

    const variables = {
      CourseInfoInput: {
        studentId: Number(input.studentId),
        courseId: Number(input.courseId)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        const result = response.data.data.getCourseInfo
        dispatch({
          type: 'SET_I18N',
          payload: result.Course.i18nTag
        })
        if (!result) {
          return {
            status: { success: false },
            progress: null,
            activeUnit: null,
            navigation: null
          }
        }
        if (result.status.success) {
          // Guarda en redux la info del curso.
          dispatch({
            type: 'GET_COURSE_INFO',
            payload: response.data.data.getCourseInfo
          })

          // Guarda en redux ui el idioma.
          dispatch({
            type: 'SET_I18N_COURSE',
            payload: {
              courseId: Number(input.courseId),
              i18n: result.Course.i18nTag
            }
          })
        }
        return {
          status: result.status,
          progress: result.Course ? result.Course.progress : null,
          activeUnit: result.Course ? result.Course.activeUnit : null,
          navigation: result.Course ? result.Course.navigationId : null,
          Sence: result.Sence ? result.Sence : null,
          Course: result.Course ? result.Course : null
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getCourseInfo
