// Types
import { UnitBlock, UnitBlockItemLesson } from '@eclass/api'
import { formatDate } from '~services'

import { Types } from '~views/Units/WithUnit/PresencialResume/types'

const STATUS_PENDING = 0
const STATUS_ATTEND = 1

/**
 * Hook para calcular las clases presenciales y guardarlas en un objeto ordenadas por
 * - Mes -> Lessons[]
 *
 */
export const useLessons = (units: UnitBlock[]): Types.UseLessonsProps => {
  /**
   * Reduce all lessons and sort by Date.
   */
  const lessons = units
    ?.reduce<Types.BlockLessonResume[]>((acc, unit) => {
      const { ResourceTypeId, transversal, lessons: childLessons } = unit
      /**
       * Collect transversal lessons
       */
      if (ResourceTypeId === null && transversal) {
        const date = parseLessonDate(unit)
        acc.push({
          id: unit.id,
          name: unit.name,
          href: unit.href,
          label: unit!.status!,
          indicators: unit!.indicators!,
          date: new Date(date),
          dateString: date
        })
      }

      /**
       * Collect children lessons
       */
      if (childLessons && childLessons.length > 0) {
        childLessons.forEach(lesson => {
          const date = parseLessonDate(lesson)
          const partial = {
            ...lesson,
            date: new Date(date),
            dateString: date
          }
          acc.push(partial)
        })
      }

      return acc
    }, [])
    .sort((a, b) => {
      /**
       * Ordena las clases por fecha ASC
       */
      return a.date.getTime() - b.date.getTime()
    })

  if (!lessons) {
    return {
      totalClass: 0
    }
  }

  /** Get next lesson `Pending` that is after now. */
  const nextLesson = lessons.find(
    ({ label, date }) => label?.id === STATUS_PENDING && date >= new Date()
  )

  /** Calc total attend. */
  const totalAttend = lessons.reduce(
    (acc, { label }) => acc + (label?.id === STATUS_ATTEND ? 1 : 0),
    0
  )

  /** Calc average attend. */
  const lessonsTaught = lessons.reduce(
    (acc, { label }) => acc + (label?.id !== STATUS_PENDING ? 1 : 0),
    0
  )
  const averageAttend = Math.round((totalAttend / lessonsTaught) * 100)

  return {
    totalClass: lessons.length,
    totalAttend,
    averageAttend: isNaN(averageAttend) ? 0 : averageAttend,
    monthLessons: parseLessonMonth(lessons),
    nextLesson,
    lessons
  }
}

/**
 * Busca en los Indicadores del elemento si hay un indice que sea "calendar"
 * retorna el nombre del indicador (valor que viene desde el API).
 */
const parseLessonDate = ({ indicators }: UnitBlock | UnitBlockItemLesson) => {
  return indicators?.find(({ image }) => image === 'calendar')?.name!
}

/**
 * Agrupa las clases presenciales por mes.
 */
const parseLessonMonth = (lessons: Types.BlockLessonResume[]) => {
  const monthLessons: Map<string, Types.BlockLessonResume[]> = new Map()

  lessons.forEach(lesson => {
    // sumamos un día más para no tener problema con el date-fns y la zona horaria.
    const month = `${formatDate(lesson.date, 'yyyy-MM')}-02`
    const mapUpdate = !monthLessons.has(month)
      ? [lesson]
      : [...monthLessons.get(month)!, lesson]

    monthLessons.set(month, mapUpdate)
  })
  return monthLessons
}

export default useLessons
