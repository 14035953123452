import styled from 'styled-components'

import UIVars from '../../variables'

export const Wrapper = styled.section`
  .editor-info {
    display: block;
    position: relative;
    top: -23px;
    left: -17px;
    text-align: right;
    font-size: 10px;
    &.hide {
      opacity: 0;
    }
  }
  .rdw-editor-wrapper {
    background-color: ${UIVars.setColors.whiteThree};
    border-radius: 10px;
    border: 1px solid ${UIVars.setColors.veryLightPink};
    .rdw-editor-main {
      word-break: break-word;
      &.tall {
        height: 350px;
      }
    }
    .rdw-editor-toolbar {
      border-bottom: 1px solid ${UIVars.setColors.veryLightPink};
      border-radius: 10px 10px 0 0;
      margin-bottom: 0;
      padding: 15px 0 20px 15px;
      visibility: visible;
      & > div {
        margin: 0;
        .rdw-image-modal-header,
        .rdw-image-modal-size,
        .rdw-link-modal-target-option {
          display: none;
        }
        &.disabled {
          opacity: 0.3;
        }
        .toolbar-option {
          border-radius: 5px;
          border: 1px solid ${UIVars.setColors.whiteThree};
          height: 30px;
          margin: 0 8px 0 0;
          width: 30px;
          &:hover {
            border: 1px solid ${UIVars.setColors.deepSkyBlue};
          }
          &.rdw-option-active {
            border: none;
            box-shadow: none;
            background-color: ${UIVars.setColors.icons};
            &:hover {
              border: 1px solid ${UIVars.setColors.deepSkyBlue};
            }
          }
        }
      }
    }
    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      min-height: 142px;
      padding: 20px;
    }
    .DraftEditor-editorContainer
      .public-DraftEditor-content
      > div
      > :last-child {
      margin: 0px;
      padding: 0px 0px 35px;
    }
    .public-DraftEditorPlaceholder-root {
      margin-left: 20px;
    }
  }
`
