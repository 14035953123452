// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { getToken, axiosError } from '../../../Services'

/**
 * Busca todos los niveles de estudio y los ordena por nombre ascendente
 *
 * @example
 * ```js
 *    const response = await getStudyLevels()
 * ```
 * @returns Retorna una Lista con los Niveles de estudio (id, name)
 */
const getStudyLevels = () => {
  const requestId = 'getStudyLevels'
  return async dispatch => {
    getToken()

    const query = `
      query allStudyLevels{
        allStudyLevels (
          orderBy: sortOrder_ASC
        ){
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const { allStudyLevels } = response.data.data
        if (response.status === 200 && allStudyLevels) {
          return {
            data: allStudyLevels,
            status: {
              success: true,
              detail: 'Petición exitosa'
            }
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar los niveles de estudio.'
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getStudyLevels
