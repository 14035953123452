import React from 'react'

export const PlatformIcon = () => (
  <svg width={60} height={48}>
    <g fill="none" fillRule="evenodd">
      <circle cx="21.642" cy="24.405" r="21.642" fill="#F9DFDF" />
      <path
        stroke="gray"
        strokeLinecap="square"
        strokeWidth={2}
        d="M12.203 37.397v6.24m18.305-6.24v6.24"
      />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth={2}
        d="M9.915 43.95h22.882"
      />
      <path
        fill="gray"
        d="M6.102 18.017h4.576v4.576H6.102zm0 7.627h4.576v4.576H6.102z"
      />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth={2}
        d="M33.598 7.339h6.826c.842 0 1.525.683 1.525 1.525v11.804m-8.397 16.417H2.288a1.525 1.525 0 0 1-1.525-1.526V8.864c0-.842.683-1.525 1.525-1.525h10.1m2.485 12.966h16.78m-16.78 7.627h16.78"
      />
      <path
        fill="#C00"
        fillRule="nonzero"
        d="M57.203 33.508h-1.067a9.156 9.156 0 0 0-.992-2.364l.763-.763c.915-.915.915-2.364 0-3.203-.84-.839-2.365-.839-3.204 0l-.762.763a9.156 9.156 0 0 0-2.365-.992v-1.068c0-1.296-.991-2.288-2.288-2.288-1.296 0-2.288.992-2.288 2.288v1.068a9.156 9.156 0 0 0-2.364.992l-.763-.763c-.84-.839-2.365-.839-3.204 0-.915.915-.915 2.364 0 3.203l.763.763a9.156 9.156 0 0 0-.991 2.364h-1.068c-1.297 0-2.288.992-2.288 2.289 0 1.296.991 2.288 2.288 2.288h1.068c.228.839.534 1.601.991 2.364l-.763.763c-.915.915-.915 2.364 0 3.203.84.84 2.365.84 3.204 0l.763-.762a9.156 9.156 0 0 0 2.364.991v1.068c0 1.296.992 2.288 2.288 2.288 1.297 0 2.288-.992 2.288-2.288v-1.068a9.156 9.156 0 0 0 2.365-.991l.762.762c.84.84 2.365.84 3.204 0 .915-.915.915-2.364 0-3.203l-.763-.763a9.156 9.156 0 0 0 .992-2.364h1.067c1.297 0 2.289-.992 2.289-2.288 0-1.297-.992-2.289-2.289-2.289zm-10.176 5.688a3.625 3.625 0 0 1-3.661-3.66c0-2.051 1.61-3.662 3.66-3.662s3.662 1.61 3.662 3.661a3.626 3.626 0 0 1-3.661 3.661z"
      />
      <path
        stroke="gray"
        strokeWidth={2}
        d="M22.593 4.814h4.39a2 2 0 0 1 2 2v4.39a2 2 0 0 1-2 2h-4.39a2 2 0 0 1-2-2v-4.39a2 2 0 0 1 2-2z"
      />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth={2}
        d="M20.658 9.39h-2.76a1.118 1.118 0 0 1-1.118-1.119V2.12c0-.618.5-1.119 1.118-1.119h6.153c.618 0 1.118.5 1.118 1.119v2.25"
      />
    </g>
  </svg>
)
