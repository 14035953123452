import styled, { keyframes } from 'styled-components'

import { UIVars } from '~ui'

const PADDING = '25px'

const entry = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Article = styled.article`
  animation: ${entry} 1.5s;
  background: ${UIVars.setColors.whiteThree};
  border: 1px solid ${UIVars.setColors.whiteFive};
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  width: 100%;
  > .header {
    align-items: center;
    display: flex;
    min-height: 76px;
    &.isHelper {
      background-color: ${UIVars.setColors.assitant};
    }
  }
`

export const Comment = styled.div`
  padding: 15px ${PADDING} 0;
  &,
  & p,
  & li,
  & span {
    color: ${UIVars.setColors.black};
    font-size: 16px !important;
    line-height: 28px;
    :last-child {
      margin-bottom: 0;
    }
  }
  & p {
    min-height: 28px;
    margin-bottom: 0;
  }
  & img {
    height: auto !important;
    width: auto !important;
    max-width: 100%;
    max-height: 100%;
  }
  & a {
    color: ${UIVars.setColors.primary};
    cursor: pointer;
    text-decoration: underline;
    :hover {
      color: ${UIVars.setColors.black};
      text-decoration: underline;
    }
  }
  & ol,
  ul {
    padding-left: 18px;
    margin-bottom: 0;
  }
  & > span.suggestedAnswer {
    display: block;
    font-weight: bold;
  }

  & span.isNew {
    color: white;
    font-size: 12px !important;
    line-height: normal;
    margin-bottom: 8px;
  }
`

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px ${PADDING};
  & > span {
    color: ${UIVars.setColors.brownThree};
    font-size: 12px;
    line-height: 14px;
  }
`

export const Actions = styled.div`
  > span {
    margin-left: 15px;
    position: relative;
    :hover {
      color: ${UIVars.setColors.black};
      text-decoration: underline;
    }
    :not(:last-child) {
      margin-right: 16px;
      :after {
        background-color: ${UIVars.setColors.backgroundGrey};
        content: '';
        cursor: initial;
        height: 100%;
        position: absolute;
        right: -16px;
        top: 0px;
        width: 1px;
      }
    }
  }
  @media screen and (max-width: 340px) {
    > span {
      margin-left: 10px;
      :not(:last-child) {
        margin-right: 11px;
        :after {
          right: -11px;
        }
      }
    }
  }
`
