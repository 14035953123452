// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Trae el Rendimiento de un Alumno en el Curso
 * Lista las Pruebas, sus notas y las ponderaciones según su modalidad
 *
 * @param {*} info
 * @example
 * ```js
 *    const performance = await getCoursePerformance(123)
 * ```
 * @returns {*} Performance del Alumno en el Curso
 */
const getCoursePerformance = info => {
  const requestId = 'getCourseList'
  return async dispatch => {
    getToken()

    const query = `
      query getCoursePerformance($CoursePerformanceInput: CoursePerformanceInput!) {
        getCoursePerformance(input: $CoursePerformanceInput) {
          status {
            code
            name
            success
            detail
          }
          average {
            valueFormatted
            status {
              id
              enabled
              name
              style
            }
          }
          constancy {
            valueFormatted
            value
            status {
              id
              enabled
              name
              style
            }
          }
          approved
          showFinalAverage
          weighing
          CalificationGroups {
            id
            name
            weighing
            totalCompleted
            totalTest
            calificationGroupTypeId
            average {
              valueFormatted
              status {
                id
                enabled
                name
                style
              }
            }
            Tests {
              id
              title
              description
              qualification {
                valueFormatted
                status {
                  id
                  enabled
                  name
                  style
                }
              }
            }
          }
        }
      }
    `

    const variables = {
      CoursePerformanceInput: {
        studentId: parseInt(info.studentId, 10),
        courseId: parseInt(info.courseId, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.getCoursePerformance)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getCoursePerformance
