import { UIVars } from '~ui'

const FormGroup = `
  .form-group {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .label-defect{
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0;

    text-align: right;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  .container-input{
    align-items: center;
    display: flex;
  }
  .btn-clean{
    color: #9a9aa4;
    cursor: pointer;
    font-size: 0.9286rem;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
  }
  .calendar--input{
    display: flex;
    width: 100%;
  }

  .input-group{
    display: flex;
    position: relative;
    width: 100%;
    &>div{
      width: 100%;
    }
    &-addon{
      align-items: center;
      background: #f5f5fb;
      border: none;
      color: #9a9aa4;
      display: flex;
      font-size: 1.1429rem;
      height: calc(100% - 4px);
      justify-content: center;
      left: 2px;
      position: absolute;
      top: 2px;
      width: 44px!important;
      z-index: 5;
    }
    .form-control{
      padding-left: 60px;
      padding-right: 40px;
    }
  }
  .input-box{
    position: relative;
    width: 100%;
  }
  .form-control{
    background: #fff;
    border-radius: 5px!important;
    border: 1px solid ${UIVars.setColors.default};
    box-shadow: none!important;
    font-size: 1rem;
    height: auto;
    padding: 0.7143em 1.0714em;
    &.input-sm{
      font-size: 0.9286rem;
      height: inherit;
      padding: 0.384em 0.77em;
    }
    &.input-xs{
      font-size: 0.9286rem;
      height: inherit;
      padding: 0.135em 0.5em;
      &.sitb {
        padding: 0.135em 2.5em 0.135em 0.5em;
        background-position-x: 95%;
      }
    }
    &::placeholder {
      color: #ddd;
      font-style: italic;
    }
    &:focus,
    &:active,
    &:hover{
      border-color: ${UIVars.setColors.primary};
      box-shadow: none;
      outline: 0;
      &[disabled]{
        border-color: ${UIVars.setColors.default};
      }
    }
    &[readonly]{
      background: #fff;
    }
    &[disabled]{
      background: #f1f1f1!important;
      cursor: no-drop;
    }
  }
  .form-info{
    font-size: 1rem;
  }

  .isRequired:before{
    content: "*";
    color: red;
    margin-right: 2px;
  }

  form>.wrapper>.alertUI .alert{
    margin-top: 20px;
  }

  .phone{
    input[type=number] {
      -moz-appearance:textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  select{
    position: relative;

  }

`
export default FormGroup
