import styled from 'styled-components'

import { UIVars } from '~ui'
import { outstanding, featured } from '~assets/svg'

// Destruturing de UIVars
const {
  setColors: { hexToRgbA },
  setColors
} = UIVars

/**
 * Estilos CSS
 */
export default styled.section`
  &.Article__outstanding {
    &:before {
      content: url(${outstanding});
      display: block;
      line-height: 0;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      color: ${setColors.deepSkyBlue};
      font: 20px / 34px 'Lora';
      text-align: center;
    }
  }

  &.Article__featured {
    margin: 40px 0;
    width: 250px;
    p {
      border-bottom: 2px solid ${hexToRgbA(setColors.deepSkyBlue, 0.5)};
      border-top: 2px solid ${hexToRgbA(setColors.deepSkyBlue, 0.5)};
      font: 20px / 34px 'Lora', serif;
      padding: 35px 0;
      position: relative;
      text-rendering: auto;
      &:before {
        background: white;
        content: url(${featured});
        left: 50%;
        padding: 0 10px;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);
      }
    }
    &.float {
      &.left {
        float: left;
        margin-left: -125px;
        margin-right: 40px;
      }
      &.right {
        float: right;
        margin-right: -125px;
        margin-left: 40px;
      }
    }
    &.block {
      clear: both;
      margin: 50px -50px 0;
      width: 115%;
    }
  }
  @media screen and (max-width: 990px) {
    &.Article__featured {
      padding: 23px 0 0;
      &.float {
        &.left,
        &.right {
          float: none;
          margin: 50px 0 0;
          text-align: center;
          width: 100%;
        }
      }

      &.block {
        margin: 50px 0 0;
        width: 100%;
      }

      p {
        padding: 30px 0;
        font: 18px / 30px 'Lora';
      }

      & + p {
        margin-top: 30px;
      }
    }
  }
`
