import React from 'react'
import { convertNodeToElement } from 'react-html-parser'
import { Types } from './types'
import ContentModules from './Modules'

/**
 * Toma todos los embeds y los parsea a componente React
 * @param embeds - componentes
 */
export const getComponentsEmbeds = (embeds: Types.Embeds) => {
  const components: Types.ComponentsEmbedsType[] = []
  if (embeds) {
    const setRegex = '___REPLACE_CONTENT_MODULE___'
    embeds.forEach((embed, i) => {
      components.push({
        id: `${setRegex}${embed.id}${setRegex}`,
        component: <ContentModules key={i} config={embed} />
      })
    })
  }
  return components
}

/**
 * Si el nodo es un objeto y no es un style o hr entonces lo procesamos.
 */
export const canReplace = (element: any) => {
  if (typeof element === 'object' && !['style', 'hr'].includes(element.type)) {
    return true
  } else {
    return false
  }
}

/**
 * De cada nodo quitamos el prop contentediatable
 *
 * Desde PlatBe cuando se cargan los componentes del editor de contenidos se les agrega el tag para uso interno del Panel.
 * Pero en React se genera un Warning. Con esto evitamos el warning y dejamos siempre "contentEditable = false".
 */
export const removeContentEditable = (node: any, index: any) => {
  if (node && node.attribs && node.attribs.contenteditable) {
    // Elimina attributo contenteditable para prevenir error con react
    node.attribs.contenteditable = false
    return convertNodeToElement(node, index, removeContentEditable)
  }
}
