import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'react-i18next'

import SVGAnimation from './Animation'

type Props = {
  /**
   * Si hay un error entonces hace el render de esa vista (404, 500, etc)
   */
  error?: JSX.Element

  /**
   * Título personalizado del Loading.
   */
  title?: string

  /**
   * Mensaje personalizado del Cargando...
   */
  message?: string
}

/**
 * Componente Loading
 * @example
 * <LoadingSVG
 *    error={error}
 *    title="Custom Title Loading SVG"
 *    message="Custom Message"
 * />
 */
const LoadingSVG = ({ error, title, message }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [customTitle, setTitle] = useState('')

  useEffect(() => {
    if (!title) {
      setTitle(t('Loading eClass'))
    } else {
      setTitle(title)
    }
    return () => {
      setIsLoading(false)
    }
  }, [t, title])

  const IsLoadingOverFlow = createGlobalStyle`
    body {
      overflow: hidden;
    }
  `
  const IsLoaded = createGlobalStyle`
    body {
      overflow: visible;
    }
  `
  if (error) {
    return error
  }

  return (
    <Wrapper className={`LoadingSVG flex-center`}>
      {isLoading ? <IsLoadingOverFlow /> : <IsLoaded />}
      <SVGAnimation title={customTitle} />

      <h5>{!message ? t('CrossLoading') : message}</h5>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  svg {
    width: 197px;
  }

  h5 {
    color: #999;
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0 0;
    text-align: center;
  }
`

export default LoadingSVG
