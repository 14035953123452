import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { File } from './File'
import { useAlerts } from '../'
import { PopAlert } from '../PopAlert'

import * as Style from './style'

import { Props, Types } from '../types'

export const FileList = ({
  files,
  removeFiles,
  download,
  edit,
  loading,
  uploadingFile,
  deleteAll
}: Props.FileList) => {
  const { t } = useTranslation()
  const [list, setList] = useState<Types.File[]>()
  const [deleteId, setDeleteId] = useState(0)
  const [targetMsg, setTargetMsg] = useState('')
  const { modalInit } = useAlerts()
  const [isDeleting, setIsDeleting] = useState(false)
  const {
    comments: { show }
  } = useSelector((store: Types.Store) => store)

  const handleDelete = useCallback(
    e => {
      setTargetMsg(e.target.textContent)
    },
    [setTargetMsg]
  )

  useEffect(() => {
    if (show === true && isDeleting === true) {
      document.addEventListener('click', handleDelete)
    }
  }, [show, handleDelete, isDeleting])

  useEffect(() => {
    if (removeFiles && targetMsg === ('Entendido' || 'DashboardUnderstand')) {
      removeFiles()
      setIsDeleting(false)
      setTargetMsg('resetClass')
      document.removeEventListener('click', handleDelete)
    }
  }, [removeFiles, targetMsg, handleDelete])

  useEffect(() => {
    setList(Array.from(files))
  }, [files])

  if (!list && !uploadingFile) {
    return null
  }

  return (
    <section>
      {list?.map((item, key) => {
        return (
          <File
            key={key}
            item={item}
            deleteId={deleteId}
            removeFiles={(state: Types.File) => {
              setDeleteId(item.id!)
              if (removeFiles) {
                removeFiles(state)
              }
            }}
            edit={edit}
            loading={true}
            download={download}
            deleteAll={deleteAll}
          />
        )
      })}
      {uploadingFile && (
        <File item={uploadingFile.file} uploading={uploadingFile.state} />
      )}
      {!download && !loading && list && list!.length > 1 && (
        <Style.AllDelete
          onClick={event => {
            modalInit({
              type: 'delete'
            })
            setIsDeleting(true)
            handleDelete(event)
          }}>
          {t('CommentSystemDeleteAllFiles')}
        </Style.AllDelete>
      )}
      <PopAlert uploadingFile={uploadingFile} />
    </section>
  )
}
