import { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { axiosError, axiosAbort, getToken } from '..'

import { apiURL } from '../../../Config/constants'
import { Types } from '~views/Courses/types'

/**
 * Custom hook para chequear si usuario viene de campus virtual.
 *
 * Si viene de CV se redirecciona a url del portal.
 * @param {string} nextUrl - Url a redirigir.
 * @example useCheckPortal('newPath')
 */
export const useCheckPortal = (nextUrl: string = '') => {
  const { portalId } = useSelector((state: Types.Store) => state.loggedUser)

  const checkPortal = useCallback(async () => {
    getToken()
    const query = `
      query Portal {
        Portal(id: ${portalId}) {
          url
        }
      }
    `
    await axios
      .post(`${apiURL}/graphql`, { query })
      .then(response => {
        const { url } = response?.data?.data?.Portal!
        if (url) {
          window.location.replace(`${url}/${nextUrl}`)
        }
      })
      .catch(err => axiosError(err, 'checkPortal', null))
  }, [portalId, nextUrl])

  useEffect(() => {
    if (portalId) {
      checkPortal()
    }
    return () => {
      axiosAbort()
    }
  }, [portalId, checkPortal])

  return true
}
