import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SideFlash } from '~ui/Alert'

import * as Style from './style'
import { Store } from '~app_store/types'

const TIMEOUT = 6000

/**
 * Renderiza los errores que estén en el store, al descararlo lo cierra y quita de redux.
 * @example <Alerts />
 */
export const Alerts = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { requestError } = useSelector((state: Store) => state)
  const refsAlert = React.useRef<HTMLDivElement>(null)

  const closed = React.useCallback(() => {
    if (refsAlert.current) {
      dispatch({
        type: 'RESET_ERROR'
      })
      if (requestError.redirect) {
        history.push(requestError.redirect)
      }
    }
  }, [dispatch, refsAlert, requestError, history])

  React.useEffect(() => {
    if (refsAlert.current) {
      setTimeout(() => {
        closed()
      }, TIMEOUT)
    }
  }, [refsAlert, requestError.code, closed])

  if (requestError.code === 0) {
    return null
  }

  return (
    <Style.Wrapper ref={refsAlert}>
      <SideFlash type="error" margin={0} onClose={() => closed()}>
        <strong>{requestError.detail || t('CrossErrorOcurred')}</strong>
      </SideFlash>
    </Style.Wrapper>
  )
}
