/**
 * Estados del control
 */
export const testsStates = {
  /**
   * -1: Estado sin borrador y que no he visto las alertas.
   */
  ALERT: -1,

  /**
   * 0: Estado que ya vi las alertas y no he terminado de responder lo obligatorio.
   */
  ANSWERING: 0,

  /**
   * 1: Estado cuando ya termine de responder los obligatorios.
   */
  COMPLETED: 1,

  /**
   * 2: Estado cuando le puse "finalizar" y no puedo modificar respuestas.
   */
  TERMINATE: 2,

  /**
   * 3: Estado cuando nos debe llevar el resumen.
   */
  FINISHED: 3,

  /**
   * 4: Si no aprobé con la nota minima me da las opciones de repeticion.
   */
  REPETITIONS: 4,

  /**
   * 5: Me muestra el feedback de la evaluación.
   */
  EVALUATED: 5,

  /**
   * 6: Pendiente de revision, evaluacion respondida.
   */
  PENDING_EVALUATION: 6,

  /**
   * 7: Forzar reintento, SOLO PARA REPETICION
   */
  REPEAT_NOW: 7
}
