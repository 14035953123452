import React from 'react'
import { status } from './GridIcon'

import { UnitIconProps } from './types'

export const ListIcon = ({ active }: UnitIconProps) => (
  <svg
    className="ListIcon"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={16}
    height={16}
    viewBox="0 0 16 16">
    <g fill={status(active!)} fillRule="nonzero">
      <circle cx="2" cy="2" r="2" />
      <circle cx="2" cy="8" r="2" />
      <circle cx="2" cy="14" r="2" />
      <path d="M6 1h10v2H6zM6 7h10v2H6zM6 13h10v2H6z" />
    </g>
  </svg>
)
