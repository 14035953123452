import * as React from 'react'

export const CommentsIcon = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={48}
    height={49}
    role="CommentsIcon"
    viewBox="0 0 48 49">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 .333)">
        <circle cx="24.108" cy="23.868" r="23.868" />
      </g>
      <circle
        cx="17.589"
        cy="16.832"
        r="6.418"
        stroke="#60798E"
        strokeWidth="2"
      />
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M33.602 39.386C31.706 31.996 25 26.533 17.018 26.533c-3.198 0-6.191.877-8.752 2.403"
      />
      <g stroke="#60798E" strokeWidth="2" transform="translate(27.29 12.696)">
        <circle cx="9.13" cy="5.707" r="4.707" />
        <path
          strokeLinecap="round"
          d="M15.911 15.63c-1.999-1.14-4.314-1.793-6.78-1.793-3.214 0-6.168 1.106-8.504 2.96"
        />
      </g>
      <path
        stroke="#60798E"
        strokeWidth="2"
        d="M40.978 1H29c-.276 0-.526.112-.707.293-.181.18-.293.43-.293.707l.059 8.955 3.538-1.616h9.381c.276 0 .526-.112.707-.293.181-.181.293-.431.293-.707V2c0-.276-.112-.526-.293-.707-.18-.181-.43-.293-.707-.293z"
      />
      <path
        fill="#0189FF"
        d="M13.978 45.145H2c-1.105 0-2-.895-2-2v-6.339c0-1.104.895-2 2-2h9.164l3.814-1.742c.355-.162.774-.006.936.35.042.091.064.191.064.293V43.145c0 1.105-.895 2-2 2z"
      />
    </g>
  </svg>
)
