import { RequestErrorReducer, ReducerAction } from './types'
import { initialState } from './initialState'

/**
 * Hace el dispatch de un error o lo elimina del store.
 *
 * @retuns Objeto con los datos del usuario
 */
export const requestError = (
  state: RequestErrorReducer = initialState,
  action: ReducerAction
): RequestErrorReducer | null => {
  switch (action.type) {
    case 'SET_ERROR':
      return dispatchError(action.payload)
    case 'RESET_ERROR':
      return initialState
    default:
      return state
  }
}

const dispatchError = (payload: RequestErrorReducer) => {
  if (!payload.success) {
    return payload
  }
  return null
}
