import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { CDN_URL } from '~app/Config/constants'
import { Icon } from '~ui/Icons'
import { Button, LoaderAsync } from '~ui'
import { useAlerts } from '../'
import * as Style from './style'
import { Props, Types } from '../types'
export const File = ({
  item,
  removeFiles,
  edit,
  loading,
  download,
  uploading,
  deleteId,
  deleteAll
}: Props.File) => {
  const { modalInit } = useAlerts()
  const [targetMsg, setTargetMsg] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const {
    comments: { show }
  } = useSelector((store: Types.Store) => store)

  const handleDelete = useCallback(
    e => {
      setTargetMsg(e.target.textContent)
    },
    [setTargetMsg]
  )

  useEffect(() => {
    if (show === true && isDeleting === true) {
      document.addEventListener('click', handleDelete)
    }
  }, [show, handleDelete, isDeleting])

  useEffect(() => {
    if (removeFiles && targetMsg === ('Entendido' || 'DashboardUnderstand')) {
      removeFiles(item)
      setIsDeleting(false)
      setTargetMsg('resetClass')
      document.removeEventListener('click', handleDelete)
    }
  }, [removeFiles, targetMsg, item, handleDelete])

  if (!item) {
    return null
  }

  return (
    <Style.File>
      <Style.Name>
        <p className="name">{item.name}</p>
        {item.extension && <span>{`(${item.extension})`}</span>}
        {uploading && (
          <Style.Uploading className={uploading}>
            <div>
              <div className="loading"></div>
              <div className="full"></div>
            </div>
          </Style.Uploading>
        )}
      </Style.Name>
      <Style.Actions>
        {download && (
          <Button
            onClick={event =>
              modalInit({
                event,
                type: 'download',
                url: CDN_URL + item?.path?.substr(1)
              })
            }
            className="download">
            <Icon name="download" width={14} height={17} fill="white" />
            {''}
          </Button>
        )}
        {!download && edit ? (
          deleteId === item.id || deleteAll ? (
            <Style.Loading>
              <LoaderAsync />
            </Style.Loading>
          ) : (
            <Icon
              onClick={event => {
                modalInit({
                  type: 'delete'
                })
                handleDelete(event)
                setIsDeleting(true)
              }}
              name="close"
              width={15}
              height={15}
              fill="#60798E"
            />
          )
        ) : null}
      </Style.Actions>
    </Style.File>
  )
}
