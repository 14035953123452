// @External Dependencies
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import App from '../../App'
import domainConfig from '../domains'
import { HandleError, RequestError } from '~components'
import { LoadingSVG } from '~ui'
import { useLocale } from '~services'

import PrivateRoute from './PrivateRoute'
import { PrivateRoutes, PublicRoutes } from './routes'

/**
 * Rutas de navegación de la aplicación.
 */
const AppRoutes = () => {
  useLocale()
  return (
    <App>
      <>
        <RequestError.Alerts />
        <Suspense fallback={<LoadingSVG />}>
          <Switch>
            {PublicRoutes.map(({ path, component: Component }, key) => {
              return (
                <Route
                  exact
                  path={path}
                  render={props => (
                    <Component {...props} config={domainConfig} />
                  )}
                  key={key}
                />
              )
            })}
            {PrivateRoutes.map((route, key) => {
              return (
                <PrivateRoute
                  path={route.path}
                  component={route.component}
                  public={route.public}
                  key={key}
                />
              )
            })}
            <Route
              render={() => (
                <HandleError
                  status={{
                    code: 404,
                    success: false,
                    detail: 'Página no encontrada',
                    name: 'Not Found.'
                  }}
                />
              )}
            />
          </Switch>
        </Suspense>
      </>
    </App>
  )
}

export default AppRoutes
