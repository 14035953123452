// @External Dependencies
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Dependencies
import vars from '../variables'

// @Component
const PaginationList = ({
  active,
  long,
  handle,
  total,
  disabled,
  extreme,
  centered
}) => {
  const firstPages = []
  const lastPages = []
  const pages = []

  for (let i = 1; i <= long; i++) {
    firstPages.push(i)
  }
  for (let i = long - 1; i >= 0; i--) {
    lastPages.push(i)
  }
  for (let i = Math.trunc(long / 2); i >= -Math.trunc(long / 2); i--) {
    pages.push(i)
  }
  const rangeMin = Math.round(long / 2)
  const arrow = total <= long

  const renderPage = () => {
    if (active <= rangeMin && total > long) {
      return firstPages.map(page => (
        <PaginationItem
          key={page}
          active={active === page}
          onClick={() => !disabled && handle(page)}>
          <PaginationLink>{page}</PaginationLink>
        </PaginationItem>
      ))
    } else if (total <= long && total > 1) {
      const pag = []
      for (let i = 1; i <= total; i++) {
        pag.push(i)
      }
      return pag.map(page => (
        <PaginationItem
          key={page}
          active={active === page}
          onClick={() => !disabled && handle(page)}>
          <PaginationLink>{page}</PaginationLink>
        </PaginationItem>
      ))
    } else if (total === 1) {
      return null
    } else if (active >= total - (rangeMin - 1)) {
      return lastPages.map(page => (
        <PaginationItem
          key={page}
          active={active === total - page}
          onClick={() => !disabled && handle(total - page)}>
          <PaginationLink>{total - page}</PaginationLink>
        </PaginationItem>
      ))
    } else {
      return pages.map(page =>
        page === 0 ? (
          <PaginationItem key={page} active>
            <PaginationLink>{active}</PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem
            key={page}
            onClick={() => !disabled && handle(active - page)}>
            <PaginationLink>{active - page}</PaginationLink>
          </PaginationItem>
        )
      )
    }
  }

  return (
    <StyledPaginationList
      centered={centered}
      className={disabled && 'disabled'}>
      <Pagination aria-label="Page navigation">
        {/* {!arrow && extreme && (
          <Pagination.First
            disabled={active === 1 && true}
            onClick={() => active !== 1 && !disabled && handle(1)}
          />
        )} */}
        {!arrow && (
          <PaginationItem>
            <PaginationLink
              previous
              disabled={active === 1 && true}
              onClick={() => active !== 1 && !disabled && handle(active - 1)}
            />
          </PaginationItem>
        )}
        {renderPage()}
        {!arrow && (
          <PaginationItem>
            <PaginationLink
              next
              disabled={active >= total && true}
              onClick={() => active > 0 && !disabled && handle(active + 1)}
            />
          </PaginationItem>
        )}
        {/* {!arrow && extreme && (
          <Pagination.Last
            disabled={active >= total && true}
            onClick={() => active > 0 && !disabled && handle(total)}
          />
        )} */}
      </Pagination>
    </StyledPaginationList>
  )
}
// active, long, handle, total, disabled, extreme
// @Proptypes
PaginationList.propTypes = {
  active: PropTypes.number.isRequired,
  extreme: PropTypes.bool,
  disabled: PropTypes.bool,
  handle: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  long: PropTypes.number
}
PaginationList.defaultProps = {
  extreme: true,
  long: 4
}

// @Export Component
export default PaginationList

// @Styles
const StyledPaginationList = styled.div`
  .pagination {
    justify-content: ${props => (props.centered ? 'center' : 'normal')};
  }

  li {
    a,
    span {
      background: #f5f5fb;
      border-radius: 5px;
      border: 0;
      color: #777781;
      margin-left: 5px;
      transition: background 0.25s;

      &:hover {
        background-color: #c9c9d3;
        color: #efefef;
      }
    }
    &.active {
      span {
        background: ${vars.setColors.primary};
        &:hover {
          background: ${vars.setColors.primary};
        }
      }
    }
  }
  &.disabled {
    opacity: 0.7;
    li {
      a,
      span {
        cursor: default !important;
        :hover {
          background: #f5f5fb;
          color: #777781;
        }
      }
      &.active {
        span {
          background: ${vars.setColors.primary};
          color: #efefef;
          &:hover {
            background: ${vars.setColors.primary};
          }
        }
      }
    }
  }
`
