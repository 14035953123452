import styled from 'styled-components'
import UIVars from '../../variables'

const Wrapper = styled.div`
  background: ${UIVars.setColors.deepSkyBlue};
  bottom: 0;
  height: 3px;
  position: absolute;
  z-index: 999;
`

export default Wrapper
