import styled, { keyframes } from 'styled-components'

const entry = keyframes`
  0% {
    opacity: 0;
    top: 0px;
  }
  100% {
    opacity: 1;
    top: 30px;
  }
`

export const Wrapper = styled.div`
  animation: ${entry} 0.5s;
  left: 50%;
  position: fixed;
  top: 30px;
  transform: translatex(-50%);
  z-index: 99999;
  .alert {
    margin-bottom: 0;
  }
`
