import React, { useEffect, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import goTo from '@eclass/scrollmove'

import { Icon } from '../Icons'
import { UIVars } from '~ui'

import { getQuestionStatus } from '~views/Tests/helpers/questionStatus'
import { useTranslation } from 'react-i18next'
import { ItemTestProps } from './Types'
import * as ItemStyle from './ItemStyle'

const MenuItemTest = ({
  number,
  answer: {
    state = 0,
    config: { id }
  },
  activeQuestion
}: ItemTestProps) => {
  const { t } = useTranslation()
  const questionStatus: any = getQuestionStatus(t, state)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (activeQuestion) {
      activeQuestion === `question-${id}` ? setActive(true) : setActive(false)
    }
  }, [setActive, activeQuestion, id])

  return (
    <ItemStyle.Test key={number}>
      <DropdownItem
        className={questionStatus.className}
        onClick={() => goTo(`#question-${id}`)}>
        {
          <span>
            <Icon
              name="in-progress"
              fill={
                active ? UIVars.setColors.primary : UIVars.setColors.whiteThree
              }
              className="flag"
            />
          </span>
        }
        <span>{`${t('ResourceQuestion')} ${number + 1}`}</span>
        <span className="Question__Status">
          {questionStatus.status}
          <div className="Triangle" />
        </span>
      </DropdownItem>
    </ItemStyle.Test>
  )
}

export default MenuItemTest
