import styled from 'styled-components'

import UIVars from '../variables'

export const WrapperDocs = styled.ul`
  padding: 0;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 16px;

  li {
    border: 1px solid ${UIVars.setColors.veryLightPink};
    border-radius: ${UIVars.borderRadius};
    background: ${UIVars.setColors.whiteTwo};
    color: ${UIVars.setColors.blackTwo};
    margin: 0 0 16px 0;
    padding: 10px 20px;

    .text_button {
      color: ${UIVars.setColors.white};
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }
    span {
      color: ${UIVars.setColors.brownGreyTwo};
      font-size: 12px;
      margin-left: 5px;
      text-transform: uppercase;
    }
  }
`

export const WrapperDoc = styled.div`
  margin: 16px 0 16px 0;
  padding: 0;
  list-style: none;

  .Svg__Icon {
    margin-right: 0px;
  }

  .File {
    border: 1px solid ${UIVars.setColors.veryLightPink};
    border-radius: ${UIVars.borderRadius};
    background: ${UIVars.setColors.whiteTwo};
    color: ${UIVars.setColors.blackTwo};
    margin-bottom: 10px;
    padding: 10px 20px;

    &:last-child {
      margin: 0;
    }
    .text_button {
      color: ${UIVars.setColors.white};
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
      margin-right: 8px;
    }

    .button__container {
      font-size: 14px;
      padding: 5px 10px;
    }
  }
`
