import { CampusHelpCenterPayload } from '@eclass/api'
import { UIReducerAction, UIReducer, Payloads } from './types'

import { InitialState } from './initialState'
import { dispatchBranding } from './dispatchBranding'

/**
 * Reducer de UI
 *
 * @param {UIReducer} state - Valores iniciales del Reducer.
 * @param {UIReducerAction} action - dispatch disparado.
 *
 * @returns {UIReducer} Reducer.
 */
export const ui = (
  state: UIReducer = InitialState,
  action: UIReducerAction
): UIReducer => {
  /**
   * Evalúa cada dispatch y hace el return correspondiente.
   */
  switch (action.type) {
    case 'COURSE_WELCOME':
      return {
        ...state,
        ...dispatchCourseWelcome(action.payload)
      }
    case 'SET_MEDIAQUERY':
      return {
        ...state,
        ...dispatchIsMobile(action.payload)
      }
    case 'SET_HELP_CENTER':
      return {
        ...state,
        ...dispatchHelpCenter(action.payload)
      }
    case 'SET_BRANDING':
      return {
        ...state,
        ...dispatchBranding(action.payload)
      }
    case 'SET_I18N':
      return {
        ...state,
        ...dispatchLanguage(action.payload)
      }
    case 'SET_I18N_COURSE':
      return dispatchLanguageCourse(state, action.payload)
    default:
      return state
  }
}

/**
 * Cambia el idioma de la plataforma.
 */
const dispatchLanguage = (payload: string): UIReducer => {
  return {
    i18n: payload
  }
}

/**
 * Cambia el courseWelcome true/false.
 */
const dispatchCourseWelcome = (payload: boolean): UIReducer => {
  return {
    courseWelcome: payload
  }
}

/**
 * Cambia el isMobile true/false.
 */
const dispatchIsMobile = (payload: boolean): UIReducer => {
  return {
    isMobile: payload
  }
}

/**
 * Cambia el isMobile true/false.
 */
const dispatchHelpCenter = (payload: CampusHelpCenterPayload): UIReducer => {
  return {
    HELP_CENTER: payload
  }
}

/**
 * Guarda el idioma del curso en el objeto `i18nCourses`
 */
const dispatchLanguageCourse = (
  state: UIReducer,
  payload: Payloads.CourseLanguage
): UIReducer => {
  const tempLangs =
    state.i18nCourses instanceof Object ? state.i18nCourses! : {}

  // seteamos el curso nuevo o sobreescribimos
  tempLangs[payload.courseId] = payload.i18n

  return {
    ...state,
    i18nCourses: tempLangs
  }
}
