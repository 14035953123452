import React from 'react'

export const MainMaterial = () => (
  <svg
    role="iconMainMaterial"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="24.5"
        cy="24.5"
        r="24.5"
        fill="#E0EEFA"
        fillRule="nonzero"></circle>
      <path
        fill="#0189FF"
        fillRule="nonzero"
        d="M30 31v11.207c0 .99.728 1.793 1.625 1.793.52 0 1.008-.275 1.314-.738L36.5 38.86l3.56 4.402c.529.8 1.545.977 2.27.395.421-.337.67-.876.67-1.45V31a3 3 0 00-3-3h-7a3 3 0 00-3 3z"></path>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25.276 44.143H8.952A2.958 2.958 0 016 41.18V7.106a2.958 2.958 0 012.952-2.963h25.096A2.958 2.958 0 0137 7.106V22.81"></path>
      <path
        stroke="#60798E"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M30 9h-5a2 2 0 00-2 2v5a2 2 0 002 2h5a2 2 0 002-2v-5a2 2 0 00-2-2z"></path>
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M11 14h7m-7 9h14m-14 6h14m-14 6h7"></path>
    </g>
  </svg>
)
