import './polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import axe from 'react-axe'
import whyDidYouRender from '@welldone-software/why-did-you-render'

// @App Dependencies
import AppRoutes from './Config/routes/'
import config from './Config/domains/'
import i18n from './app/I18n'

import { a11y } from './Config/constants'
import { store, persistor } from './app/Redux/'

if (process.env.NODE_ENV !== 'production') {
  whyDidYouRender(React)
  if (a11y) {
    const axeConfig = {
      rules: [
        {
          id: 'skip-link',
          enabled: true
        }
      ]
    }
    // @ts-ignore
    axe(React, ReactDOM, axeConfig)
  }
}

const AppWrapper = () => (
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter forceRefresh={config.appMobile}>
          <I18nextProvider i18n={i18n}>
            <AppRoutes />
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </>
)

// @Render
ReactDOM.render(<AppWrapper />, document.getElementById('root'))
