import styled from 'styled-components'
import UIVars from '~ui/variables'

// @Styles
export default styled.section`
  background: white;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  overflow: auto;

  .title_mobile {
    display: none;
  }
  .logo-footer-404 {
    height: 24px;
    text-align: -webkit-center;
    width: 120px;
    margin-left: 0px;
  }

  .ErrorPage {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 50px;
      h2 {
        color: ${UIVars.setColors.black};
        font-size: 80px;
        margin-bottom: 20px;
        font-weight: bold;
      }
      ul {
        list-style: none;
        padding: 0px;
        li {
          display: flex;
          font-size: 18px;
          line-height: 31px;
          margin-bottom: 10px;
          img {
            align-self: flex-start;
            margin: 6px 10px 0;
            width: 100%;
            max-width: 20px;
            height: 100%;
            max-height: 15px;
          }
          p {
            line-height: 1.7;
            a {
              color: ${UIVars.setColors.primary};
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      p.error {
        margin-bottom: 20px;
        color: ${UIVars.setColors.brownGrey};
      }
    }

    &__image {
      margin-bottom: 0;
      text-align: center;
      display: flex;
      .desktop-404-img {
        width: 100%;
        height: 90%;
        align-self: center;
      }
      .mobile-404-img {
        display: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .container {
      max-width: 960px;
    }
    .ErrorPage {
      &__image {
        .desktop-404-img {
          width: 100%;
          height: 100%;
          max-height: 470px;
          max-width: 340px;
          align-self: center;
        }
      }
      &__content {
        h2 {
          margin-top: 80px;
        }
      }
    }
  }

  /*style mobile */
  @media screen and (max-width: 480px) {
    .ErrorPage {
      &__content {
        padding-right: 20px;
      }
      &__image {
        display: flex;
        justify-content: center;
        .desktop-404-img {
          display: none;
        }
        .mobile-404-img {
          display: block;
          width: 100%;
          height: 100%;
          margin: 20px;
          padding-left: 80px;
          padding-right: 80px;
        }
      }
      &__content {
        ul {
          li {
            img {
              margin: 6px 10px 6px 0px;
            }
          }
        }
      }
    }
    .logo-footer-404 {
      height: 24px;
      text-align: -webkit-center;
      width: auto;
      margin-bottom: 20px;
    }
    .title_mobile {
      color: ${UIVars.setColors.black};
      display: block;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      margin-top: 35px;
    }
    h2 {
      display: none;
    }
    .row {
      flex-direction: column-reverse;
    }
  }
`
