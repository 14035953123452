// Traduccion de variables
const profile = {
  'Show me how connected to my classmates':
    'Show me how connected to my classmates',
  Activated: 'Activated',
  Disabled: 'Disabled'
}
export default {
  ...profile
}
