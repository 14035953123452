// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/constants'
import { axiosError, getToken } from '../../../Services'

/**
 * Obtiene los datos de vista resumen de ejercicios.
 *
 * @param {import('@eclass/api').ExerciseResumeInput} input - Parámetros para buscar los datos.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444,
 *      programId: 555
 *    }
 *    const response = await getExerciseResume(input)
 * ```
 * @returns {Promise<import('@eclass/api').ExerciseResumePayload>} Retorna el resumen de vista ejercicios.
 */

const getExerciseResume = input => {
  const requestId = 'getExerciseResume'
  return async dispatch => {
    getToken()
    const query = `
      query ExerciseResume($ExerciseResumeInput: ExerciseResumeInput!) {
      ExerciseResume(input: $ExerciseResumeInput) {
        status {
          success
          name
          detail
          code
        }
        Evaluation {
          header {
            name
            description
          }
          config {
            id
            indicator {
              id
              name
              image
              obtained
              max
            }
            href
          }
          Menu {
            Links {
              id
              name
              href
              indicators {
                id
                name
                image
                obtained
                max
              }
              status {
                id
                detail
                style
                enabled
              }
              Childrens {
                id
                name
                href
                status {
                  id
                  name
                  detail
                  enabled
                  style
                }
                indicators {
                  id
                  name
                  image
                  obtained
                  max
                }
              }
            }
            Unit{
              id
              name
            }
          }
        }
        CallToAction {
          text
          href
        }
      }
    }
    `

    const variables = {
      ExerciseResumeInput: {
        programId: parseInt(input.programId, 10),
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10),
        unitId: parseInt(input.unitId, 10),
        resourceId: parseInt(input.resourceId, 10)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => response.data.data.ExerciseResume)
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getExerciseResume
