import React from 'react'

export const AcademicHistorialIcon = () => (
  <svg
    role="AcademicHistorialIcon"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={42}
    height={45}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#CCE5FF" id="a" cx="20.664" cy="20.459" r="20.459" />
      <path
        stroke="#60798E"
        strokeLinecap="square"
        strokeWidth={2}
        d="M2 19h38.005"
      />
      <rect width={8} height={6} x={5} y={22} fill="#0189FF" rx={2} />
      <rect width={8} height={6} x={5} y={30} fill="#0189FF" rx={2} />
      <rect width={8} height={6} x={17} y={22} fill="#0189FF" rx={2} />
      <rect width={8} height={6} x={17} y={30} fill="#0189FF" rx={2} />
      <rect width={8} height={6} x={29} y={22} fill="#0189FF" rx={2} />
      <rect width={8} height={6} x={29} y={30} fill="#0189FF" rx={2} />
      <rect
        width={40}
        height={26}
        x={1}
        y={9}
        stroke="#60798E"
        strokeWidth={2}
        rx={2}
      />
      <path
        d="M15 35v5.785M27 35v5.785"
        stroke="#60798E"
        strokeLinecap="square"
        strokeWidth={2}
      />
      <path
        stroke="#60798E"
        strokeLinecap="round"
        strokeWidth={2}
        d="M12.997 42h16.04"
      />
    </g>
  </svg>
)
